import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import * as PR from "../../prime-react/index";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getFileDetails, toggleFeaturedNews } from '../../services/api';
import { getNewsTypeLabel } from '../../utils/reuse';

const NewsItem = ({ news, Avatar, newsType, handleDeleteNews, fetchNews, deletedNewsIds }) => {
    const isMember = useSelector(state => state.auth.role) === 'member';
    const isRegulator = useSelector(state => state.auth.role) === 'regulator';
    const [attachmentImage, setAttachmentImage] = useState(null);
    const menuRight = useRef(null);
    const navigate = useNavigate();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const toggleFeatured = () => {
        const obj = {
            featured: !news.featured
        }
        toggleFeaturedNews(news?.id, obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: news.featured? 'News removed from featured successfully':'News marked as featured successfully'
                });
                setTimeout(() => {
                    fetchNews()
                  }, 2000);
             
            } else {
                const error = response.error;
                toast.current?.show({
                    severity: error?.severity,
                    summary: 'Error',
                    detail: error?.errorMsg || error?.summary
                });
            }
        });
    };

    const getDeleteLabel = () => {
        switch (newsType) {
            case "generalnews":
                return "Delete news";
            case "integritypreviews":
                return "Delete preview";
            case "sportingsanctions":
                return "Delete sanction"
            default:
                return "Delete news"
        }
    }
    const items = [
        ...(newsType !== 'sportingsanctions' ? [{
            label: news?.featured ? 'Remove feature' : 'Mark as featured',
            icon: news?.featured ? 'pi pi-star-fill' : 'pi pi-star',
            command: toggleFeatured,
        }] : []),
        {
            label: 'Edit details',
            icon: 'pi pi-pencil',
            command: () => gotoEditNews(news.id, news.newsType),
        },
        {
            label: getDeleteLabel(),
            icon: 'pi pi-trash',
            command: () => handleDeleteNews(news.id, news.newsType),
        }
    ];

    const gotoEditNews = (id, newsType) => {
        navigate(`/edit-details?id=${id}&newsType=${newsType}`);
    };
    useEffect(() => {
        if (news?.fileAttachments && !deletedNewsIds?.includes(news.id)) {
            const attachments = JSON.parse(news?.fileAttachments);
            attachments?.forEach(attachment => {
                if (attachment?.attachmentType === "coverImage") {
                    getFileDetails(attachment?.key, headers, dispatch, response => {
                        if (response.result === 'SUCCESS') {
                            setAttachmentImage(URL.createObjectURL(response?.data));
                        } else {
                            const error = response?.error;
                            toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
                        }
                    });
                }
            });
        }
    }, [news?.fileAttachments, headers, dispatch, deletedNewsIds, news.id]);

    const gotoNewsDetails = (id) => {
        if (isMember || isRegulator) {
            navigate(`/news-details?id=${id}`);
        } else {
            navigate(`/news-detail?id=${id}&newsType=${newsType}`);
        }
    };

    return (
        <div className="news-list">
            <div className="flex gap-4">
                <div className="news-cover p-0">
                    {attachmentImage ? (
                        <PR.Image
                            src={attachmentImage}
                            alt={news?.title}
                            className="news-img"
                        />
                    ) : (
                        <PR.Image
                            src={Avatar}
                            alt={news?.title}
                            className="news-img"
                        />
                    )}
                </div>
                <div className="news-content w-full py-2 px-0">
                    <div className="flex justify-content-between w-full h-full">
                        <div className="flex justify-content-between flex-column w-full">
                            <div className='flex flex-column'>
                                <h3 className='title'>{news?.title}</h3>
                                <div
                                    onClick={() => gotoNewsDetails(news?.id)}
                                    className="desc-content"
                                    dangerouslySetInnerHTML={{
                                        __html: news?.description,
                                    }}
                                ></div>
                            </div>
                            <div className="flex align-items-center justify-content-between">
                                <ul className="flex list-none align-items-center gap-4">
                                    {newsType === "sportingsanctions" ? (
                                        <ul className='chips-ul gap-3'>
                                            {news?.keywords && news?.keywords?.split(',')?.map(keyword => (
                                                <li key={keyword}>{keyword}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <>
                                            <li>
                                                <i className="pi pi-user"></i>{" "}
                                                <span className="author">
                                                    {news?.createdByUser}
                                                </span>
                                            </li>
                                            <li>
                                                <i className="pi pi-calendar"></i>{" "}
                                                <span>{moment(news?.createdAt).format('DD MMM YYYY')}</span>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                <ul className="flex list-none align-items-center gap-4">
                                    {newsType === "sportingsanctions" ? (
                                        <li>
                                            <i className="pi pi-calendar"></i>{" "}
                                            <span>{moment(news?.createdAt).format('DD MMM YYYY')}</span>
                                        </li>
                                    ) : (
                                        <li>
                                            <i className="pi pi-book"></i>{" "}
                                            <span>{news?.categoryName ?? getNewsTypeLabel(news?.newsType)}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        {(!isMember && !isRegulator) && (
                            <div className="dropdown-popup">
                                <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className="export-popup" popupAlignment="right" />
                                <PR.Button icon="pi pi-ellipsis-v" className="info-button" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <PR.Toast ref={toast} position='top-right' />
        </div>
    );
};

export default NewsItem;
