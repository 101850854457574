import { useState, useEffect, useMemo } from "react";
import * as PR from "../prime-react/index";
import { getFileDetails } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { profileIcon } from "../assets/images";
import { authActions } from "../store/auth";

const ProfileImageDisplay = ({ profileImage }) => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const displayProfile = useSelector(state => state.auth.displayProfile);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [displayImage, setDisplayImage] = useState(profileIcon);

    useEffect(() => {
        if(displayProfile){
            setDisplayImage(displayProfile)
        }else if (!displayProfile && profileImage) {
            if (profileImage?.key && (profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" || profileImage.type === "image/png")) {
                getFileDetails(profileImage.key, headers, dispatch, response => {
                    if (response.result === 'SUCCESS') {
                        setDisplayImage(URL.createObjectURL(response?.data));
                        dispatch(authActions.storeProfileImage(URL.createObjectURL(response?.data)))
                    }
                });
            } 
        }else{
            setDisplayImage(profileIcon)
        }
        // eslint-disable-next-line
    }, [displayProfile]);

    return (
        <PR.Image
            src={displayImage}
            className="group-name-icon"
        />
    );
};

export default ProfileImageDisplay;
