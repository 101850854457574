import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import { getFileDetails, getGroupsWithPermission, getNewsCategories, getNewsDetails } from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { newsActions } from "../../../../store/news";
import { fileUploadLimit, getNewsTypeLabel, permission, roleIds } from "../../../../utils/reuse";

const EditDetails = () => {
  const [newsDetail, setNewsDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const newsType = queryParams.get("newsType");
  const id = queryParams.get("id");
  const dispatch = useDispatch()
  const fileUploadRef = useRef(null);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
    };
  }, [sessionData.sessionId]);
  const toast = useRef(null);

  const [categories, setCategories] = useState([]);
  const [regulators, setRegulators] = useState([]);
  const [governingBodies, setGoverningBodies] = useState([]);

  const [deletedFiles, setDeletedFiles] = useState([])

  useEffect(() => {
    setLoading(true)
    getNewsDetails(id, headers, dispatch, (response) => {
      if (response.result === 'SUCCESS') {
        const responseData=response.data
        const newsData = responseData?.map(news => ({
          ...news,
          fileAttachments: news?.fileAttachments ? JSON.parse(news.fileAttachments) : []
        }));
        setNewsDetail(newsData[0]);
      } else {
        const error = response?.error;
        toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
      }
      setLoading(false)
    })
    // eslint-disable-next-line
  }, []);

  const [fileUrls, setFileUrls] = useState([]);
  const [attachmentImage, setAttachmentImage] = useState(null);
  const [initialCoverImage, setInitialCoverImage] = useState(null)
  useEffect(()=>{
    if (newsDetail?.fileAttachments) {
      setLoading(true)
      const attachmentsArray = newsDetail?.fileAttachments

      if (attachmentsArray && attachmentsArray?.length > 0) {
        attachmentsArray.forEach(attachment => {
          if(attachment.attachmentType === "coverImage"){
            getFileDetails(attachment.key, headers, dispatch, response => {
              if (response.result === 'SUCCESS') {
                const url = URL.createObjectURL(response.data);
                if (attachment.type === "image/jpeg" || attachment.type === "image/png") {
                  setAttachmentImage(url);
                  setInitialCoverImage({
                    key: attachment.key,
                    type: attachment.type,
                    attachmentType: "coverImage"
                  })
                }
              } else {
                const error = response?.error;
                toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
              }
            })
          }else{
            setFileUrls(prev => {
              if(!prev.some(file => file.key === attachment.key)){
                return [...prev, { name: attachment.name, key: attachment.key, type: attachment.type }]
              }
              return prev
            });
          }
        })
      };
      setLoading(false)
      }
      // eslint-disable-next-line
    },[newsDetail?.fileAttachments])

  useEffect(() => {
    getNewsCategories(
      headers,
      dispatch,
      (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const result = responseData?.length > 0 ? responseData?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          setCategories(result);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch categories",
          });
        }
      },
      false,
      false
    );

    getGroupsWithPermission(roleIds?.regulator,permission?.news,  headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setRegulators(result);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch regulators",
        });
      }
    });

    getGroupsWithPermission(roleIds?.governingbody,permission?.news, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setGoverningBodies(result);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch governing bodies",
        });
      }
    });
  }, [headers, dispatch]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    keywords: Yup.array().of(Yup.string()),
    attachments: Yup.array()
    .max(3, 'You can upload a maximum of 3 files')
    .nullable()
    .test('fileType', 'Only image, PDF, DOCX or XLSX files are allowed', (files) => {
      if (!files) return true; // If no file is provided, return true
      const acceptedTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];
      return files?.every((file) => acceptedTypes.includes(file.type))
    })
    .test('fileSize', `File size must be less than ${fileUploadLimit.label}`, (files) => {
      if (!files) return true;
      return files?.every((file) => {
        return file.size <= fileUploadLimit.value
      })
    }),
    category: Yup.object().when("newsType", {
      is: (value) => value !== "Integrity previews",
      then: (schema) => schema.required("Category is required"),
      otherwise: (schema) => schema,
    }),
    pushTo: Yup.object({
      members: Yup.boolean(),
      regulators: Yup.array().of(Yup.object()),
      governingBodies: Yup.array().of(Yup.object()),
    }).test(
      "pushToValidation",
      "At least one push target is required",
      (value) => {
        const { members, regulators, governingBodies } = value;
        return members || regulators?.length > 0 || governingBodies?.length > 0;
      }
    ),
  });
  const imageUploadRef = useRef(null)
  const formik = useFormik({
    initialValues: {
      title: "",
      coverImage:null,
      attachments: [],
      description: "",
      keywords:[],
      category: "",
      newsType: newsType || "",
      pushTo: {
        members: false,
        regulators: [],
        governingBodies: [],
      },
    },
    validationSchema,
    onSubmit: (values) => {

      const payload = {
        id:Number(id),
        title: values.title,
        description: values.description,
        newsType: newsType.toLowerCase().replace(/\s/g, ""),
        keywords: values?.keywords?.join(','),
        categoryID: categories?.find(
          (category) => category.name === values.category.name
        )?.id,
        receiverAllMembers: values.pushTo.members,
        receivers: [
          ...(values?.pushTo?.governingBodies||[])?.filter(body => !initialGoverningBodies?.some(item => item.id === body.id))?.map((body) =>body.id),
          ...(values?.pushTo?.regulators||[])?.filter(regulator => !initialRegulators?.some(item => item.id === regulator.id))?.map((regulator) =>regulator.id),
        ]
      };
      const attachments = [];
      if (values.attachments?.length > 0) {
        let newAttachments = values.attachments

        if(fileUrls.length >0){
          newAttachments = values.attachments.filter(attachment => {
            return !fileUrls.some(file => file.key === attachment.key);
          })
        }

        newAttachments?.forEach((file, index) => {
          attachments.push(file);
        });

      }

      let deletedAttachments = [];
      if(deletedFiles){
        deletedAttachments = [...deletedFiles]
      }
      if(initialCoverImage && values.coverImage){
        deletedAttachments.push(initialCoverImage)
      }

      if (values.coverImage) {
        secureLocalStorage.setItem("coverImage", values.coverImage);
      }
      secureLocalStorage.setItem("newsData", JSON.stringify(payload));
      secureLocalStorage.setItem("attachments", attachments);
      secureLocalStorage.setItem("deletedAttachments", deletedAttachments);

      navigate("/preview-news");
    },
  });
  useEffect(() => {
    switch(newsType){
      case "sportingsanctions":
        dispatch(newsActions.updateTab({activeIndex:2}))
        return;
      case"integritypreviews":
        dispatch(newsActions.updateTab({activeIndex:1}))
        return;
      default:
        dispatch(newsActions.updateTab({activeIndex:0}))
        return;
    }
  },[newsType, dispatch])

  const [initialRegulators, setInitialRegulators] = useState([])
  const [initialGoverningBodies,setInitialGoverningBodies] = useState([])
  useEffect(() => {
    if (newsDetail) {
      formik.setValues({
        title: newsDetail.title || "",
        description: newsDetail.description || "",
        keywords: newsDetail.keywords?.split(','),
        category: {id:newsDetail.categoryID,name:newsDetail.categoryName }|| {},
        pushTo:{
          members: newsDetail.receiverAllMembers,
          regulators: newsDetail.receivers?.filter(receiver => receiver.role === "regulator")
            .map(r =>({...r,disabled:true})),
          governingBodies: newsDetail.receivers?.filter(receiver => receiver.role === "governingbody")
            .map(r =>({...r,disabled:true})),
        }
      });

      setInitialRegulators(newsDetail.receivers?.filter(receiver => receiver.role === "regulator"))
      setInitialGoverningBodies(newsDetail.receivers?.filter(receiver => receiver.role === "governingbody"))
    }
    // eslint-disable-next-line
  }, [newsDetail]);

  useEffect(() => {
    if (newsDetail?.fileAttachments) {
      const attachmentsArray = newsDetail?.fileAttachments
      if (attachmentsArray && attachmentsArray?.length > 0) {

        let files = []
        attachmentsArray?.forEach(attachment => {
          if(attachment?.attachmentType !== 'coverImage') {
            files.push({name: attachment.name, key: attachment.key, type: attachment.type, size:Number(attachment.size) })
          }
        })
        formik.setFieldValue("attachments", [...files])
      }
    }
    // eslint-disable-next-line
  },[newsDetail])

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </span>
    );
  };

  const header = renderHeader();

  if (loading) {
    return (
      <div className="loading">
        <i className="pi pi-spin pi-spinner"></i>
      </div>
    );
  }
  const disableSelectedOptions = (options,selectedOptions) =>{
    return options?.map(option => {
      const isDisabled = selectedOptions?.some(so =>so.id===(option.id))
      const {id,name,role} = option
      return {
        id,
        name,
        role,
        disabled: isDisabled,
      }
    })?.sort((a, b) => a.name.localeCompare(b.name))
  }

  const handleFileSelect = (event) => {
    if (fileUploadRef.current) {
        fileUploadRef.current.clear();
    }
    const currentAttachments = formik.values.attachments || [];
    const newAttachment = event.files[0];
    const duplicateFiles = currentAttachments?.some(existingFile => existingFile.name === newAttachment.name)
    if (duplicateFiles) {
      toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "File names must be unique. Please rename your file(s) before uploading.",
      });
      fileUploadRef.current.clear();
    } else if (currentAttachments?.length + 1 > 3) {
      toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "You can upload a maximum of 3 files (including existing and new files).",
      });
      fileUploadRef.current.clear();
    } else {
      const updatedAttachments = [...currentAttachments, newAttachment];
      formik.setFieldValue("attachments", updatedAttachments);
    }
  };
  const handleDeleteCoverImage = () => {
    setDeletedFiles(prevDeleted => [
      ...prevDeleted,
      initialCoverImage
    ]);
    setAttachmentImage(null)
  }
  const handleDeleteCoverImageFile = () => {
    formik.setFieldValue("coverImage", null);
    if(initialCoverImage){
      setDeletedFiles(prevDeleted => [
        ...prevDeleted,
        initialCoverImage
      ]);
    }
  }

  const handleDeleteFile = (index) => {
    const deletedFile = formik.values.attachments[index];
      if (deletedFile.key) {
          setDeletedFiles(prevDeleted => [
              ...prevDeleted,
              { key: deletedFile.key, type: deletedFile.type }
          ]);
      }

    const updatedAttachments = [...formik.values.attachments];
    updatedAttachments.splice(index, 1);
    formik.setFieldValue("attachments", updatedAttachments);
  };

  return (
    <>
      <Layout backBtn={'news'}>
        <section className="news-tab news-details-section general-news-section">
          <div className="flex align-items-center justify-content-between mb-4">
            <div className="left">
              <h1>
                Edit {getNewsTypeLabel(newsType)}
              </h1>
            </div>
            <div className="right">
              <PR.Button
                type="submit"
                label="Save & Preview"
                className="action-buttons save-button"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid || formik.isSubmitting}
              />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid">
              <div className="col-12">
                <div className="new-list-section">
                  <div className="list-wrapper">
                    <div className="news-list edit-details">
                      <div className="card">
                        <div className="col-12 p-0 mb-4">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="title">
                              News title <span className="p-error">*</span>
                            </label>
                            <PR.InputText
                              id="title"
                              type="text"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={`input-field ${
                                formik.touched.title && formik.errors.title
                                  ? "p-invalid"
                                  : ""
                              }`}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <small className="p-error">
                                {formik.errors.title}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        {newsType === "sportingsanctions" && (
                          <div className="form-input-row">
                            <div className="col-12 p-0 mb-4">
                              <div  className="flex flex-column form-input gap-2">
                                <label htmlFor="keywords">Keywords</label>
                                <PR.Chips 
                                  className="chips-field"
                                  id="keywords"
                                  value={formik.values.keywords}
                                  onChange={(e) => formik.setFieldValue('keywords', e.value)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-12 p-0">
                          <div className="flex flex-column gap-2">
                            {!attachmentImage?(
                              <>
                                <label htmlFor="coverImage">
                                  Attach Cover Image
                                </label>
                                <div className="card create-file-upload flex align-items-center">
                                  <PR.FileUpload
                                    mode="advanced"
                                    name="coverImage"
                                    accept="image/*"
                                    chooseLabel="Choose Image"
                                    maxFileSize={fileUploadLimit.value}
                                    ref={imageUploadRef}
                                    customUpload
                                    onSelect={(event) => {
                                      if (imageUploadRef.current) {
                                        imageUploadRef.current.clear();
                                      }
                                      formik.setFieldValue("coverImage", event.files[0]);
                                    }}
                                    />
                                  <span className="ml-2">
                                    (Add image less than {fileUploadLimit.label})
                                  </span>
                                </div>
                                {formik.values.coverImage && (
                                  <div className="uploaded-file-container flex flex-column gap-2">
                                    <div className="uploaded-files">
                                      {formik.values.coverImage.name}
                                      <i className="pi pi-trash" onClick={handleDeleteCoverImageFile} />
                                    </div>
                                  </div>
                                )}
                              </>
                            )
                            :(<div className="edit-file-upload">
                              <PR.FileUpload
                               mode="basic"
                               name="coverImage"
                               accept="image/*"
                               chooseLabel="Choose Image"
                               maxFileSize={fileUploadLimit.value}
                               customUpload
                               onSelect={(event) => {
                                setAttachmentImage(null)
                                 formik.setFieldValue("coverImage", event.files[0]);
                               }}
                              />
                              {attachmentImage && <PR.Image
                                src={attachmentImage}
                                alt="Weekly Integrity Preview"
                                className="new-edit-image"
                              />}

                              {/* Edit & Delete buttons */}
                              {/* <i
                                className="pi pi-pencil cover-image-delete edit"
                                onClick={() => handleDeleteCoverImage()}
                              /> */}
                              <i
                                className="pi pi-trash cover-image-delete"
                                onClick={() => handleDeleteCoverImage()}
                              />
                              
                            </div>)}
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <div className='attachments'>
                            <span className='heading-span'>Attached Files:</span>
                            <div className="attach-files">
                              <div className="card create-file-upload flex align-items-center">
                                <PR.FileUpload
                                  auto
                                  mode="advanced"
                                  name="attachments"
                                  accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, image/gif"
                                  chooseLabel="Choose Files"
                                  multiple
                                  ref={fileUploadRef}
                                  onSelect={handleFileSelect}
                                  onFocus={() => formik.setFieldTouched("attachments")}
                                  customUpload
                                />
                                <span className="ml-2">
                                  (Add up to 3 files less than {fileUploadLimit.label} each)
                                </span>
                              </div>
                            </div>
                            {formik.values.attachments && (
                                <div className="uploaded-file-container attachments-upload">
                                    {formik.values.attachments?.map((attachment, index) => (
                                      <div className="uploaded-files" key={index}>
                                        {attachment.name}
                                        <i
                                          className="pi pi-trash"
                                          onClick={() => handleDeleteFile(index)}
                                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                                        />
                                      </div>
                                    ))}
                                </div>
                            )}

                            {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                              <div className="error">
                                {Array.isArray(formik.errors.attachments) ? (
                                    Array.from(new Set(formik.errors?.attachments)).map((error, index) => (
                                        <div key={'attachment' + index}>{error}</div>
                                    ))
                                ) : (
                                    <div className="error-message mt-1">{formik.errors.attachments}</div>
                                )}
                              </div>
                            )}


                          </div>
                        </div>
                      <div className="col-12 p-0 mt-4">
                        <div className="flex flex-column gap-2">
                          <label htmlFor="description">News description</label>
                          <div className="card content-editor">
                            <PR.Editor
                              value={formik.values.description}
                              onTextChange={e => formik.setFieldValue('description', e.htmlValue)}
                              headerTemplate={header}
                              style={{ height: "450px" }}
                            />
                          </div>
                        </div>
                      </div>
                      {newsType !== "integritypreviews" && (
                        <div className="col-12 p-0 mt-4">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="category">News category</label>
                            <PR.Dropdown
                              value={formik.values.category}
                              disabled={true}
                              options={categories}
                              optionLabel="name"
                              placeholder="Select a category"
                              className="w-full"
                            />
                          </div>
                        </div>
                      )}
                      <div className="grid mt-4">
                        <div className="col-12 pt-0 pb-0">
                          <label htmlFor="pushTo">Publish and send by email to</label>
                        </div>
                        <div className="col-4">
                          <div className="flex form-input gap-2 align-items-center status-box">
                            <PR.Checkbox
                              inputId="members"
                              name="members"
                              value="All members"
                              onChange={(e) => formik.setFieldValue("pushTo.members", e.checked)}
                              checked={formik.values.pushTo.members}
                              disabled={formik.values.pushTo.members}
                            />
                            <label
                              htmlFor="members"
                              className="ml-2 font-medium"
                            >
                              All members
                            </label>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="card flex justify-content-center">
                            <PR.MultiSelect
                              value={formik.values.pushTo.regulators}
                              onChange={(e) => formik.setFieldValue("pushTo.regulators", e.value)}
                              options={disableSelectedOptions(regulators, initialRegulators)}
                              optionLabel="name"
                              filter
                              placeholder="Regulators"
                              maxSelectedLabels={3}
                              className="w-full"
                              pt={{ 
                                checkboxContainer: { 
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                  }
                                } 
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="card flex justify-content-center">
                            <PR.MultiSelect
                              value={formik.values.pushTo.governingBodies}
                              onChange={(e) => formik.setFieldValue("pushTo.governingBodies", e.value)}
                              options={disableSelectedOptions(governingBodies, initialGoverningBodies)}
                              optionLabel="name"
                              filter
                              placeholder="Sport Governing Bodies"
                              maxSelectedLabels={3}
                              className="w-full"
                              pt={{
                                checkboxContainer: { 
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                          </div>
                        </div>
                  </div>
                  <div className="text-right mt-4">
                    <PR.Button
                      label="Save & Preview"
                      type="submit"
                      className="action-buttons save-button"
                      onClick={formik.handleSubmit}
                      disabled={!formik.isValid || formik.isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <PR.Toast ref={toast} position='top-right' />
      </Layout>
    </>
  );
};

export default EditDetails;
