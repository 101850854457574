import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const sessionData = secureLocalStorage.getItem('sessionData');
const profileData = secureLocalStorage.getItem('profileData');
const displayProfile = secureLocalStorage.getItem('displayProfile');

const isLoggedIn = sessionData !== null ? true : false;
const role = sessionData?.role;
const companyName = sessionData?.companyName;
const alertCount = sessionData?.alertCount;
const conversationCount = sessionData?.conversationCount;
const newsLatest = sessionData?.newsLatest ? sessionData?.newsLatest:  null;
const exportPermission = sessionData?.groupPermission?.includes("export") || false;

const initialAuthState = {
    isLoggedIn: isLoggedIn,
    sessionData: sessionData,
    profileData: profileData,
    role: role,
    companyName: companyName,
    alertCount: alertCount,
    conversationCount: conversationCount,
    newsLatest: newsLatest,
    alerts:[],
    alertRaisedAnalytics: {},
    exportPermission: exportPermission,
    displayProfile: displayProfile
}

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        onLogin(state, action) {
            const sessionData = {
                email: action.payload.email,
                sessionId: action.payload.sessionId,
                role: action.payload.role,
                companyName: action.payload.groupName,
                alertCount: action.payload.alertCount,
                conversationCount: action.payload.conversationCount,
                groupID: action.payload.groupID,
                groupImage: action.payload.groupImage,
                groupName: action.payload.groupName,
                userImage: action.payload?.userImage,
                userID: action.payload.userID,
                name: action.payload.name,
                newsLatest: action.payload?.news ? action.payload?.news[0] : null,
                groupPermission: action.payload.groupPermission,
            }

            const profileData = {
                name:action.payload.name,
                email:action.payload.email,
                profilePic: action.payload?.userImage ? JSON.parse(action.payload?.userImage) : null
            }
            secureLocalStorage.setItem('profileData', profileData);
            secureLocalStorage.setItem('sessionData', sessionData);
            if (action.payload.remember) {
                const { email, password } = action.payload;
                const user = { email, password };
                secureLocalStorage.setItem('userData', user);
            } else {
                if (secureLocalStorage.getItem('userData')) {
                    secureLocalStorage.removeItem('userData')
                }
            }
            state.isLoggedIn = true;
            state.sessionData = sessionData
            state.profileData = profileData
            state.name = action.payload.name;
            state.role = action.payload.role;
            state.companyName = action.payload.groupName;
            state.alertCount = action.payload.alertCount;
            state.conversationCount = action.payload.conversationCount;
            state.newsLatest = action.payload?.news ? action.payload?.news[0] : null;

            const exportPermission = sessionData.groupPermission?.includes("export") || false;
            state.exportPermission = exportPermission;
        },
        updateAlertCount(state, action) {
            state.alertCount = action.payload.alertCount;
            if (state.sessionData) {
                state.sessionData.alertCount = action.payload.alertCount;
                secureLocalStorage.setItem('sessionData', state.sessionData);
            }
        },
        updateConversationCount(state, action) {
            state.conversationCount = action.payload.conversationCount;
            if (state.sessionData) {
                state.sessionData.conversationCount = action.payload.conversationCount;
                secureLocalStorage.setItem('sessionData', state.sessionData);
            }
        },
        updateAlertsList(state, action) {
            state.alerts = action.payload
        },
        updateAlertRaisedAnalytics(state, action) {
            state.alertRaisedAnalytics = action.payload;
        },
        updateResponseRateAnalytics(state, action) {
            state.responseRateAnalytics = action.payload;
        },
        updateTotalAlertsAnalytics(state, action) {
            state.totalAlertsAnalytics = action.payload;
        },
        updateTagsCountAnalytics(state, action) {
            state.tagsCountAnalytics = action.payload;
        },
        updateAlertByLocationAnalytics(state, action) {
            state.alertByLocationAnalytics = action.payload;
        },
        updateResponseTypeAnalytics(state, action) {
            state.responseTypeAnalytics = action.payload;
        },
        updateAnalystAlertRaisedAnalytics(state, action) {
            state.analystAlertRaisedAnalytics = action.payload;
        },
        updateAnalystResponseRateAnalytics(state, action) {
            state.analystResponseRateAnalytics = action.payload;
        },
        storeProfileImage(state, action) {
            state.displayProfile = action.payload;
            secureLocalStorage.setItem('displayProfile', state.displayProfile)
        },
        onLogout(state) {
            state.isLoggedIn = false;
            state.displayProfile = null;
            secureLocalStorage.removeItem('sessionData');
            secureLocalStorage.removeItem('profileData');
            secureLocalStorage.removeItem('displayProfile')
        },
    }
})

export const authActions = authSlice.actions;
export default authSlice.reducer;