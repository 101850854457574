import React from "react";
import * as PR from "../../prime-react/index";
import "./Help.scss";
import Layout from "../../layouts/Layout";
import { help1, help2 } from "../../assets/images/index";

const Help = () => {
    return (
        <>
            <Layout>
                <section className="help-page-section">
                    <div className="help-wrapper">
                        <div className="m-0">
                            <h1>Help</h1>
                        </div>
                        <div className="help-tabs-section">
                            <PR.TabView className="vertical-tabs">
                                <PR.TabPanel header="Getting Started">
                                    <div className="help-content">
                                        <h2>Getting Started with IBIA</h2>
                                        <p>Welcome to [Dashboard Name] - your platform for maintaining the integrity of betting activities. This guide will help you navigate through the initial steps and acquaint you with the core functionalities of the dashboard.</p>
                                        <p>1. Logging In:</p>
                                        <ul>
                                            <li>Use your provided credentials to log into the dashboard.</li>
                                            <li>If you encounter login issues, please contact our support team at [support email/phone].</li>
                                        </ul>
                                        <p>2. Dashboard Overview:</p>
                                        <ul>
                                            <li>Familiarize yourself with the layout and main sections of the dashboard.</li>
                                            <li>Explore the navigation menu to access various features and modules.</li>
                                        </ul>
                                        <div className="mt-3">
                                            <PR.Button label="Copy" icon="pi pi-copy" className="copy-btn" />
                                        </div>
                                        <div className="mt-4">
                                            <PR.Image src={help1} alt="IBIA Logo" className="logo-img" />
                                        </div>
                                        <p>3. Accessing Alerts:</p>
                                        <ul>
                                            <li>Learn how to access and review alerts related to suspicious betting activities.</li>
                                            <li>Click on the "Alerts" or "Incidents" tab to view reported issues.</li>
                                        </ul>
                                        <p>4. Understanding Alert Details:</p>
                                        <ul>
                                            <li>Each alert provides specific details about the reported activity.</li>
                                            <li>Click on an alert to delve into its details, including event information, reported irregularities, and involved entities.</li>
                                        </ul>
                                        <p>5. Responding to Alerts:</p>
                                        <ul>
                                            <li>Follow the steps outlined in the alert detail page to respond appropriately.</li>
                                            <li>Collaborate with other members and analysts to validate or investigate the reported activity.</li>
                                        </ul>
                                        <p>6. Customizing Your Experience:</p>
                                        <ul>
                                            <li>Personalize your dashboard experience by adjusting settings and preferences.</li>
                                            <li>Explore options to modify layout, theme, or notification settings in the dashboard.</li>
                                        </ul>
                                        <p>7. Getting Help:</p>
                                        <ul>
                                            <li>Use the "Help" section for FAQs, tutorials, troubleshooting, and support contact details.</li>
                                            <li>Reach out to our support team for any assistance or questions you may have.</li>
                                        </ul>
                                        <div className="mt-4">
                                            <PR.Image src={help2} alt="IBIA Logo" className="logo-img" />
                                        </div>
                                        <p>8. Stay Updated:</p>
                                        <ul>
                                            <li>Keep an eye on system updates and announcements within the dashboard.</li>
                                            <li>Check the "System Updates" section regularly for new features or improvements.</li>
                                        </ul>
                                        <p>This guide serves as an initial walkthrough to help you get acquainted with [Dashboard Name]. For detailed instructions on specific features or functionalities, refer to the corresponding sections in the help area.</p>
                                    </div>
                                    <div className="help-content contact-info">
                                        <p>For any further assistance or inquiries, don't hesitate to contact our support team</p>
                                        <p><span>Email:</span> ibia.bet@gmail.com</p>
                                        <p><span>Mobile:</span> +1 (888)12 123</p>
                                    </div>
                                </PR.TabPanel>

                                <PR.TabPanel header="Dashboard Features">
                                    <div className="help-content">
                                        <h2>Getting Started with IBIA</h2>
                                        <p>Welcome to [Dashboard Name] - your platform for maintaining the integrity of betting activities. This guide will help you navigate through the initial steps and acquaint you with the core functionalities of the dashboard.</p>
                                        <p>1. Logging In:</p>
                                        <ul>
                                            <li>Use your provided credentials to log into the dashboard.</li>
                                            <li>If you encounter login issues, please contact our support team at [support email/phone].</li>
                                        </ul>
                                        <p>2. Dashboard Overview:</p>
                                        <ul>
                                            <li>Familiarize yourself with the layout and main sections of the dashboard.</li>
                                            <li>Explore the navigation menu to access various features and modules.</li>
                                        </ul>
                                        <div className="mt-4">
                                            <PR.Image src={help1} alt="IBIA Logo" className="logo-img" />
                                        </div>
                                        <p>This guide serves as an initial walkthrough to help you get acquainted with [Dashboard Name]. For detailed instructions on specific features or functionalities, refer to the corresponding sections in the help area.</p>
                                    </div>
                                    <div className="help-content contact-info">
                                        <p>For any further assistance or inquiries, don't hesitate to contact our support team</p>
                                        <p><span>Email:</span> ibia.bet@gmail.com</p>
                                        <p><span>Mobile:</span> +1 (888)12 123</p>
                                    </div>
                                </PR.TabPanel>

                                <PR.TabPanel header="User Settings and Preferences">
                                    <div className="help-content">
                                        <h2>Getting Started with IBIA</h2>
                                        <p>Welcome to [Dashboard Name] - your platform for maintaining the integrity of betting activities. This guide will help you navigate through the initial steps and acquaint you with the core functionalities of the dashboard.</p>
                                        <p>1. Logging In:</p>
                                        <ul>
                                            <li>Use your provided credentials to log into the dashboard.</li>
                                            <li>If you encounter login issues, please contact our support team at [support email/phone].</li>
                                        </ul>
                                        <p>2. Dashboard Overview:</p>
                                        <ul>
                                            <li>Familiarize yourself with the layout and main sections of the dashboard.</li>
                                            <li>Explore the navigation menu to access various features and modules.</li>
                                        </ul>
                                        <div className="mt-4">
                                            <PR.Image src={help1} alt="IBIA Logo" className="logo-img" />
                                        </div>
                                        <p>This guide serves as an initial walkthrough to help you get acquainted with [Dashboard Name]. For detailed instructions on specific features or functionalities, refer to the corresponding sections in the help area.</p>
                                    </div>
                                    <div className="help-content contact-info">
                                        <p>For any further assistance or inquiries, don't hesitate to contact our support team</p>
                                        <p><span>Email:</span> ibia.bet@gmail.com</p>
                                        <p><span>Mobile:</span> +1 (888)12 123</p>
                                    </div>
                                </PR.TabPanel>

                                <PR.TabPanel header="Troubleshooting and Support">
                                    <div className="help-content">
                                        <h2>Getting Started with IBIA</h2>
                                        <p>Welcome to [Dashboard Name] - your platform for maintaining the integrity of betting activities. This guide will help you navigate through the initial steps and acquaint you with the core functionalities of the dashboard.</p>
                                        <p>1. Logging In:</p>
                                        <ul>
                                            <li>Use your provided credentials to log into the dashboard.</li>
                                            <li>If you encounter login issues, please contact our support team at [support email/phone].</li>
                                        </ul>
                                        <p>2. Dashboard Overview:</p>
                                        <ul>
                                            <li>Familiarize yourself with the layout and main sections of the dashboard.</li>
                                            <li>Explore the navigation menu to access various features and modules.</li>
                                        </ul>
                                        <div className="mt-4">
                                            <PR.Image src={help1} alt="IBIA Logo" className="logo-img" />
                                        </div>
                                        <p>This guide serves as an initial walkthrough to help you get acquainted with [Dashboard Name]. For detailed instructions on specific features or functionalities, refer to the corresponding sections in the help area.</p>
                                    </div>
                                    <div className="help-content contact-info">
                                        <p>For any further assistance or inquiries, don't hesitate to contact our support team</p>
                                        <p><span>Email:</span> ibia.bet@gmail.com</p>
                                        <p><span>Mobile:</span> +1 (888)12 123</p>
                                    </div>
                                </PR.TabPanel>

                                <PR.TabPanel header="System Updates and Announcements">
                                    <div className="help-content">
                                        <h2>Getting Started with IBIA</h2>
                                        <p>Welcome to [Dashboard Name] - your platform for maintaining the integrity of betting activities. This guide will help you navigate through the initial steps and acquaint you with the core functionalities of the dashboard.</p>
                                        <p>1. Logging In:</p>
                                        <ul>
                                            <li>Use your provided credentials to log into the dashboard.</li>
                                            <li>If you encounter login issues, please contact our support team at [support email/phone].</li>
                                        </ul>
                                        <p>2. Dashboard Overview:</p>
                                        <ul>
                                            <li>Familiarize yourself with the layout and main sections of the dashboard.</li>
                                            <li>Explore the navigation menu to access various features and modules.</li>
                                        </ul>
                                        <div className="mt-4">
                                            <PR.Image src={help1} alt="IBIA Logo" className="logo-img" />
                                        </div>
                                        <p>This guide serves as an initial walkthrough to help you get acquainted with [Dashboard Name]. For detailed instructions on specific features or functionalities, refer to the corresponding sections in the help area.</p>
                                    </div>
                                    <div className="help-content contact-info">
                                        <p>For any further assistance or inquiries, don't hesitate to contact our support team</p>
                                        <p><span>Email:</span> ibia.bet@gmail.com</p>
                                        <p><span>Mobile:</span> +1 (888)12 123</p>
                                    </div>
                                </PR.TabPanel>

                                <PR.TabPanel header="Security and Privacy">
                                    <div className="help-content">
                                        <h2>Getting Started with IBIA</h2>
                                        <p>Welcome to [Dashboard Name] - your platform for maintaining the integrity of betting activities. This guide will help you navigate through the initial steps and acquaint you with the core functionalities of the dashboard.</p>
                                        <p>1. Logging In:</p>
                                        <ul>
                                            <li>Use your provided credentials to log into the dashboard.</li>
                                            <li>If you encounter login issues, please contact our support team at [support email/phone].</li>
                                        </ul>
                                        <p>2. Dashboard Overview:</p>
                                        <ul>
                                            <li>Familiarize yourself with the layout and main sections of the dashboard.</li>
                                            <li>Explore the navigation menu to access various features and modules.</li>
                                        </ul>
                                        <div className="mt-4">
                                            <PR.Image src={help1} alt="IBIA Logo" className="logo-img" />
                                        </div>
                                        <p>This guide serves as an initial walkthrough to help you get acquainted with [Dashboard Name]. For detailed instructions on specific features or functionalities, refer to the corresponding sections in the help area.</p>
                                    </div>
                                    <div className="help-content contact-info">
                                        <p>For any further assistance or inquiries, don't hesitate to contact our support team</p>
                                        <p><span>Email:</span> ibia.bet@gmail.com</p>
                                        <p><span>Mobile:</span> +1 (888)12 123</p>
                                    </div>
                                </PR.TabPanel>

                            </PR.TabView>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Help;