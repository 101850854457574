import { useState } from "react";
import * as PR from "../../prime-react/index";
import { useFormik } from "formik";
import { addCaseUpdateNotenewAPI } from "../../services/api";
import * as yup from 'yup'


const validationSchema = yup.object().shape({ note: yup.string().required('Note is Required').max(3000, 'Note must be less than or equal to 3000 words') });

const AddNote = ({ label, refetch, headers,toast, icon, id }) => {

    const [postPop, setPostPop] = useState(false);

    const closeHandler = () => setPostPop(false);
    const openHandler = () => setPostPop(true);

    const { values, handleChange, errors, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: { note: '' },
        validationSchema,
        onSubmit: async ({ note }) => {
            const {result, error} = await addCaseUpdateNotenewAPI(id, {notes:note}, headers);
            if(result === 'SUCCESS') refetch(id);
            else toast?.current?.show({ severity: error?.severity || "error", summary: "Error", detail: error?.errorMsg || error?.summary, });
            resetForm()
            closeHandler();
        },
    });

    return <>
        <PR.Button onClick={openHandler} icon={icon} label={label} className="primay-btn" />
        <PR.Dialog footer={<Footer onCancel={closeHandler} />} className="logout-dialog response-dialog tags-dialog" visible={postPop}>
            <div className="dialog-header">
                <div className="gap-2">
                    <h2 className="mb-3">Add Note</h2>
                    <form id="addNoteForm" onSubmit={handleSubmit}>
                        <p className="mb-1" style={{fontSize:12}}>Word{values.note.length>1&&'s'}: {values.note.length} / 3000</p>
                        <PR.InputTextarea maxLength={3000} name='note' value={values.note} onChange={handleChange} onBlur={handleBlur} className="description-textarea" />
                        {errors.note && <span className="error">{errors.note}</span>}
                    </form>
                </div>
            </div>
        </PR.Dialog>
    </>
}

export default AddNote;

const Footer = ({ onCancel }) => <>
    <PR.Button label="Cancel" onClick={onCancel} outlined className="logout-btns" />
    <PR.Button form="addNoteForm" type="submit" label="Post" outlined autoFocus className="logout-btns click-btn" />
</>