import React, {useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import { createNews } from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const PreviewNews = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = {
    sessionid: sessionData.sessionId,
    "Content-Type": "multipart/form-data",
  };

  const newsData = JSON.parse(secureLocalStorage.getItem("newsData"));
  const attachments = secureLocalStorage.getItem("attachments");
  const deletedAttachments = secureLocalStorage.getItem("deletedAttachments");
  const coverImage = secureLocalStorage.getItem("coverImage");
  const { title, description, newsType, keywords, categoryName } = newsData;
  const todayDate = new Date().toLocaleDateString();
  const files = attachments

  const getNewsType = (newsType) => {
    switch (newsType) {
      case "General news":
        return "generalnews"
      case "Integrity previews":
        return "integritypreviews"
      case "Sporting sanctions":
        return "sportingsanctions"
      default:
        return newsType
    }
  }
  const handlePublish = () => {
    const formData = new FormData();
    if(deletedAttachments?.length>0){
      newsData.deletedAttachments = deletedAttachments
    }    
    newsData.newsType = getNewsType(newsData.newsType);
    formData.append("data", JSON.stringify(newsData));
    attachments?.forEach((file) => {
      formData.append("attachments", file);
    });
    if(coverImage){
      formData.append("image", coverImage);
    }
    createNews(formData, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        secureLocalStorage.removeItem("newsData");
        secureLocalStorage.removeItem("attachments");
        secureLocalStorage.removeItem("deletedAttachments");
        secureLocalStorage.removeItem("coverImage");

        navigate("/news");
      } else {
        toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: response.error.errorMsg,
          });
      }
    });
  };

  return (
    <Layout backBtn={"news"}>
      <section className="news-tab news-details-section general-news-section">
        <div className="flex align-items-center justify-content-between mb-4">
          <div className="left">
            <h1>Preview News Item</h1>
          </div>
          <div className='right flex gap-2'>
            <PR.Button label="Publish" className="action-buttons save-button" onClick={handlePublish} />
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            <div className='new-list-section'>
              <div className='list-wrapper'>
                <h2>{title}</h2>
                <div className="news-list">
                  <div className="card">
                    <div className="col-12 p-0">
                      <div className='flex align-items-center justify-content-between mb-4'>
                        {newsType === 'Sporting sanctions' ? (
                          <>
                            <ul className='chips-ul'>
                              {keywords && keywords.split(',').map(keyword =>(
                                <li>{keyword}</li>
                              ))}
                            </ul>
                            <ul className="flex list-none align-items-center gap-4 mb-0">
                              <li><i className="pi pi-calendar"></i> <span>{todayDate}</span></li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <ul className="flex list-none align-items-center gap-4">
                              <li><i className="pi pi-user"></i> <span className="author">{sessionData.name}</span></li>
                              <li><i className="pi pi-calendar"></i> <span>{todayDate}</span></li>
                            </ul>
                            <ul className="flex list-none align-items-center gap-4">
                              <li><i className="pi pi-book"></i> <span>{categoryName ?? newsType}</span></li>
                            </ul>
                          </>
                        )}
                      </div>
                      {coverImage && (
                        <PR.Image src={URL.createObjectURL(coverImage)} alt="Cover" className="news-img" />
                      )}
                    </div>
                    <div className="col-12 p-0">
                      <div className='content' dangerouslySetInnerHTML={{ __html: description }}></div>
                      {files.length > 0 && (
                        <div className='attachments'>
                          <span className='heading-span'>Attached Files:</span>
                          {files?.map((file, index) => (
                            <PR.Button key={index} icon='pi pi-download' label={file.name} className="download-button" />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-right mt-4'>
                <PR.Button label="Publish" className="action-buttons save-button" onClick={handlePublish} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <PR.Toast ref={toast} position='top-right' />
    </Layout>
  );
};

export default PreviewNews;
