import React from "react";
import { Link } from "react-router-dom";
import * as PR from "../../prime-react/index";
import { notFound } from "../../assets/images/index";

// CSS
import "./PageNotFound.scss";

const PageNotFound = () => {
  return (
    <>
      <section className="notfound-section">
        <div className="grid grid-nogutter align-items-center custom-height">
          <div className="col-12 text-center">
            <PR.Image src={notFound} alt="not found" className="notfound-img" />
            <h1 className="text-4xl font-semibold"> Page Not found </h1>
            <Link to="/" className="backtohome-btn"><i className="pi pi-chevron-left"></i> Back to home</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;