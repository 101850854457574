import { useFormik } from "formik";
import { logo } from "../../assets/images"
import * as PR from "../../prime-react/index";
import { validationSchema, initialValues } from "./helper";
import { resetpasswordApi } from "../../services/api";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const content = {
    '/reset-password':{
        title:'Reset Password',
        para:'Please enter your details.',
        successMsg:'Your password has been changed successfully',
        label:'New Password',
        button:'Change password'
    },
    '/set-password':{
        title:'Welcome',
        para:'Secure Your Account with a Password',
        successMsg:'Your password has been updated successfully',
        label: 'Enter your password',
        button:'Set password'
    }
}

const SetPassword = () => {

    const  { pathname } = useLocation();
    const resetID = new URLSearchParams(document.location.search).get('id');
    const toast = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!resetID) navigate('/');
    }, [resetID, navigate]);

    const onSubmit = async ({ confirmPassword: password }) => {
        setLoading(true)
        const { result, error } = await resetpasswordApi({ resetID, password }, dispatch);
        if (result === 'SUCCESS') {
            setTimeout(() => { navigate('/login') }, 1000)
            toast.current.show({ severity: 'success', summary: 'Success', detail:content[pathname]?.successMsg});
        }
        else toast.current.show({ severity: 'error', summary: error.summary, detail: error.errorMsg });
        setLoading(false);
    }

    const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({ initialValues, validationSchema, onSubmit });

    return <section className="login-section">
        <PR.Toast ref={toast} position='top-right' />
        <div className="grid grid-nogutter">
            <div className="col-12">
                <div className="login-wrapper flex align-items-center justify-content-center" style={{ height: '100vh' }}>
                    <div className="login-info">
                        <PR.Image src={logo} alt="IBIA Logo" className="logo-img" />
                        <h1>{content[pathname]?.title}</h1>
                        <p>{content[pathname]?.para}</p>
                        {/* Form section starts here */}
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div className='grid'>
                                <div className="col-12 md:col-12">
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="newPassword">{content[pathname]?.label}</label>
                                        <PR.Password feedback={false} id="newPassword" aria-describedby="newPassword" name="newPassword" placeholder="Create new password" className="w-12" toggleMask autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} value={values.newPassword} />
                                        {(touched.newPassword && errors.newPassword) && <div className="error-message">{errors.newPassword}</div>}
                                    </div>
                                </div>
                                <div className="col-12 md:col-12">
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="confirmPassword">Confirm new password</label>
                                        <PR.Password feedback={false} id="confirmPassword" aria-describedby="confirmPassword" name="confirmPassword" placeholder="Enter new password again" className="w-12" toggleMask autoComplete="off" onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                                        {(touched.confirmPassword && errors.confirmPassword) && <div className="error-message">{errors.confirmPassword}</div>}
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <PR.Button loading={loading} label={content[pathname]?.button} type='submit' className="signin-button w-12" />
                                </div>
                            </div>
                        </form>
                        {/* Form section ends here */}
                    </div>
                </div>
            </div>
        </div>

    </section>
}

export default SetPassword;