import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  const [year] = useState(new Date().getFullYear())
  return (
    <>
        <footer>
          <div className='footer-section'>
            <div className="grid align-items-center">
                <div className="col-6">
                  <p>All Rights reserved. &copy;{year} | IBIA</p>
                </div>
                <div className="col-6 text-right">
                    <ul className="flex list-none align-items-center justify-content-end">
                      <li>Developed by <Link to="https://www.bluehouse-technology.com" target='_blank'>Bluehouse Technology</Link></li>
                      <li className="line"> | </li>
                      <li><Link to="/privacy-policy">Privacy policy</Link></li>
                      <li className="line"> | </li>
                      <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                    </ul>
                </div>
            </div>
          </div>
        </footer>
    </>
  )
}
export default Footer