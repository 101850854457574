import React from 'react';
import LazyImage from '../../../shared/Alerts/LazyImage';
const icons = {
    '15a': <LazyImage src="1/15.png" alt="Badminton" />,
    '15b': <LazyImage src="2/15.png" alt="Badminton" />,

    '18a': <LazyImage src="1/18.png" alt="Basketball" />,
    '18b': <LazyImage src="2/18.png" alt="Basketball" />,

    '21a': <LazyImage src="1/21.png" alt="Beach volleyball" />,
    '21b': <LazyImage src="2/21.png" alt="Beach volleyball" />,

    '24a': <LazyImage src="1/24.png" alt="Bowls" />,
    '24b': <LazyImage src="2/24.png" alt="Bowls" />,

    '25a': <LazyImage src="1/25.png" alt="Boxing" />,
    '25b': <LazyImage src="2/25.png" alt="Boxing" />,

    '28a': <LazyImage src="1/28.png" alt="Combat Sports" />,
    '28b': <LazyImage src="2/28.png" alt="Combat Sports" />,

    '29a': <LazyImage src="1/29.png" alt="Cricket" />,
    '29b': <LazyImage src="2/29.png" alt="Cricket" />,

    '43a': <LazyImage src="1/43.png" alt="Football" />,
    '43b': <LazyImage src="2/43.png" alt="Football" />,

    '45a': <LazyImage src="1/45.png" alt="Futsal" />,
    '45b': <LazyImage src="2/45.png" alt="Futsal" />,

    '48a': <LazyImage src="1/48.png" alt="Greyhounds" />,
    '48b': <LazyImage src="2/48.png" alt="Greyhounds" />,

    '50a': <LazyImage src="1/50.png" alt="Handball" />,
    '50b': <LazyImage src="2/50.png" alt="Handball" />,

    '52a': <LazyImage src="1/52.png" alt="Horses" />,
    '52b': <LazyImage src="2/52.png" alt="Horses" />,

    '53a': <LazyImage src="1/53.png" alt="Ice hockey" />,
    '53b': <LazyImage src="2/53.png" alt="Ice hockey" />,

    '54a': <LazyImage src="1/54.png" alt="Indoor Soccer" />,
    '54b': <LazyImage src="2/54.png" alt="Indoor Soccer" />,

    '71a': <LazyImage src="1/71.png" alt="Pool" />,
    '71b': <LazyImage src="2/71.png" alt="Pool" />,

    '93a': <LazyImage src="1/93.png" alt="Squash" />,
    '93b': <LazyImage src="2/93.png" alt="Squash" />,

    '94a': <LazyImage src="1/94.png" alt="Swimming" />,
    '94b': <LazyImage src="2/94.png" alt="Swimming" />,

    '96a': <LazyImage src="1/96.png" alt="Table Tennis" />,
    '96b': <LazyImage src="2/96.png" alt="Table Tennis" />,

    '98a': <LazyImage src="1/98.png" alt="Tennis" />,
    '98b': <LazyImage src="2/98.png" alt="Tennis" />,

    '101a': <LazyImage src="1/101.png" alt="Volleyball" />,
    '101b': <LazyImage src="2/101.png" alt="Volleyball" />,

    '104a': <LazyImage src="1/104.png" alt="eSports" />,
    '104b': <LazyImage src="2/104.png" alt="eSports" />,
};

export default icons;