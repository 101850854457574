import React, { useEffect, useRef, useState, useMemo } from "react";
import * as PR from "../../../../prime-react/index";
import Layout from "../../../../layouts/Layout";
import "./NewsCategories.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createNewsCategory, getNewsCategories, updateNewsCategory, deleteNewsCategory } from "../../../../services/api";

const NewsCategories = () => {
  const menuRight = useRef(null);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newsCategory, setNewsCategory] = useState([]);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
    };
  }, [sessionData.sessionId]);

  useEffect(() => {
    fetchCategories();
     // eslint-disable-next-line
  }, [headers, dispatch]);

  const fetchCategories = () => {
    getNewsCategories(headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setNewsCategory(result);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error.errorMsg,
        });
      }
    }, true, true);
  };

  const items = [
    {
      label: "Edit details",
      icon: "pi pi-pencil",
      command: () => openEditDialog(selectedCategory),
    },
    {
      label: "Delete category",
      icon: "pi pi-trash",
      command: () => handleDeleteCategory(),
    },
  ];

  const handleDeleteCategory = () => {

    if(selectedCategory?.newsUpdatesCount>0){
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Categories with news attached to them can't be deleted",
      });
      return;
    }
    deleteNewsCategory(selectedCategory.id,headers,dispatch,(response) =>{
      if (response.result === "SUCCESS") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category deleted successfully",
        });
        fetchCategories();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error.errorMsg,
        });
      }
    })
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Category name is required").max(20, "Category name must be 20 characters or less"),
    description: Yup.string().required("Category description is required").max(100, "Category description must be 100 characters or less") ,
  });

  const handleCreateCategory = (values, setSubmitting) => {
    if (isEdit && selectedCategory) {
      updateNewsCategory(
        { id: selectedCategory.id, ...values },
        headers,
        dispatch,
        (response) => {
          if (response.result === "SUCCESS") {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Category updated successfully",
            });
            fetchCategories();
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.error.errorMsg,
            });
          }
          setSubmitting(false);
          setVisible(false);
          setIsEdit(false);
          setSelectedCategory(null);
        }
      );
    } else {
      createNewsCategory(values, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Category created successfully",
          });
          fetchCategories();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.error.errorMsg,
          });
        }
        setSubmitting(false);
        setVisible(false);
      });
    }
  };

  const openEditDialog = (category) => {
    setSelectedCategory(category);
    setIsEdit(true);
    setVisible(true);
  };
  const footerContent = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {
          setVisible(false);
          setIsEdit(false);
          setSelectedCategory(null);
        }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label={isEdit ? "Update category" : "Create category"}
        type="submit"
        form="categoryForm"
        outlined
        autoFocus
        className="logout-btns click-btn"
      />
    </div>
  );

  return (
    <>
      <Layout backBtn={"news"}>
        <section className="all-alerts-section news-categories-section mt-5">
          <div className="m-0 flex align-items-center justify-content-between">
            <h1>News categories</h1>
            <PR.Button
              label="Create new category"
              icon="pi pi-plus"
              className="create-button"
              onClick={() => setVisible(true)}
            />
          </div>
          <div className="alerts-tab categories-list">
            <div className="grid">
              {Array.isArray(newsCategory) && newsCategory?.map((category) => (
                <div key={category?.id} className="col-3">
                  <div className="info-box flex justify-content-between h-full">
                    <div>
                      <div className="flex align-items-start justify-content-between">
                          <div className="pr-0">
                            <h2>{category?.name}</h2>
                          </div>
                          <div className="card">
                            <PR.Menu
                              model={items}
                              popup
                              ref={menuRight}
                              id="popup_menu_right"
                              className="export-popup"
                              popupAlignment="right"
                            />
                            <PR.Button
                              icon="pi pi-ellipsis-v"
                              className="export-button"
                              onClick={(event) => {
                                setSelectedCategory(category);
                                menuRight.current.toggle(event);
                              }}
                              aria-controls="popup_menu_right"
                              aria-haspopup
                            />
                          </div>
                      </div>
                      <p>{category?.description}</p>
                    </div>
                      <h4>
                        <i className="pi pi-book"></i> {category?.newsUpdatesCount >= 0 ? category?.newsUpdatesCount : 0}{" "} news items
                      </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Create/Edit Category Dialog */}
        <PR.Dialog
          visible={visible}
          footer={footerContent}
          onHide={() => {
            setVisible(false);
            setIsEdit(false);
            setSelectedCategory(null);
          }}
          className="logout-dialog response-dialog tags-dialog"
        >
          <Formik
            initialValues={isEdit && selectedCategory ? { name: selectedCategory.name, description: selectedCategory.description } : { name: "", description: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) =>
              handleCreateCategory(values, setSubmitting)
            }
          >
            {({ isSubmitting }) => (
              <Form id="categoryForm">
                <div className="dialog-header">
                  <div className="mb-5">
                    <h2>{isEdit ? "Edit category" : "Create category"}</h2>
                    <p>{isEdit ? "Edit the category details" : "Categorize the alert according to the relevant tag"}</p>
                  </div>
                </div>
                <div className="dialog-body-section">
                  <div className="flex flex-column form-input gap-2 mb-3">
                    <label htmlFor="name">
                      Category name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      name="name"
                      as={PR.InputText}
                      id="name"
                      type="text"
                      placeholder="Enter category name"
                      aria-describedby="name"
                      style={{ color: 'black' }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="description">
                      Category description{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                      <Field
                        name="description"
                        as={PR.InputTextarea}
                        placeholder="Something about category.."
                        className="description-textarea"
                        style={{ color: 'black' }}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error-message"
                      />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </PR.Dialog>
        <PR.Toast ref={toast} position="top-right" />
      </Layout>
    </>
  );
};

export default NewsCategories;
