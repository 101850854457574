import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
// import "./CreateAlert.scss";
import * as PR from "../../prime-react/index";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getLocations, getPlayers, getSports, getTournament, getTournamentLevels } from '../../services/api'
import { useDispatch, useSelector } from "react-redux";
import { cleanData, debounce, fileUploadLimit } from "../../utils/reuse";
import { RAW_FILTER_DATA } from '../common';
import moment from 'moment';

const EditAlertForm = ({ initialData, handleBack, handleNext, backLabel, nextLabel }) => {
    const dispatch = useDispatch();
    const isMember = useSelector((state) => state.auth.role) === "member";
    const isAnalyst = useSelector((state) => state.auth.role) === "analyst";
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return {
            sessionid: sessionData.sessionId,
            'Content-Type': 'multipart/form-data'
        };
    }, [sessionData.sessionId]);

    const alerts = RAW_FILTER_DATA.alertTypeFilter;
    const channels = RAW_FILTER_DATA.channels;
    const betTimeOptions = RAW_FILTER_DATA.timeOfBet;
    const betsStateOptions = RAW_FILTER_DATA.stateOfBetsFilter;
    const confirmOptions = RAW_FILTER_DATA.confirmOptions;
    const alertsRaised = RAW_FILTER_DATA.alertsRaised;
    const customerTypeOptions = RAW_FILTER_DATA.customerTypes;
    const gender = RAW_FILTER_DATA.gender;

    const toast = useRef(null);
    const [events, setEvents] = useState([]);
    const [sports, setSports] = useState([]);
    const [location, setLocation] = useState([]);
    const [tournamentLevel, setTournamentLevel] = useState([]);
    const [tournamentList, setTournamentList] = useState([]);
    const [filteredTournaments, setFilteredTournaments] = useState([]);
    const [deletedAttachments, setDeletedAttachments] = useState([]);
    let fileUploadRef = useRef(null);  //file ref

    const [maxBets, setMaxBets] = useState('');
    const [alertTime, setAlertTime] = useState('');
    const [alertTagList, setAlertTagList] = useState([]);
    const intialAlertTags = initialData?.tags;
    const [selectedAlertTags, setSelectedAlertTags] = useState(intialAlertTags);
    const [tagListShow, setTagListShow] = useState(false);
    
    const [initialValues, setInitialValues] = useState({
        alertType: null,
        numOfEvents: '',
        events: [],
        channel: [],
        betsState: [],
        betTime: [],
        maxBets: '',
        alertTime: '',
        customerType: [],
        customerDetails: [],
        newCustomer: { count: '', country: '' },
        description: '',
        attachments: null,
    })

    const validationSchema = () => {
        let schema = Yup.object().shape({
            alertType: Yup.object().nullable().required('Alert type required'),
            numOfEvents: Yup.number().required('Number of events required')
                .min(1, 'Number of events must be greater than or equal to 1')
                .max(10, 'Number of events must be less than or equal to 10'),
            channel: Yup.array().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.array().min(1, 'At least one channel must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            betsState: Yup.array().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.array().min(1, 'At least one state of bets must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            betTime: Yup.array().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.array().min(1, 'At least one timing of bet must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            alertTime: Yup.string().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.string().required('Time of alert is required'),
                otherwise: () => Yup.string().nullable(),
            }),
            customerType: Yup.array().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.array().min(1, 'At least one customer type must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            maxBets: Yup.string().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.string().required('Max Bets is required'),
                otherwise: () => Yup.string().optional(),
            }),
            customerDetails: Yup.array().of(
                Yup.object().shape({
                    count: Yup.number().min(0, 'Number of customers must be greater than or equal to 0').when(`isAnalyst`, {
                        is: () => !isAnalyst,
                        then: () => Yup.number().typeError('Number of customers must be a number').required('Number of customers is required'),
                        otherwise: () => Yup.number().optional(),
                    }),
                    country: Yup.object().when(`isAnalyst`, {
                        is: () => !isAnalyst,
                        then: () => Yup.object().required('Country required'),
                        otherwise: () => Yup.object().nullable(),
                    })
                })
            ),
            newCustomer: Yup.object().shape({
                count: Yup.number().min(0, 'Number of customers must be greater than or equal to 0').when(`isAnalyst`, {
                    is: () => !isAnalyst,
                    then: () => Yup.number().typeError('Number of customers must be a number').required('Number of customers is required'),
                    otherwise: () => Yup.number().optional(),
                }),
                country: Yup.object().when(`isAnalyst`, {
                    is: () => !isAnalyst,
                    then: () => Yup.object().required('Country required'),
                    otherwise: () => Yup.object().nullable(),
                })
            }),
            attachments: Yup.array().max(3, 'You can upload a maximum of 3 files')
                .nullable().of(Yup.mixed().nullable()
                    .test('fileSize', `File size must be less than ${fileUploadLimit.label}`, (value) => {
                        return !value || value.size <= fileUploadLimit.value; // 1 MB in bytes
                    })
                    .test('fileType', 'Only PDF, DOCX or XLSX files are allowed', (value) => {
                        if (!value) return true; // If no file is provided, return true
                        const acceptedTypes = ['pdf', 'docx', 'xlsx', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                        return acceptedTypes.includes(value.type);
                    })
                )
        });

        // Dynamically add validation rules for event fields
        for (let i = 0; i < events.length; i++) {
            schema = schema.shape({
                [`eventName-${i}`]: Yup.string().required(`Event name for event is required`),
                [`sport-${i}`]: Yup.object().nullable().required(`Sport for event is required`),
                [`league-${i}`]: Yup.mixed().required('League for event is required'),
                [`tournament-${i}`]: Yup.object().when([`sport-${i}`], {
                    is: (sportName) => sportName?.name?.toLowerCase() === 'tennis',
                    then: () => Yup.object().required('Tournament level for event is required'),
                    otherwise: () => Yup.object().nullable(),
                }),
                [`location-${i}`]: Yup.object().nullable().required(`Location for event is required`),
                [`gender-${i}`]: Yup.string().required(`Gender for event is required`),
                [`dateTime-${i}`]: Yup.date().required(`Date/Time of event is required`),
            });
        }
        return schema;
    };

    // Utility function for deep comparison
    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) return true;

        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
            return false;
        }

        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    };

    const handleSubmit = (formData) => {
        let updatedFields = {
            alertID: initialData?.alertID,
        };
        const obj = {
            alertType: formData.alertType.id,
            channel: formData.channel,
            timeOfBet: formData.betTime,
            stateOfBet: formData.betsState,
            maxBets: formData.maxBets,
            timeOfAlert: formData.alertTime,
        };

        if(isAnalyst) {
            obj.tags = selectedAlertTags;
        }
        if(deletedAttachments.length > 0){
            obj.deletedAttachments = deletedAttachments;
        }
        //
        let customerInfoChanged = false;
        const compareAndUpdate = (key, newValue) => {
            let initialValue

            if(key === 'customerDetails'){
                initialValue = initialData.customerInfo?.customerDetails
            }else if(key === 'customerType'){
                initialValue = initialData.customerInfo?.customerType
            }else{
                initialValue = initialData[key];
            }
            if (!deepEqual(newValue, initialValue)) {
                updatedFields[key] = newValue;
                if (key === 'customerDetails' || key === 'customerType') {
                    customerInfoChanged = true;
                }
            }
        };
    
        Object.keys(obj).forEach(key => {
            compareAndUpdate(key, obj[key]);
        });

        const customerDetails = formatCustomerInfo([...(formik.values?.customerDetails || []), formData?.newCustomer]);
        compareAndUpdate('customerDetails', customerDetails);
        compareAndUpdate('customerType', formData.customerType);
    
        if (customerInfoChanged) {
            updatedFields.customer = {
                customerDetails: customerDetails,
                customerType: formData.customerType
            }
            delete updatedFields.customerDetails
        }
        const eventsData = formatEventData(events);
        compareAndUpdate('events', eventsData);
        const refinedObj = cleanData(updatedFields);
        const formResponse = new FormData();
        formResponse.append('data', JSON.stringify(refinedObj));

        if (formData.attachments && formData.attachments.length > 0) {
            let newAttachments = formData.attachments
            if(initialData?.fileAttachment?.length>0){
                newAttachments = formData.attachments.filter(attachment => {
                    return !initialData.fileAttachment.some(file => file.key === attachment.key);
                })
            }
            newAttachments?.forEach((file, index) => {
                formResponse.append(`attachments`, file);
            });
        }
        handleNext(formResponse);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: () => {
            handleSubmit(formik.values);
        }
    });

    const prevInitialValuesRef = useRef(initialValues);

    useEffect(() => {
        if (initialValues !== prevInitialValuesRef.current) {
            formik.setValues(initialValues)
            prevInitialValuesRef.current = initialValues;
        }
    }, [initialValues, formik])

    const handleAdd = () => {
        // Use a default empty array if customerDetails is not defined or not an array
        const newCustomers = [...(formik.values?.customerDetails || []), { count: '', country: '' }];
        formik.setFieldValue('customerDetails', newCustomers);
    };
    
    const handleDelete = (index) => {
        const updatedCustomers = [...formik.values.customerDetails];
        updatedCustomers?.splice(index, 1);
        formik.setFieldValue('customerDetails', updatedCustomers);
    };
    const formatEventData = (events) => {
        const updatedEvents = events?.map(event => {
            let data = {}
            data.eventID = event.eventID;
            data.eventName = event.eventName;
            data.sportName = event.sport.name;
            data.sportID = event.sport.id;
            if(event.tournament.id){
                data.tournamentLevelID = event.tournament.id;
                data.tournamentLevelName = event.tournament.name;
            }
            data.tournamentName = event.league.name;
            data.tournamentID = event.league.id;
            data.locationID = event.location.id;
            data.locationName = event.location.name;
            data.dateTime = moment.utc(event.dateTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
            data.gender = event.gender;
            data.turnover = parseInt(event.turnover);

            return data;
        })
        return updatedEvents;
    }
    const formatCustomerInfo = (customers) => {
        const updatedCustomers = customers?.map(customer => {
            let data = {}
            data.count = customer?.count;
            data.country = customer?.country?.name;
            return data;
        })
        return updatedCustomers;
    }


    const handleEventDetailChange = (index, value, property) => {
        const newEvents = [...events];
        newEvents[index][property] = value;
        setEvents(newEvents);
        formik.setFieldValue(`${property}-${index}`, value);
        if (property === 'sport') {
            newEvents[index].tournament = {};   // Reset tournament field
            newEvents[index].league = {};       // Reset tournament level field
            formik.setFieldValue(`tournament-${index}`, null);
            formik.setFieldValue(`league-${index}`, null);
            setTournamentLevel([]);
            setEvents(newEvents);

            getTournamenthandler(value.id);
            if (value?.name?.toLowerCase() === 'tennis') {
                getTournmamentLevelsHandler(value);
            }
        }
        if (property === 'league') {
            if (value?.name.trim() === '') {
                formik.setFieldValue(`league-${index}`, null);
            }
        }
    }

    const handleMultiSelect = (e, fieldName) => {
        const { value } = e.target;
        const set = new Set(formik.values[fieldName]);
        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }
        formik.setFieldValue(fieldName, Array.from(set));
    }

    const handleMaxBets = (value) => {
        setMaxBets(value);
        formik.setFieldValue('maxBets', value)
    }

    const handleAlertTime = (value) => {
        setAlertTime(value);
        formik.setFieldValue('alertTime', value)
    }

    const handleNumOfEventsChange = (e) => {
        const numOfEvents = parseInt(e.target.value);
        if (!isNaN(numOfEvents)) {
            const existingEventsCount = events.length;
            if (numOfEvents > existingEventsCount) {
                // Add new events
                const newEvents = [...events];
                for (let i = existingEventsCount; i < numOfEvents; i++) {
                    newEvents.push({ eventName: '', sport: null });
                }
                setEvents(newEvents);
            } else if (numOfEvents < existingEventsCount) {
                // Remove excess events
                const newEvents = events.slice(0, numOfEvents);
                setEvents(newEvents);
            }
            formik.setFieldValue('numOfEvents', numOfEvents);
        }
        formik.handleChange(e);
    };

    //this is for debouncing effect AutoComplete Onchange 
    const handleEventDetailChangeDebounce = debounce((index, value, property) => {
        handleEventDetailChange(index, value, property);
    }, 300);

    // Get list of all the sports
    const getSportsHandler = useCallback(() => {
        getSports(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const sports = responseData;
                const result = (sports?.length > 0) ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setSports(result);
                if (result.length === 0) {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No records found' });
                }
            } else {
                setSports([]);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        })
    }, [dispatch, headers]);

    // Get list of all the locations
    const getLocationHandler = useCallback(() => {
        getLocations(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const locations = responseData;
                const result = (locations?.length > 0) ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setLocation(result);
                if (result.length === 0) {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No records found' });
                }
            } else {
                setLocation([]);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        })
    }, [dispatch, headers]);

    // Get list of all the tournament levels
    const getTournmamentLevelsHandler = useCallback(() => {
        getTournamentLevels(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const tournamentLevel = responseData;
                const result = (tournamentLevel?.length > 0) ? tournamentLevel?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setTournamentLevel(result);
            } else {
                setTournamentLevel([]);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        })
    }, [dispatch, headers]);

    //  function to filter searched results of autocomplete 
    const search = (event) => {
        const query = event.query?.toLowerCase();
        const filtered = tournamentList
            .filter(tournament => tournament.name?.toLowerCase().includes(query));
        setFilteredTournaments(filtered);
    }

    // Get all the tournaments based on SportId
    const getTournamenthandler = useCallback((sportId) => {
        const obj = {
            sportid: sportId,
        };
        getTournament(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const tournaments = responseData;
                setTournamentList(tournaments);
                setFilteredTournaments(tournaments);
            } else {
                setTournamentList([]);
                setFilteredTournaments([]);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        })
    }, [dispatch, headers]);

    useEffect(() => {
        getSportsHandler();
        getLocationHandler();
    }, [getSportsHandler, getLocationHandler]);

    useEffect(() => {
        let updatedInitialValues = {};

        //alertType
        let alertTypeName = alerts.find(item => item.id === initialData.alertType)?.name;
        updatedInitialValues['alertType'] = { name: alertTypeName, id: initialData.alertType }


        //channel
        updatedInitialValues['channel'] = initialData.channel;

        //timeOfBet
        updatedInitialValues['betTime'] = initialData.timeOfBet;

        //betsState
        updatedInitialValues['betsState'] = initialData.stateOfBet;

        //maxBets
        setMaxBets(initialData.maxBets)
        updatedInitialValues['maxBets'] = initialData.maxBets

        //alertTime
        setAlertTime(initialData.timeOfAlert)
        updatedInitialValues['alertTime'] = initialData.timeOfAlert;

        //CustomerType

        updatedInitialValues['customerType'] = initialData.customerInfo.customerType;

        //customerInfo
        const customerDetails = initialData.customerInfo?.customerDetails
        let formattedCustomers = [];

        // Map customers and find countryId
        formattedCustomers = customerDetails?.map(customerItem => {
            const countryId = location.find(item => item.name === customerItem.country)?.id;
            return {
                count: customerItem.count,
                country: { name: customerItem.country, id: countryId }
            };
        });

        if (formattedCustomers) {
            // Separate the last object from the array
            const lastIndex = formattedCustomers?.length - 1;
            const separatedObject = formattedCustomers[lastIndex];
            const remainingArray = formattedCustomers?.slice(0, lastIndex);

            updatedInitialValues['customerDetails'] = remainingArray;
            updatedInitialValues['newCustomer'] = separatedObject;
        }

        //desc
        updatedInitialValues['description'] = initialData.description;

        let eventsData = initialData.events;
        let updatedEvents = [];

        updatedEvents = eventsData.map((event, index) => {

            const formikEventData = {
                [`eventName-${index}`]: event['eventName'],
                [`sport-${index}`]: { name: event['sportName'], id: event['sportID'] },
                [`league-${index}`]: { name: event['tournamentName'], id: event['tournamentID'] },
                [`tournament-${index}`]: { name: event['tournamentLevelName'], id: event['tournamentLevelID'] },

                [`eventID-${index}`]: event['eventID'],
                [`dateTime-${index}`]: moment.utc(event.dateTime).local().toDate(),
                [`location-${index}`]: { name: event['locationName'], id: event['locationID'] },
                [`gender-${index}`]: event['gender'],
                [`turnover-${index}`]: event['turnover']
            };

            const updatedEvent = {
                eventName: event['eventName'],
                sport: { name: event['sportName'], id: event['sportID'] },
                league: { name: event['tournamentName'], id: event['tournamentID'] },
                tournament: { name: event['tournamentLevelName'], id: event['tournamentLevelID'] },

                eventID: event['eventID'],
                dateTime: moment.utc(event.dateTime).local().toDate(),
                location: { name: event['locationName'], id: event['locationID'] },
                gender: event['gender'],
                turnover: event['turnover']
            };

            updatedInitialValues = { ...updatedInitialValues, ...formikEventData };

            return updatedEvent;
        });

        //num of Events
        updatedInitialValues['numOfEvents'] = updatedEvents.length;
        updatedInitialValues['attachments'] = initialData?.fileAttachment?.filter((file) => file.owner !== 'IBIA');

        setEvents(updatedEvents);


        setInitialValues(updatedInitialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);


    // function to delete an attachment 
    const handleDeleteFile = (index) => {
        const deletedFile = formik.values.attachments[index];
        if (deletedFile.key) {
            setDeletedAttachments(prevDeleted => [
                ...prevDeleted,
                { key: deletedFile.key, type: deletedFile.type }
            ]);
        }

        const updatedAttachments = [...formik.values.attachments];
        updatedAttachments?.splice(index, 1);
        formik.setFieldValue('attachments', updatedAttachments);
    };

    // Get list of all the Players
  const getPlayerTags = useCallback(() => {
    getPlayers('', headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        setTagListShow(true);
        const responseData = response.data;
        const Players = responseData;
        const result = Players?.length > 0 ? Players?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setAlertTagList(result);
      } else {
        setAlertTagList([]);
        const error = response.error;
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
    });
  }, [dispatch, headers]);

    useEffect(() => {
        getPlayerTags();
    }, [getPlayerTags]);

    const handleAlertTagChange = (e) => {
        setSelectedAlertTags(e.value);
    }

    return (
        <>
            {/* Update alert form section starts here */}
            <div className="form-section">
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="form-body">
                        <div className="grid align-items-center">
                            <div className="col-12 mb-2">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="alertType">Type of alert<span className="important">*</span></label>
                                    <PR.Dropdown
                                        value={formik.values.alertType}
                                        onChange={(e) => {
                                            formik.setFieldValue('alertType', e.value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="alertType"
                                        options={alerts}
                                        optionLabel="name"
                                        placeholder="Select type of alert"
                                        className="w-full alert-drop-down"
                                        disabled={true}
                                    />
                                    {formik.errors.alertType && formik.touched.alertType && (
                                        <div className="error">{formik.errors.alertType}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column form-input gap-2">
                                    <label htmlFor="numOfEvents">Number of events<span className="important">*</span></label>
                                    <PR.InputText
                                        id="numOfEvents"
                                        type="number"
                                        placeholder="Number of Events"
                                        onChange={handleNumOfEventsChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.numOfEvents}
                                        aria-describedby="events-help"
                                        min={0}
                                        max={10}
                                        disabled={true}
                                    />
                                    {formik.errors.numOfEvents && formik.touched.numOfEvents && (
                                        <div className="error">{formik.errors.numOfEvents}</div>
                                    )}
                                </div>
                                {events?.length !== 0 && <div className="events-section">
                                    {/* Event */}
                                    {events?.map((event, index) => (
                                        <div className="grid align-items-baseline" key={'event' + index}>
                                            <div className="custom-col">
                                                <span className="number">{index + 1}</span>
                                            </div>
                                            <div className="col">
                                                <div className="flex flex-column form-input gap-2 mb-4">
                                                    <label htmlFor={`eventName-${index}`}>Event name<span className="important">*</span></label>
                                                    <PR.InputText
                                                        type="text"
                                                        aria-describedby="eventname"
                                                        name={`eventName-${index}`}
                                                        onBlur={formik.handleBlur}
                                                        value={event.eventName}
                                                        onChange={(e) => handleEventDetailChange(index, e.target.value, 'eventName')}
                                                    />
                                                    {(formik.touched[`eventName-${index}`] || formik.submitCount > 0) && formik.errors[`eventName-${index}`] && (
                                                        <div className="error">{formik.errors[`eventName-${index}`]}</div>
                                                    )}
                                                </div>
                                                <div className="flex flex-column gap-2 mb-4">
                                                    <label htmlFor={`sport-${index}`}>Select sport<span className="important">*</span></label>
                                                    <PR.Dropdown
                                                        value={event.sport}
                                                        name={`sport-${index}`}
                                                        onChange={(e) => handleEventDetailChange(index, e.value, 'sport')}
                                                        options={sports} optionLabel="name"
                                                        placeholder="Select sport"
                                                        filter
                                                        onBlur={formik.handleBlur}
                                                        className="w-full alert-drop-down"
                                                    />
                                                    {(formik.touched[`sport-${index}`] || formik.submitCount > 0) && formik.errors[`sport-${index}`] && (
                                                        <div className="error">{formik.errors[`sport-${index}`]}</div>
                                                    )}
                                                </div>
                                                <div className="grid mb-4">
                                                    <div className="col-6">
                                                        <div className="flex flex-column form-input gap-2">
                                                            <label htmlFor={`league-${index}`}>League/ Tournament<span className="important">*</span></label>
                                                            <PR.AutoComplete
                                                                name={`league-${index}`}
                                                                onBlur={formik.handleBlur}
                                                                dropdownAutoFocus={true}
                                                                value={event.league ? event.league?.name : ''}
                                                                onChange={(e) => {
                                                                    if (typeof e.value === 'string') {
                                                                        handleEventDetailChangeDebounce(index, { name: e.value }, 'league'); // value is a string
                                                                    } else if (e.value) {
                                                                        handleEventDetailChangeDebounce(index, e.value, 'league'); //value is an object
                                                                    }
                                                                }}
                                                                aria-describedby="league"
                                                                suggestions={filteredTournaments}
                                                                completeMethod={search}
                                                                itemTemplate={(tournament) => tournament.name}
                                                                className="form-autocomplete"
                                                                disabled={!event.sport}
                                                            />
                                                            {(formik.touched[`league-${index}`] || formik.submitCount > 0) && formik.errors[`league-${index}`] && (
                                                                <div className="error">{formik.errors[`league-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor={`tournament-${index}`}>Tournament level {(event.sport?.name?.toLowerCase() === 'tennis') && <span className="important">*</span>}</label>
                                                            <PR.Dropdown
                                                                value={event.tournament}
                                                                name={`tournament-${index}`}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'tournament')}
                                                                options={tournamentLevel}
                                                                optionLabel="name"
                                                                filter
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Select tournament level"
                                                                className="w-full alert-drop-down"
                                                                disabled={tournamentLevel.length <= 0}
                                                            />
                                                            {(formik.touched[`tournament-${index}`] || formik.submitCount > 0) && formik.errors[`tournament-${index}`] && (
                                                                <div className="error">{formik.errors[`tournament-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-4">
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="firstname">Date/ Time of event<span className="important">*</span></label>
                                                            <PR.Calendar
                                                                name={`dateTime-${index}`}
                                                                placeholder="Select date"
                                                                value={event.dateTime}
                                                                onFocus={() => formik.setFieldTouched(`dateTime-${index}`)}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'dateTime')}
                                                                showTime hourFormat="12"
                                                                showIcon iconPos="right"
                                                                className="date-calendar"
                                                                maxDate={new Date()}
                                                            />
                                                            {(formik.touched[`dateTime-${index}`] || formik.submitCount > 0) && formik.errors[`dateTime-${index}`] && (
                                                                <div className="error">{formik.errors[`dateTime-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="location">Location of event<span className="important">*</span></label>
                                                            <PR.Dropdown
                                                                value={event.location}
                                                                name={`location-${index}`}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'location')}
                                                                options={location} optionLabel="name"
                                                                onBlur={formik.handleBlur}
                                                                filter
                                                                placeholder="Select event location"
                                                                className="w-full alert-drop-down"
                                                            />
                                                            {(formik.touched[`location-${index}`] || formik.submitCount > 0) && formik.errors[`location-${index}`] && (
                                                                <div className="error">{formik.errors[`location-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-4">
                                                    <div className="col-12">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="firstname">Gender<span className="important">*</span></label>
                                                            <div className="card flex mt-1 gap-3">
                                                                {gender.map((option) => (
                                                                    <div className="flex flex-wrap gap-2" key={'gender' + option.id}>
                                                                        <div className="flex align-items-center">
                                                                            <PR.RadioButton
                                                                                disabled={isMember ? true : false}
                                                                                inputId={`gender${index}`}
                                                                                name="gender"
                                                                                value={option.id}
                                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'gender')}
                                                                                checked={event.gender === option.id}
                                                                            />
                                                                            <label htmlFor={`gender${index}`} className="ml-2">{option.name}</label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {(formik.touched[`gender-${index}`] || formik.submitCount > 0) && formik.errors[`gender-${index}`] && (
                                                                <div className="error">{formik.errors[`gender-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-0">
                                                    <div className="col-12">
                                                        <div className="flex flex-column form-input gap-2">
                                                            <label htmlFor="turnover">How much did the suspicious turnover account for as a percentage of the event as a whole, if available?</label>
                                                            <PR.InputText
                                                                name={`turnover-${index}`}
                                                                value={event.turnover || ''}
                                                                onChange={(e) => handleEventDetailChange(index, e.target.value, 'turnover')}
                                                                type="text"
                                                                aria-describedby="eventname"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4 mb-4" />
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Channel {!isAnalyst && <span className="important">*</span>}</label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                        {channels.map((channel) => (
                                            <div className="flex align-items-center" key={'channel' + channel.id}>
                                                <PR.Checkbox
                                                    value={channel.id}
                                                    onChange={(e) => handleMultiSelect(e, 'channel')}
                                                    checked={formik.values.channel?.includes(channel.id)}></PR.Checkbox>
                                                <label htmlFor={channel.id} className="ml-2 font-medium">{channel.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formik.errors.channel && (formik.touched.channel || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.channel}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Time of bet {!isAnalyst && <span className="important">*</span>}</label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                        {betTimeOptions.map((option) => (
                                            <div className="flex align-items-center" key={'betTime' + option.id}>
                                                <PR.Checkbox
                                                    value={option.id}
                                                    onChange={(e) => handleMultiSelect(e, 'betTime')}
                                                    checked={formik.values.betTime?.includes(option.id)}></PR.Checkbox>
                                                <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formik.errors.betTime && (formik.touched.betTime || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.betTime}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">State of the bets {!isAnalyst && <span className="important">*</span>}</label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                        {betsStateOptions.map((option) => (
                                            <div className="flex align-items-center" key={'betState' + option.id}>
                                                <PR.Checkbox
                                                    value={option.id}
                                                    onChange={(e) => handleMultiSelect(e, 'betsState')}
                                                    checked={formik.values.betsState?.includes(option.id)}></PR.Checkbox>
                                                <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formik.errors.betsState && (formik.touched.betsState || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.betsState}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Max bets {!isAnalyst && <span className="important">*</span>}</label>
                                    <div className="card flex mt-1 gap-3">
                                        {confirmOptions.map((option) => (
                                            <div className="flex flex-wrap gap-2" key={'confirmOption' + option.id}>
                                                <div className="flex align-items-center">
                                                    <PR.RadioButton
                                                        inputId="bet-yes"
                                                        name="bets"
                                                        value={option.id}
                                                        onChange={(e) => handleMaxBets(e.value)}
                                                        checked={maxBets === option.id}
                                                    />
                                                    <label htmlFor="bet-yes" className="ml-2 font-medium">{option.name}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {formik.errors.maxBets && (formik.touched.maxBets || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.maxBets}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">When is the alert being raised?{!isAnalyst && <span className="important">*</span>}</label>
                                    <div className="card flex mt-1 gap-3">
                                        {alertsRaised.map((option) => (
                                            <div className="flex flex-wrap gap-2" key={'alertsRaised' + option.id}>
                                                <div className="flex align-items-center">
                                                    <PR.RadioButton
                                                        inputId="pre-event"
                                                        name="raised"
                                                        value={option.id}
                                                        onChange={(e) => handleAlertTime(e.value)}
                                                        checked={alertTime === option.id}
                                                    />
                                                    <label htmlFor="pre-event" className="ml-2 font-medium">{option.name}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {formik.errors.alertTime && (formik.touched.alertTime || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.alertTime}</div>
                                    )}
                                </div>
                            </div>
                            {(isAnalyst && tagListShow) && 
                                <div className="col-12">
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="firstname">Tag alert</label>
                                        <div className="card flex mt-1 gap-3">
                                            <PR.MultiSelect 
                                                filter 
                                                value={selectedAlertTags} 
                                                onChange={handleAlertTagChange} 
                                                options={alertTagList} 
                                                optionLabel="name"
                                                placeholder="Select Tags"
                                                showSelectAll={false} 
                                                className="w-full alert-drop-down"
                                                display="chip"
                                                dataKey="id"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-12">
                                <p className="customer_info">CUSTOMER INFO</p>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Type of customers {!isAnalyst && <span className="important">*</span>}</label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                        {customerTypeOptions.map((option) => (
                                            <div className="flex align-items-center" key={'customerType' + option.id}>
                                                <PR.Checkbox
                                                    value={option.id}
                                                    onChange={(e) => handleMultiSelect(e, 'customerType')}
                                                    checked={formik.values.customerType?.includes(option.id)}></PR.Checkbox>
                                                <label htmlFor={option.id} className="ml-2 font-medium capitalize-first">{option.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formik.errors.customerType && (formik.touched.customerType || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.customerType}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Number of customers involved {!isAnalyst && <span className="important">*</span>}</label>
                                    {/* Add More Section */}
                                    <div className="grid">
                                        <div className="col-4 form-input">
                                            <PR.InputText
                                                type="number"
                                                name="newCustomer.count"
                                                value={formik.values.newCustomer?.count || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="No. of customers"
                                                aria-describedby="events-help"
                                                min={0}
                                            />
                                            {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                <div className="error mt-1">{formik.errors.newCustomer.count}</div>
                                            )}
                                        </div>
                                        <div className="col-5">
                                            <PR.Dropdown
                                                name="newCustomer.country"
                                                value={formik.values.newCustomer?.country || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                options={location} optionLabel="name"
                                                placeholder="Select country"
                                                filter
                                                className="w-full alert-drop-down"
                                            />
                                            {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                <div className="error mt-1">{formik.errors.newCustomer.country}</div>
                                            )}
                                        </div>
                                        <div className="col-3 form-input">
                                            <PR.Button label="Add more" type="button" icon="pi pi-plus" className="addmore-button" onClick={handleAdd} />
                                        </div>
                                    </div>
                                    {formik.values.customerDetails?.map((customer, index) => {
                                        return (
                                            <div className="grid" key={'customer' + index}>
                                                <div className="col-4 form-input">
                                                    <PR.InputText
                                                        name={`customerDetails[${index}].count`}
                                                        type="number"
                                                        value={formik.values.customerDetails[index]?.count}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="No. of customers"
                                                        aria-describedby="events-help"
                                                        min={0}
                                                    />
                                                    {formik.touched.customerDetails && formik.touched.customerDetails[index] && formik.errors.customerDetails && formik.errors.customerDetails[index] && formik.errors.customerDetails[index].count && (
                                                        <div className="error mt-1">{formik.errors.customerDetails[index].count}</div>
                                                    )}
                                                </div>
                                                <div className="col-5">
                                                    <PR.Dropdown
                                                        name={`customerDetails[${index}].country`}
                                                        value={formik.values.customerDetails[index].country}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        options={location} optionLabel="name"
                                                        placeholder="Select country"
                                                        filter
                                                        className="w-full alert-drop-down"
                                                    />
                                                    {formik.touched.customerDetails && formik.touched.customerDetails[index] && formik.errors.customerDetails && formik.errors.customerDetails[index] && formik.errors.customerDetails[index].country && (
                                                        <div className="error mt-1">{formik.errors.customerDetails[index].country}</div>
                                                    )}
                                                </div>
                                                <div className="col-3 form-input">
                                                    <PR.Button
                                                        label="Remove"
                                                        type="button"
                                                        icon="pi pi-minus"
                                                        className="addmore-button"
                                                        onClick={() => handleDelete(index)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {/* Add More Section */}
                                </div>
                            </div>
                            <div className="col-7">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Attach file</label>
                                    <div className="card file-upload flex align-items-center">
                                        <PR.FileUpload
                                            auto
                                            multiple
                                            mode="advanced"
                                            name="attachments"
                                            accept=".pdf,.docx,.xlsx"
                                            chooseLabel="Choose File"
                                            uploadLabel="Upload"
                                            emptyTemplate={null}
                                            itemTemplate={null}
                                            ref={fileUploadRef}
                                            onSelect={(event) => {
                                                const currentAttachments = formik.values.attachments || [];
                                                const newAttachments = event.files;
                                            
                                                const duplicateFiles = newAttachments.filter((newFile) =>
                                                    currentAttachments.some(
                                                        (existingFile) => existingFile.name === newFile.name
                                                    )
                                                );
                                            
                                                if (duplicateFiles?.length > 0) {
                                                    toast.current?.show({
                                                        severity: "warn",
                                                        summary: "Duplicate File Names",
                                                        detail: "File names must be unique. Please rename your file(s) before uploading.",
                                                    });
                                                    fileUploadRef.current.clear();
                                                    return;
                                                }
                                            
                                                if (currentAttachments?.length + newAttachments?.length > 3) {
                                                    toast.current.show({
                                                        severity: 'warn',
                                                        summary: 'Warning',
                                                        detail: 'You can upload a maximum of 3 files.',
                                                    });
                                                    fileUploadRef.current.clear();
                                                    return;
                                                }
                                            
                                                const validFiles = newAttachments?.filter((file) => {
                                                    if (file.size > fileUploadLimit.value) {
                                                        toast.current.show({
                                                            severity: 'error',
                                                            summary: 'File Too Large',
                                                            detail: `${file.name} is larger than ${fileUploadLimit.label}.`,
                                                        });
                                                        return false;
                                                    }
                                                    return true;
                                                });
                                            
                                                if (validFiles?.length !== newAttachments?.length) {
                                                    fileUploadRef.current.clear();
                                                }
                                            
                                                const updatedAttachments = [...currentAttachments, ...validFiles];
                                                formik.setFieldValue('attachments', updatedAttachments);
                                            }}
                                            
                                            onFocus={() => formik.setFieldTouched(`attachments`)}
                                            customUpload
                                        />
                                        <span className="ml-2"> <span className="block">(PDF, DOCX, XLSX)</span>File should be less than {fileUploadLimit.label}.</span>
                                    </div>

                                    {formik.values.attachments && (
                                        <div className="uploaded-file-container flex flex-column gap-2">
                                            {formik.values.attachments?.map((attachment, index) => (
                                                <div className="uploaded-files" key={'attachments' + index}>
                                                    {attachment.name}
                                                    <i className="pi pi-trash" onClick={() => handleDeleteFile(index)} />
                                                </div>
                                            )
                                            )}
                                        </div>
                                    )}
                                    {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                                        <div className="error">
                                            {Array.isArray(formik.errors.attachments) ? (
                                                Array.from(new Set(formik.errors?.attachments))?.map((error, index) => (
                                                    <div key={'attachment' + index}>{error}</div>
                                                ))
                                            ) : (
                                                <div>{formik.errors.attachments}</div>
                                            )}
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className="form-footer flex align-items-center justify-content-end gap-3 mt-2">
                            <PR.Button
                                label={backLabel}
                                type="button"
                                onClick={handleBack}
                                className="cancel-button"
                            />
                            <PR.Button
                                label={nextLabel}
                                className="create-alert-button"
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
            {/* Update alert form section ends here */}
            <PR.Toast ref={toast} position='top-right' />
        </>
    );
};

export default EditAlertForm;