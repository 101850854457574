import React, { useEffect, useState } from "react";
import "../WeeklyIntegrityPreview/weeklyIntegrity.scss";
import Layout from "../../../layouts/Layout";
import * as PR from "../../../prime-react/index";
import { Link } from "react-router-dom";
import dbData from "../../../assets/data/db.json";
import { DateFormat } from "../../../utils/reuse";
import { upcomingNews } from "../../../assets/images/index";

const WeeklyIntegrityPreview = () => {
  const [newsDetail, setNewsDetail] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setNewsDetail(dbData.weeklyIntegrity);
    setLoading(false);
  }, []);

  if (loading || !newsDetail) {
    return (
      <div className="loading">
        <i className="pi pi-spin pi-spinner"></i>
      </div>
    );
  }

  return (
    <Layout>
      <div className="back-button">
        <Link to="/news">
          <i className="pi pi-arrow-left"></i>
          Back to news
        </Link>
      </div>
      <section className="news-tab news-details-section">
        <div className="grid">
          <div className="col-9">
            <h1>{newsDetail.title}</h1>
          </div>
        </div>
        <div className="grid">
          <div className="col-9">
            <div className="new-list-section">
              <div className="list-wrapper">
                <div className="news-list">
                  <div className="card">
                    <div className="col-12 p-0">
                      <div className="flex align-items-center justify-content-between">
                        <ul className="flex list-none align-items-center gap-4">
                          <li>
                            <i className="pi pi-user"></i>{" "}
                            <span className="author">{newsDetail.author}</span>
                          </li>
                        </ul>
                        <ul className="flex list-none align-items-center gap-4">
                          <li>
                            <i className="pi pi-calendar"></i>{" "}
                            <span>{DateFormat(newsDetail.date)}</span>
                          </li>
                        </ul>
                      </div>
                      <PR.Image
                        src={upcomingNews}
                        alt="image"
                        className="news-img"
                      />
                    </div>
                    <div className="col-12 p-0">
                      <div className="content">
                        <p>
                          {newsDetail.description
                            .split("\n")
                            .map((text, index) => (
                              <React.Fragment key={index}>
                                {text}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default WeeklyIntegrityPreview;
