import React, { useMemo, useEffect, useState, useRef } from "react";
import * as PR from "../../prime-react/index";
import "./Profile.scss";
import Layout from "../../layouts/Layout";
// import ProfileMenuBar from "../ProfileMenuBar";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { getFileDetails, updateProfile } from "../../services/api";
import { authActions } from "../../store/auth";
import secureLocalStorage from "react-secure-storage";
import { fileUploadLimit } from "../../utils/reuse";

const Profile = () => {
    const role = useSelector(state => state.auth.role);
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [profilePic, setProfilePic] = useState();
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const getProfilePic = (key) => {
        if (!key) {
            setLoading(false)
            return;
        }
        getFileDetails(key, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                setProfilePic(URL.createObjectURL(response?.data));
                dispatch(authActions.storeProfileImage(URL.createObjectURL(response?.data)))
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
            }
            setLoading(false);
        });
    };

    const profileData = useSelector(state => state.auth.profileData);
    useEffect(() => {
        getProfilePic(profileData?.profilePic?.key);
        // eslint-disable-next-line
    }, [profileData]);

    const validationSchema = Yup.object({
        name: Yup.string().required("User name is required"),
        profilePhoto: Yup.mixed()
            .nullable()
            .test("fileSize", `File size must be less than ${fileUploadLimit.label}`, value => {
                return !value || value.size <= fileUploadLimit.value;
            })
            .test("fileType", "Only image files are allowed", value => !value || ["image/jpeg", "image/png", "image/gif"].includes(value.type)),
        password: Yup.string()
            .notRequired()
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@$%&*()])[a-zA-Z0-9!@$%&*()]{8,}$/,
                "Password must contain at least one uppercase letter, one number, one special character from !@$%&*(), and be at least 8 characters long."
            ),
        confirmPassword: Yup.string()
            .when('password', {
                is: (password) => password && password.length > 0,
                then: (schema) => schema.required('Please confirm your password').oneOf([Yup.ref('password'), null], 'Passwords must match'),
                otherwise: (schema) => schema.notRequired(),
            })
    });

    const setHeaderPic = (parseImage) => {
        if (parseImage?.key && (parseImage.type === "image/jpeg" || parseImage.type === "image/jpg" || parseImage.type === "image/png")) {
            getFileDetails(parseImage.key, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    dispatch(authActions.storeProfileImage(URL.createObjectURL(response?.data)))
                }
            });
        }
    }
    const initialValues = {
        name: profileData?.name,
        email: profileData?.email,
        profilePhoto: profileData?.profilePic,
        password: '',
        oldPassword: '',
        confirmPassword: ''
    };
    let imageUploadRef = useRef(null);
    const handleSubmit = (formValues, { setSubmitting, setErrors }) => {

        const payload = {
            name: formValues.name,
            email: formValues.email,
            id: sessionData.userID
        };
        if (profilePic === null) {
            payload.deleteImage = true;
        }

        if (formValues.password) {
            payload.password = formValues.password;
        }
        const formData = new FormData();
        formData.append("data", JSON.stringify(payload));
        if (formValues.profilePhoto && formValues.profilePhoto?.key === undefined && profilePic !== null) {
            formData.append("attachments", formValues.profilePhoto);
        }
        updateProfile(formData, headers, dispatch, (response) => {
            if (response.result === "SUCCESS") {
                toast.current?.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Profile updated successfully",
                });
                const oldData = secureLocalStorage.getItem('profileData')
                const profileData = {
                    ...oldData,
                    name: formValues.name,
                }
                if (response.data === 'ok') {
                    profileData.profilePic = null
                    dispatch(authActions.storeProfileImage(null))
                } else if (response.data) {
                    profileData.profilePic = response.data
                    setHeaderPic(response.data)
                }
                secureLocalStorage.setItem('profileData', profileData);
                if (formValues.password) {
                    dispatch(authActions.onLogout());
                }
            } else {
                toast.current?.show({
                    severity: response.error.severity,
                    summary: response.error.summary,
                    detail: response.error.errorMsg,
                });
            }
            setSubmitting(false);
        });
    };
    const handleDeleteImage = (values) => {
        setProfilePic(null);
    };

    const handleFileChange = (event) => {
        const file = event.files[0]; // Accessing files from target
        if (file) {
            const objectURL = URL.createObjectURL(file);
            setProfilePic(objectURL);
        }
    }

    return (
        <>
            <Layout backBtn={'home'}>
                {
                    loading
                        ? (<div className="loading">
                            <i className="pi pi-spin pi-spinner"></i>
                        </div>)
                        : (<section className="profile-page-section">
                            <div className="profile-wrapper">
                                <div className="profile-tabs-section">
                                    <div className="grid justify-content-center">
                                        <div className="col-9">
                                            <div className="m-0">
                                                <h1>Profile</h1>
                                            </div>

                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={(values, { setSubmitting }) =>
                                                    handleSubmit(values, { setSubmitting })
                                                }
                                            >
                                                {({
                                                    isSubmitting,
                                                    errors,
                                                    touched,
                                                    values,
                                                    handleChange,
                                                    setFieldValue,
                                                }) => {
                                                    return (
                                                        <Form id="profileForm">
                                                            <div className="form-section">
                                                                <div className="section">
                                                                    <div className="form-header">
                                                                        <p>Personal details</p>
                                                                    </div>
                                                                    <div className="form-body">
                                                                        <div className="grid align-items-center mb-2">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <label htmlFor="profilepic">Profile pic</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2 relative">
                                                                                    <div className="profile-upload">
                                                                                        <PR.FileUpload
                                                                                            auto
                                                                                            mode="advanced"
                                                                                            name="profilePhoto"
                                                                                            accept="image/*"
                                                                                            ref={imageUploadRef}
                                                                                            label="false"
                                                                                            customUpload
                                                                                            onSelect={(event) => {
                                                                                                if (imageUploadRef.current) {
                                                                                                    imageUploadRef.current.clear();
                                                                                                }
                                                                                                setFieldValue("profilePhoto", event.files[0]);
                                                                                                handleFileChange(event);
                                                                                            }}
                                                                                        />
                                                                                        <PR.Image
                                                                                            src={profilePic}
                                                                                            alt={'news?.title'}
                                                                                            className="profile-photo"
                                                                                        />
                                                                                    </div>
                                                                                    {profilePic && (
                                                                                        <span className="delete-icon"><i className="pi pi-trash" onClick={handleDeleteImage}></i></span>
                                                                                    )}
                                                                                </div>
                                                                                {errors.profilePhoto && (
                                                                                    <div className="error-message">{errors.profilePhoto}</div>
                                                                                )}  
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid align-items-center mb-2">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <label htmlFor="name">Name</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <PR.InputText
                                                                                        id="name"
                                                                                        value={values.name}
                                                                                        aria-describedby="name"
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                {errors.name && touched.name && (
                                                                                    <div className="error-message">{errors.name}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid align-items-center mb-2">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <label htmlFor="email">Email address</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <PR.InputText
                                                                                        id="email"
                                                                                        aria-describedby="email-help"
                                                                                        value={values.email}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid align-items-center">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <label htmlFor="company">Company</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <span className="company-name">{sessionData?.groupName}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-4">
                                                                    <div className="section">
                                                                        <div className="form-header">
                                                                            <p>Change password</p>
                                                                        </div>
                                                                        <div className="form-body">
                                                                            <div className="grid align-items-start mb-2">


                                                                            </div>
                                                                            <div className="grid align-items-start mb-2">
                                                                                <div className="col-4">
                                                                                    <div className="flex flex-column gap-2">
                                                                                        <label htmlFor="password">New password</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <div className="password-wrapper">
                                                                                        <PR.InputText
                                                                                            id="password"
                                                                                            type={showPassword ? "text" : "password"}
                                                                                            value={values.password}
                                                                                            onChange={handleChange}
                                                                                            autoComplete="new-password"
                                                                                            aria-describedby="password"
                                                                                            className="p-inputtext password-input"
                                                                                        />
                                                                                        <i
                                                                                            className={`pi ${showPassword ? "pi-eye-slash" : "pi-eye"} eye-icon`}
                                                                                            onClick={() => setShowPassword(prev => !prev)}
                                                                                        ></i>
                                                                                    </div>
                                                                                    {errors.password && touched.password && (
                                                                                        <div className="error-message">{errors.password}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="grid align-items-start mb-2">
                                                                                <div className="col-4">
                                                                                    <div className="flex flex-column gap-2">
                                                                                        <label htmlFor="password">Confirm password</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <div className="password-wrapper">
                                                                                        <PR.InputText
                                                                                            id="confirmPassword"
                                                                                            type={showConfirmPassword ? "text" : "password"}
                                                                                            value={values.confirmPassword}
                                                                                            onChange={handleChange}
                                                                                            aria-describedby="confirmPassword"
                                                                                            className="p-inputtext password-input"
                                                                                        />
                                                                                        <i
                                                                                            className={`pi ${showConfirmPassword ? "pi-eye-slash" : "pi-eye"} eye-icon`}
                                                                                            onClick={() => setShowConfirmPassword(prev => !prev)}
                                                                                        ></i>
                                                                                    </div>
                                                                                    {errors.confirmPassword && touched.confirmPassword && (
                                                                                        <div className="error-message">{errors.confirmPassword}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-footer">
                                                                    <PR.Button
                                                                        label="Save changes"
                                                                        className={`${role === 'regulator' && 'regulator'} save-button`}
                                                                        type="submit"
                                                                        form="profileForm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                        {/* {role === 'member' && (
                                            <div className="col-3 mt-6">
                                                <div className="vertical-menu">
                                                    <ProfileMenuBar />
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </section>)
                }
                <PR.Toast ref={toast} position='top-right' />
            </Layout>
        </>
    );
};

export default Profile;
