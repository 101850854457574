import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../layouts/Layout';
import * as PR from '../../prime-react/index';
import { news1, news2, news3 } from '../../assets/images/index';
import { DateFormat } from '../../utils/reuse';
import { useDispatch, useSelector } from 'react-redux';
import { getFileDetails, getReportById } from '../../services/api';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Link } from 'react-router-dom';
import "./Reports.scss";

const ReportDetail = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const reportID = queryParams.get('id');
    const [report, setReport] = useState(null);
    const dispatch = useDispatch();
    const toast = useRef(null);
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const isRegulator = useSelector(state => state.auth.role) === 'regulator';

    useEffect(() => {
        getReportById(reportID, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                if (responseData && responseData?.length > 0) {
                    setReport(responseData[0]);
                } else {
                    setReport([]);
                }
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
            }
        });
    }, [reportID, headers, dispatch]);

    if (!report) return <div className="loading"><i className="pi pi-spin pi-spinner"></i></div>;

    const fetchFileDetails = (key, fileName) => {
        const convertBlobToPDFAndDownload = async (pdfData, fileName) => {
            try {
                const url = URL.createObjectURL(pdfData);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName || 'file.pdf');

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error converting base64 to PDF:', error);
            }
        };

        getFileDetails(key, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const fileData = response.data;
                convertBlobToPDFAndDownload(fileData, fileName);
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
            }
        });
    };

    const downloadReportAsPDF = async () => {
        const buttons = document.querySelectorAll(".hide-on-download");
        buttons.forEach(button => button.style.display = "none");

        const content = document.getElementById("report-content");
        const canvas = await html2canvas(content);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
        });

        const margin = 10;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", margin, margin, pdfWidth, pdfHeight);
        pdf.save("report.pdf");

        buttons.forEach(button => button.style.display = "");
    };

    const imageMap = {
        'suspicious betting': news1,
        'summary': news2,
        'others': news3,
    };

    return (
        <Layout backBtn={'reports'}>
            <section className="news-tab news-details-section general-news-section reports-section">
                <div className="flex align-items-center justify-content-between mb-4">
                    <div className="left">
                        <h1 className='capitalize'>{report?.title}</h1>
                    </div>
                </div>
                <div className="grid" id="pdfContent">
                    <div className="col-12">
                        <div className="new-list-section">
                            <div className="list-wrapper">
                                <div className="news-list preview-report" id="report-content">
                                    <div className="relative">
                                        <PR.Image src={imageMap[report?.reportType]} alt={report?.title} className="report-img" />
                                        <div className="overlay">
                                            <h2>{report?.type}</h2>
                                        </div>
                                    </div>
                                    <div className="attachments flex align-items-center justify-content-between mt-3">
                                        <div className="flex gap-2">
                                            {report?.fileAttachment && (
                                                JSON.parse(report?.fileAttachment)?.map((file) => (
                                                    <PR.Button
                                                        key={file.key}
                                                        icon="pi pi-book"
                                                        label={file.name}
                                                        className="download-button hide-on-download"
                                                        onClick={() => fetchFileDetails(file.key, file.name)}
                                                    />
                                                ))
                                            )}
                                        </div>
                                        <div className="flex">
                                            <PR.Button
                                                icon="pi pi-download"
                                                label="Download Report"
                                                className={`${isRegulator && 'regulator'} save-button hide-on-download`}
                                                onClick={downloadReportAsPDF}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 p-0 mt-3">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: report?.description }} />
                                        <div className="attachments flex gap-3 mt-3">
                                            <span className="type-info">Report Type: {report?.reportType}</span>
                                            <span className="type-info">Sport: {report?.sportName}</span>
                                        </div>
                                        <div className="card mt-5">
                                            <ul className="list-none gap-4 flex align-items-center justify-content-between mb-0">
                                                <li><i className="pi pi-calendar"></i> <span className="author">{DateFormat(report?.createdAt)}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PR.Toast ref={toast} position="top-right" />
        </Layout>
    );
};

export default ReportDetail;
