import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import "./CreateAlert.scss";
import * as PR from "../../prime-react/index";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getLocations, getSports, getTournament, getTournamentLevels} from '../../services/api'
import { useDispatch, useSelector } from "react-redux";
import { debounce, descriptionCount, fileUploadLimit } from "../../utils/reuse";
import { RAW_FILTER_DATA } from '../../shared/common';
import moment from 'moment';


const AlertForm = ({initialData, handleBack, handleNext, editForm, backLabel, nextLabel}) => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { 
            sessionid: sessionData.sessionId,
            'Content-Type': 'multipart/form-data'
        };
    }, [sessionData.sessionId]);

    const isMember = useSelector((state) => state.auth.role) === "member";
    const alerts = RAW_FILTER_DATA.alertTypeFilter;
    const channels = RAW_FILTER_DATA.channels;
    const betTimeOptions = RAW_FILTER_DATA.timeOfBet;
    const betsStateOptions = RAW_FILTER_DATA.stateOfBetsFilter;
    const confirmOptions = RAW_FILTER_DATA.confirmOptions;
    const alertsRaised = RAW_FILTER_DATA.alertsRaised;
    const customerTypeOptions = RAW_FILTER_DATA.customerTypes;
    const gender = RAW_FILTER_DATA.gender;

    const toast = useRef(null);
    let fileUploadRef = useRef(null);  //file ref

    const [events, setEvents] = useState([]);
    const [sports, setSports] = useState([]);
    const [location, setLocation] = useState([]);
    const [tournamentLevel, setTournamentLevel] = useState([]);
    const [tournamentList, setTournamentList] = useState([]);
    const [filteredTournaments, setFilteredTournaments] = useState([]);
    const[errorMessage,setErrorMessage]= useState("")

    const [maxBets, setMaxBets] = useState('');
    const [alertTime, setAlertTime] = useState('');
    const [initialValues, setInitialValues] = useState({
        numOfEvents: '',
        events: [],
        channel: [],
        betsState: [],
        betTime: [],
        maxBets: '',
        alertTime: '',
        customerType: [],
        customers: [],
        newCustomer: { count: '', country: '' },
        description: '',
        attachments: null,
      })

      const validationSchema = () => {
        let schema = Yup.object().shape({
            numOfEvents:  Yup.number().required('Number of events required')
                        .min(1, 'Number of events must be greater than or equal to 1')
                        .max(10, 'Number of events must be less than or equal to 10'), 
            channel: Yup.array().min(1, 'At least one channel must be selected'),
            betsState: Yup.array().min(1, 'At least one state of bets must be selected'),
            betTime: Yup.array().min(1, 'At least one timing of bet must be selected'),
            customerType:  Yup.array().min(1, 'At least one customer type must be selected'),
            maxBets: Yup.string().required('Max Bets is required'),
            customers: Yup.array().of(
                Yup.object().shape({
                    count: Yup.number().min(0, 'Number of customers must be greater than or equal to 0').required('Number of customers is required'),
                    country: Yup.object().nullable().required('Country required'),
                })
            ),
            newCustomer: Yup.object().shape({
                count: Yup.number()
                    .typeError('Number of customers must be a number')
                    .required('Number of customers is required')
                    .min(0, 'Number of customers must be greater than or equal to 0'),
                country: Yup.object().nullable().required('Country required'),
            }),
            description: Yup.string().required('Description required').max(descriptionCount,  `Description should be ${descriptionCount} characters or less.`),
            attachments: Yup.array().max(3, 'You can upload a maximum of 3 files').nullable().of(Yup.mixed().nullable()
                .test('fileSize', `File size must be less than ${fileUploadLimit.label}`, (value) => {
                return !value || value.size <= fileUploadLimit.value; // 1 MB in bytes
                })
                .test('fileType', 'Only PDF, DOCX or XLSX files are allowed', (value) => {
                    if (!value) return true; // If no file is provided, return true                    
                    const acceptedTypes = ['application/zip','application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                    return acceptedTypes?.includes(value.type);
                })
            ) 
        });

        // Dynamically add validation rules for event fields
        for (let i = 0; i < events.length; i++) {
            schema = schema.shape({
                [`eventName-${i}`]: Yup.string().required(`Event name for event is required`),
                [`sport-${i}`]: Yup.object().nullable().required(`Sport for event is required`),
                [`league-${i}`]: Yup.mixed().required('League for event is required'),
                [`tournament-${i}`]: Yup.object().when([`sport-${i}`], {
                    is: (sportName) => sportName?.name.toLowerCase() === 'tennis',
                    then: () => Yup.object().required('Tournament level for event is required'),                             
                    otherwise: () => Yup.object().nullable(),
                }),
                [`location-${i}`]: Yup.object().nullable().required(`Location for event is required`),
                [`gender-${i}`]: Yup.string().required(`Gender for event is required`),
                [`dateTime-${i}`]: Yup.date().required(`Date/Time of event is required`),
                [`turnover-${i}`]: Yup.number().min(0, 'Turnover must be greater than or equal to 0').when(`isMember`, {
                    is: () =>  isMember === true ,
                    then: () => Yup.number().required('Turnover for event is required'),
                    otherwise: () =>  Yup.number().nullable(),
                }),
            });
        }
        return schema;
    };

    useEffect(() => {
        let updatedInitialValues = {};

                            //alertType
                            let alertTypeName = alerts.find(item => item.id === initialData?.alertType)?.name;
                            updatedInitialValues['alertType'] =  { name: alertTypeName, id: initialData?.alertType}
                            
                            //channel
                            updatedInitialValues['channel'] =  initialValues?.channel;
                
                            //timeOfBet
                            updatedInitialValues['betTime'] = initialValues?.betTime;
                
                            //betsState
                            updatedInitialValues['betsState'] = initialValues?.betsState;
                
                            //CustomerType
                            updatedInitialValues['customerType'] = initialValues?.customerType;
                
                            updatedInitialValues['customers'] = [];

                            let eventsData = initialData?.events;
                            let updatedEvents = [];
                           
                            updatedEvents = eventsData?.map((event, index) => {

                                const formikEventData = {
                                    [`eventName-${index}`]: event['eventName'],
                                    [`sport-${index}`]: { name: event['sportName'], id: event['sportID'] },
                                    [`league-${index}`]: { name: event['tournamentName'], id: event['tournamentID'] },
                                    [`tournament-${index}`]: { name: event['tournamentLevelName'], id: event['tournamentLevelID'] },

                                    [`eventID-${index}`]: event['eventID'],
                                    [`dateTime-${index}`]: moment.utc(event.dateTime).local().toDate(),
                                    [`location-${index}`]: { name: event['locationName'], id: event['locationID'] },
                                    [`gender-${index}`]: event['gender'],
                                };

                                const updatedEvent = {
                                    eventName: event['eventName'],
                                    sport: { name: event['sportName'], id: event['sportID'] },
                                    league: { name: event['tournamentName'], id: event['tournamentID'] },
                                    tournament: { name: event['tournamentLevelName'], id: event['tournamentLevelID'] },

                                    eventID: event['eventID'],
                                    dateTime: moment.utc(event.dateTime).local().toDate(),
                                    location: { name: event['locationName'], id: event['locationID'] },
                                    gender: event['gender'],
                                };

                                updatedInitialValues = { ...updatedInitialValues, ...formikEventData };

                                return updatedEvent;
                            });
    
                             //num of Events
                            updatedInitialValues['numOfEvents'] = updatedEvents.length;
                            updatedInitialValues['attachments'] = initialValues?.attachments;

                            setEvents(updatedEvents);
    
                           
                            setInitialValues(updatedInitialValues);
                            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);


    const handleSubmit = (formData) => {
        let data = {};
        data.channel = formData.channel;
        data.timeOfBet = formData.betTime;
        data.stateOfBet = formData.betsState;
        data.maxBets = formData.maxBets;
        data.timeOfAlert = initialData?.timeOfAlert;    //will remove this later

        let customers = formData.customers;
        customers?.push(formData.newCustomer);
        let customerDetails = formatCustomerInfo(customers);

        data.customer = { 
            customerType: formData.customerType,
            customerDetails: customerDetails
        }

        data.description = formData.description;
        data.events = formatEventData(events);
        if (!editForm) {
            data.responseType = "offered and suspicious";
        }

        const formResponse = new FormData();

        formResponse.append('data', JSON.stringify(data)); 
        if (formData.attachments && formData.attachments.length > 0) {
            formData.attachments.forEach((file, index) => {
                formResponse.append(`attachments`, file);
            });
        }
        handleNext(formResponse); 
    };
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });
    
    const prevInitialValuesRef = useRef(initialValues);
    useEffect(()=> {
        if (initialValues !== prevInitialValuesRef.current) {
            formik.setValues(initialValues)
            prevInitialValuesRef.current = initialValues;
        }
    },[initialValues, formik])

    const handleAdd = () => {
        const newCustomers = [...formik.values.customers, { count: '', country: '' }];
        formik.setFieldValue('customers', newCustomers);
    };
    const handleDelete = (index) => {
        const updatedCustomers = [...formik.values.customers];
        updatedCustomers.splice(index, 1);
        formik.setFieldValue('customers', updatedCustomers);
    };
    const formatEventData = (events) => {
        const updatedEvents = events.map(event => {
            let data = {}
            data.eventName = event.eventName;
            data.sportName = event.sport.name;
            data.sportID = event.sport.id;
            data.tournamentLevelID = event.tournament.id; 
            data.tournamentLevelName = event.tournament.name; 
            data.tournamentName = event.league.name;
            data.tournamentID = event.league.id;
            data.locationID = event.location.id;
            data.locationName = event.location.name;
            data.dateTime = moment.utc(event.dateTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
            data.gender = event.gender;
            data.turnover = parseInt(event.turnover);
            return data;
        })
        return updatedEvents;
    }
    const formatCustomerInfo = (customers) => {
        const updatedCustomers = customers.map(customer => {
            let data = {}
            data.count = customer.count;
            data.country = customer.country.name;
            return data;
        })
        return updatedCustomers;
    }


    const handleEventDetailChange = (index, value, property) => {
        const newEvents = [...events];
        newEvents[index][property] = value;
        setEvents(newEvents);
        formik.setFieldValue(`${property}-${index}`, value);
        if (property === 'sport') {
            newEvents[index].tournament = {};   // Reset tournament field
            newEvents[index].league = {};       // Reset tournament level field
            formik.setFieldValue(`tournament-${index}`, null);
            formik.setFieldValue(`league-${index}`, null);
            setTournamentLevel([]);
            setEvents(newEvents);

            getTournamenthandler(value.id);
            if(value?.name.toLowerCase() === 'tennis'){
                getTournmamentLevelsHandler(value);
            }
         }   
         if(property === 'league') {
            if(value?.name.trim() === '') {
                formik.setFieldValue(`league-${index}`, null);
            } 
         } 
    }

    const handleMultiSelect = (e, fieldName) => {
        const { value } = e.target;
        const set = new Set(formik.values[fieldName]);
        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }
        formik.setFieldValue(fieldName, Array.from(set));
    }

    const handleMaxBets = (value) => {
        setMaxBets(value);
        formik.setFieldValue('maxBets', value)
    }

    const handleAlertTime = (value) => {
        setAlertTime(value);
        formik.setFieldValue('alertTime', value)
    }

    const handleNumOfEventsChange = (e) => {
        const numOfEvents = parseInt(e.target.value);
        if (!isNaN(numOfEvents)) {
            const existingEventsCount = events.length;
            if (numOfEvents > existingEventsCount) {
                // Add new events
                const newEvents = [...events];
                for (let i = existingEventsCount; i < numOfEvents; i++) {
                    newEvents.push({ eventName: '', sport: null });
                }
                setEvents(newEvents);
            } else if (numOfEvents < existingEventsCount) {
                // Remove excess events
                const newEvents = events.slice(0, numOfEvents);
                setEvents(newEvents);
            }
            formik.setFieldValue('numOfEvents', numOfEvents);
        }
        formik.handleChange(e);
    };

    //this is for debouncing effect AutoComplete Onchange 
       const handleEventDetailChangeDebounce = debounce((index, value, property) => {
        handleEventDetailChange(index, value, property);
    }, 300);

    // Get list of all the sports
    const getSportsHandler = useCallback(() => {
        getSports(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const sports = responseData;
                const result = (sports?.length > 0) ? sports : [];
                setSports(result);
                if (result.length === 0) {
                    toast.current.show({  severity: 'warn', summary:'Warning', detail: 'No records found' });
                }
            } else {
                setSports([]);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })         
            }
        })
    }, [dispatch, headers]);

    // Get list of all the locations
    const getLocationHandler = useCallback(() => {
        getLocations(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const locations = responseData;  
                const result = (locations?.length > 0) ? locations : [];
                setLocation(result);
                if (result.length === 0) {
                    toast.current.show({  severity: 'warn', summary:'Warning', detail: 'No records found' });
                }
            } else {
                setLocation([]);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })         
            }
        })
    }, [dispatch, headers]);

      // Get list of all the tournament levels
      const getTournmamentLevelsHandler = useCallback(() => {
        getTournamentLevels(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const tournamentLevel = responseData;  
                const result = (tournamentLevel?.length > 0) ? tournamentLevel : [];
                setTournamentLevel(result);
            } else {
                setTournamentLevel([]);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })         
            }
        })
    }, [dispatch, headers]);

    //  function to filter searched results of autocomplete 
    const search = (event) => {
        const query = event.query?.toLowerCase();
        const filtered = tournamentList
            .filter(tournament => tournament.name?.toLowerCase()?.includes(query));
        setFilteredTournaments(filtered);
    }
    
    // Get all the tournaments based on SportId
    const getTournamenthandler = useCallback((sportId) => {
        const obj = {
            sportid: sportId,
        };      
        getTournament(obj,headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const tournaments = responseData;  
                setTournamentList(tournaments);
                setFilteredTournaments(tournaments);
            } else {
                setTournamentList([]);
                setFilteredTournaments([]); 
                const error = response.error;
                toast.current.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })         
            }
        })
    }, [dispatch, headers]);    

    useEffect(() => {
        getSportsHandler();
        getLocationHandler();
    },[getSportsHandler, getLocationHandler]);

    const handleDeleteFile = (index) => {
        const updatedAttachments = [...formik.values.attachments];
        updatedAttachments.splice(index, 1);
        formik.setFieldValue('attachments', updatedAttachments);
    };

    return (
        <>
            {/* Create alert form section starts here */}
            <div className="form-section">
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="form-body">
                        <div className="grid align-items-center">
                            <div className="col-12 mb-2">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="alertType">Type of alert<span className="important">*</span></label>
                                    <PR.Dropdown
                                        value={formik.values.alertType}
                                        onChange={(e) => {
                                            formik.setFieldValue('alertType', e.value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="alertType"
                                        options={alerts}
                                        optionLabel="name"
                                        placeholder="Select type of alert"
                                        className="w-full alert-drop-down"
                                        disabled={true}
                                    />
                                    {formik.errors.alertType && formik.touched.alertType && (
                                        <div className="error">{formik.errors.alertType}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column form-input gap-2">
                                    <label htmlFor="numOfEvents">Number of events<span className="important">*</span></label>
                                    <PR.InputText
                                        id="numOfEvents"
                                        type="number"
                                        placeholder="Number of Events"
                                        onChange={handleNumOfEventsChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.numOfEvents}
                                        aria-describedby="events-help"
                                        min={0}
                                        max={10}
                                        disabled={true}
                                    />
                                    {formik.errors.numOfEvents && formik.touched.numOfEvents && (
                                        <div className="error">{formik.errors.numOfEvents}</div>
                                    )}
                                </div>
                                {events.length !== 0 && <div className="events-section">
                                    {/* Event */}
                                    {events.map((event, index) => (
                                        <div className="grid align-items-baseline" key={index}>
                                            <div className="custom-col">
                                                <span className="number">{index + 1}</span>
                                            </div>
                                            <div className="col">
                                                <div className="flex flex-column form-input gap-2 mb-4">
                                                    <label htmlFor={`eventName-${index}`}>Event name</label>
                                                    <PR.InputText
                                                        type="text"
                                                        aria-describedby="eventname"
                                                        name={`eventName-${index}`}
                                                        onBlur={formik.handleBlur}
                                                        value={event.eventName}
                                                        onChange={(e) => handleEventDetailChange(index, e.target.value, 'eventName')}
                                                        disabled={true}
                                                    />
                                                    {(formik.touched[`eventName-${index}`] || formik.submitCount > 0) && formik.errors[`eventName-${index}`] && (
                                                        <div className="error">{formik.errors[`eventName-${index}`]}</div>
                                                    )}
                                                </div>
                                                <div className="flex flex-column gap-2 mb-4">
                                                    <label htmlFor={`sport-${index}`}>Select sport<span className="important">*</span></label>
                                                    <PR.Dropdown
                                                        value={event.sport}
                                                        name={`sport-${index}`}
                                                        onChange={(e) => handleEventDetailChange(index, e.value, 'sport')}
                                                        options={sports} optionLabel="name"
                                                        placeholder="Select sport"
                                                        onBlur={formik.handleBlur}
                                                        className="w-full alert-drop-down"
                                                        disabled={true}
                                                        filter
                                                    />
                                                    {(formik.touched[`sport-${index}`] || formik.submitCount > 0) && formik.errors[`sport-${index}`] && (
                                                        <div className="error">{formik.errors[`sport-${index}`]}</div>
                                                    )}
                                                </div>
                                                <div className="grid mb-4">
                                                <div className="col-6">
                                                        <div className="flex flex-column form-input gap-2">
                                                            <label htmlFor={`league-${index}`}>League/ Tournament<span className="important">*</span></label>
                                                               <PR.AutoComplete 
                                                                name={`league-${index}`}
                                                                onBlur={formik.handleBlur}
                                                                dropdownAutoFocus={true}
                                                                value={event.league ? event.league?.name : ''}
                                                                onChange={(e) => {
                                                                    if (typeof e.value === 'string') {
                                                                        handleEventDetailChangeDebounce(index, { name: e.value }, 'league'); // value is a string
                                                                    } else if (e.value) {
                                                                        handleEventDetailChangeDebounce(index, e.value, 'league'); //value is an object
                                                                    }
                                                                }}
                                                                aria-describedby="league"
                                                                suggestions={filteredTournaments} 
                                                                completeMethod={search} 
                                                                itemTemplate={(tournament) => tournament.name}
                                                                className="form-autocomplete"
                                                                // disabled={!event.sport}
                                                                disabled={true}
                                                                            />
                                                            {(formik.touched[`league-${index}`] || formik.submitCount > 0) && formik.errors[`league-${index}`] && (
                                                                <div className="error">{formik.errors[`league-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                        <label htmlFor={`tournament-${index}`}>Tournament level {(event.sport?.name.toLowerCase() === 'tennis') && <span className="important">*</span>}</label>
                                                        <PR.Dropdown
                                                                value={event.tournament}
                                                                name={`tournament-${index}`}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'tournament')}
                                                                options={tournamentLevel}
                                                                optionLabel="name"
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Select tournament level"
                                                                className="w-full alert-drop-down"
                                                                // disabled={tournamentLevel.length <= 0}
                                                                disabled={true}
                                                            />
                                                            {(formik.touched[`tournament-${index}`] || formik.submitCount > 0) && formik.errors[`tournament-${index}`] && (
                                                                <div className="error">{formik.errors[`tournament-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-4">
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="firstname">Date/ Time of event<span className="important">*</span></label>
                                                            <PR.Calendar
                                                                name={`dateTime-${index}`}
                                                                placeholder="Select date"
                                                                value={event.dateTime}
                                                                onFocus={() => formik.setFieldTouched(`dateTime-${index}`)}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'dateTime')}
                                                                showTime hourFormat="12"
                                                                showIcon iconPos="right"
                                                                className="date-calendar"
                                                                disabled={true}
                                                                maxDate={new Date()}
                                                            />
                                                            {(formik.touched[`dateTime-${index}`] || formik.submitCount > 0) && formik.errors[`dateTime-${index}`] && (
                                                                <div className="error">{formik.errors[`dateTime-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="location">Location of event<span className="important">*</span></label>
                                                            <PR.Dropdown
                                                                value={event.location}
                                                                name={`location-${index}`}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'location')}
                                                                options={location} optionLabel="name"
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Select event location"
                                                                className="w-full alert-drop-down"
                                                                disabled={true}
                                                                filter
                                                            />
                                                            {(formik.touched[`location-${index}`] || formik.submitCount > 0) && formik.errors[`location-${index}`] && (
                                                                <div className="error">{formik.errors[`location-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-4">
                                                    <div className="col-12">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="firstname">Gender<span className="important">*</span></label>
                                                            <div className="card flex mt-1 gap-3">
                                                            {gender.map((option) => (    
                                                                <div className="flex flex-wrap gap-2" key={option.id}>
                                                                <div className="flex align-items-center">
                                                                    <PR.RadioButton
                                                                        inputId={`gender${index}`}
                                                                        name="gender"
                                                                        value={option.id}
                                                                        onChange={(e) => handleEventDetailChange(index, e.value, 'gender')}
                                                                        checked={event.gender === option.id}
                                                                        disabled={true}
                                                                    />
                                                                    <label htmlFor={`gender${index}`} className="ml-2">{option.name}</label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            </div>
                                                            {(formik.touched[`gender-${index}`] || formik.submitCount > 0) && formik.errors[`gender-${index}`] && (
                                                                <div className="error">{formik.errors[`gender-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-0">
                                                    <div className="col-12">
                                                        <div className="flex flex-column form-input gap-2">
                                                            <label htmlFor="turnover">How much did the suspicious turnover account for as a proportion of the event as a whole, if available?</label>
                                                            <PR.InputText
                                                                name={`turnover-${index}`}
                                                                value={event.turnover || ''}
                                                                onChange={(e) => handleEventDetailChange(index, e.target.value, 'turnover')}
                                                                type="text"
                                                                aria-describedby="turnover"
                                                            />
                                                            {(formik.touched[`turnover-${index}`] || formik.submitCount > 0) && formik.errors[`turnover-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`turnover-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4 mb-4" />
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Channel<span className="important">*</span></label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                    {channels.map((channel) => (
                                        <div className="flex align-items-center" key={channel.id}>
                                            <PR.Checkbox
                                                value={channel.id}
                                                onChange={(e) => handleMultiSelect(e, 'channel')}
                                                checked={formik.values.channel?.includes(channel.id)}></PR.Checkbox>
                                            <label htmlFor={channel.id} className="ml-2 font-medium">{channel.name}</label>
                                        </div>
                                    ))}
                                    </div>
                                    {formik.errors.channel && (formik.touched.channel || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.channel}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Time of bet<span className="important">*</span></label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                    {betTimeOptions.map((option) => (
                                        <div className="flex align-items-center" key={option.id}>
                                            <PR.Checkbox
                                                value={option.id}
                                                onChange={(e) => handleMultiSelect(e, 'betTime')}
                                                checked={formik.values.betTime?.includes(option.id)}></PR.Checkbox>
                                            <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                        </div>
                                    ))}
                                    </div>
                                    {formik.errors.betTime && (formik.touched.betTime || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.betTime}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">State of the bets<span className="important">*</span></label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                        {betsStateOptions.map((option) => (
                                            <div className="flex align-items-center" key={option.id}>
                                                <PR.Checkbox
                                                    value={option.id}
                                                    onChange={(e) => handleMultiSelect(e, 'betsState')}
                                                    checked={formik.values.betsState?.includes(option.id)}></PR.Checkbox>
                                                <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                                </div>
                                        ))}
                                    </div>
                                    {formik.errors.betsState && (formik.touched.betsState || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.betsState}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Max bets<span className="important">*</span></label>
                                    <div className="card flex mt-1 gap-3">
                                    {confirmOptions.map((option) => (    
                                        <div className="flex flex-wrap gap-2" key={option.id}>
                                            <div className="flex align-items-center">
                                                <PR.RadioButton
                                                    inputId="bet-yes"
                                                    name="bets"
                                                    value={option.id}
                                                    onChange={(e) => handleMaxBets(e.value)}
                                                    checked={maxBets === option.id}
                                                />
                                                <label htmlFor="bet-yes" className="ml-2 font-medium">{option.name}</label>
                                                </div>
                                            </div>
                                    ))}
                                    </div>
                                    {formik.errors.maxBets && (formik.touched.maxBets || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.maxBets}</div>
                                    )}
                                </div>
                            </div>
                            {editForm && (
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">When is the alert being raised?</label>
                                    <div className="card flex mt-1 gap-3">
                                    {alertsRaised.map((option) => (      
                                        <div className="flex flex-wrap gap-2" key={option.id}>
                                            <div className="flex align-items-center">
                                            <PR.RadioButton
                                                inputId="pre-event"
                                                name="raised"
                                                value={option.id}
                                                onChange={(e) => handleAlertTime(e.value)}
                                                checked={alertTime === option.id}
                                            />
                                            <label htmlFor="pre-event" className="ml-2 font-medium">{option.name}</label>
                                        </div>
                                    </div>
                                    ))}                                        
                                </div>
                            </div>
                            </div>
                            )}
                            <div className="col-12">
                                <p className="customer_info">CUSTOMER INFO</p>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Type of customers<span className="important">*</span></label>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                    {customerTypeOptions.map((option) => (
                                                        <div className="flex align-items-center" key={option.id}>
                                                            <PR.Checkbox
                                                                value={option.id}
                                                                onChange={(e) => handleMultiSelect(e, 'customerType')}
                                                                checked={formik.values.customerType?.includes(option.id)}></PR.Checkbox>
                                                            <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                                        </div>
                                    ))}
                                    </div>
                                    {formik.errors.customerType && (formik.touched.customerType || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.customerType}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="firstname">Number of customers involved<span className="important">*</span></label>
                                    {/* Add More Section */}
                                    {formik.values.customers?.map((customer, index) => {
                                        return (
                                            <div className="grid" key={index}>
                                                <div className="col-4 form-input">
                                                    <PR.InputText
                                                        name={`customers[${index}].count`}
                                                        type="number"
                                                        value={formik.values.customers[index].count}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="No. of customers"
                                                        aria-describedby="events-help"
                                                        min={0}
                                                    />
                                                    {formik.touched.customers && formik.touched.customers[index] && formik.errors.customers && formik.errors.customers[index] && formik.errors.customers[index].count && (
                                                        <div className="error mt-1">{formik.errors.customers[index].count}</div>
                                                    )}
                                                </div>
                                                <div className="col-5">
                                                    <PR.Dropdown
                                                        name={`customers[${index}].country`}
                                                        value={formik.values.customers[index].country}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        filter
                                                        options={location} optionLabel="name"
                                                        placeholder="Select country"
                                                        className="w-full alert-drop-down"
                                                    />
                                                    {formik.touched.customers && formik.touched.customers[index] && formik.errors.customers && formik.errors.customers[index] && formik.errors.customers[index].country && (
                                                        <div className="error mt-1">{formik.errors.customers[index].country}</div>
                                                    )}
                                                </div>
                                                <div className="col-3 form-input">
                                                    <PR.Button
                                                        label="Remove"
                                                        type="button"
                                                        icon="pi pi-minus"
                                                        className="addmore-button"
                                                        onClick={() => handleDelete(index)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="grid">
                                        <div className="col-4 form-input">
                                            <PR.InputText
                                                type="number"
                                                name="newCustomer.count"
                                                value={formik.values.newCustomer?.count || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="No. of customers"
                                                aria-describedby="events-help"
                                                min={0}
                                            />
                                            {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                <div className="error mt-1">{formik.errors.newCustomer.count}</div>
                                            )}
                                        </div>
                                        <div className="col-5">
                                            <PR.Dropdown
                                                name="newCustomer.country"
                                                value={formik.values.newCustomer?.country || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                filter
                                                options={location} optionLabel="name"
                                                placeholder="Select country"
                                                className="w-full alert-drop-down"
                                            />
                                            {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                <div className="error mt-1">{formik.errors.newCustomer.country}</div>
                                            )}
                                        </div>
                                        <div className="col-3 form-input">
                                            <PR.Button label="Add more" type="button" icon="pi pi-plus" className="addmore-button" onClick={handleAdd} />
                                        </div>
                                    </div>
                                    {/* Add More Section */}
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="description">Description of suspicious event<span className="important">*</span></label>
                                    <div className="card flex justify-content-center">
                                        <PR.InputTextarea
                                            name="description"
                                            value={formik.values.description}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (newValue.length > descriptionCount) {
                                                    formik.setFieldValue('description', newValue.substring(0, descriptionCount));
                                                    setErrorMessage( `Message should be ${descriptionCount} characters or less.`)
                                                } else {
                                                    formik.setFieldValue('description', newValue);
                                                }
                                            }}
                                            onPaste={(e) => {
                                                const pastedData = e.clipboardData.getData('text');
                                                const newDescription = formik.values.description + pastedData;
                                                if (newDescription.length > descriptionCount) {
                                                    formik.setFieldValue('description', newDescription.substring(0, descriptionCount));
                                                    setErrorMessage( `Message should be ${descriptionCount} characters or less.`)
                                                } else {
                                                    formik.setFieldValue('description', newDescription);
                                                }
                                                e.preventDefault();
                                            }}
                                            onBlur={formik.handleBlur}
                                            rows={8} cols={30}
                                            className="description-textarea"
                                        />
                                    </div>
                                    <span style={{ textAlign: "left" }}>
                                                {errorMessage && <div className="error-message">{errorMessage}</div>}
                                    </span>
                                    {formik.errors.description && (formik.touched.description || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.description}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-7">
                                <div className="flex flex-column gap-2">
                                <label htmlFor="firstname">Attach file</label>
                                    <div className="card file-upload flex align-items-center">
                                        <PR.FileUpload
                                            auto
                                            multiple
                                            mode="advanced"
                                            name="attachments"
                                            accept=".pdf,.docx,.xlsx"
                                            chooseLabel="Choose File"
                                            uploadLabel="Upload"
                                            emptyTemplate={null}
                                            itemTemplate={null}
                                            ref={fileUploadRef}
                                            onSelect={(event) => {
                                                if (fileUploadRef.current) {
                                                    fileUploadRef.current.clear();  
                                                }
                                                if(formik.values?.attachments) {
                                                    formik.setFieldValue('attachments', [...formik.values?.attachments, ...event.files]);
                                                } else {
                                                    formik.setFieldValue("attachments", event.files);
                                                }
                                               
                                            }}

                                            customUpload
                                        />
                                        <span className="ml-2"> <span className="block">(PDF, DOCX, XLSX)</span>File should be less than {fileUploadLimit.label}.</span>
                                    </div>

                                    {formik.values.attachments && (
                                                    <div className="uploaded-file-container flex flex-column gap-2">
                                                    {formik.values.attachments?.map((attachment, index) => (
                                                        <div className="uploaded-files" key={index}>
                                                            {attachment.name}
                                                            <i className="pi pi-trash" onClick={() => handleDeleteFile(index)}/>
                                                        </div>
                                                        )
                                                        )}
                                                    </div>
                                                )}
                                                {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                                                  <div className="error-message">
                                                     {Array.isArray(formik.errors.attachments) ? (
                                                        Array.from(new Set(formik.errors?.attachments)).map((error, index) => (
                                                            <div key={index}>{error}</div>
                                                        ))
                                                    ) : (
                                                        <div>{formik.errors.attachments}</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                        </div>
                        <div className="form-footer flex align-items-center justify-content-end gap-3 mt-2">
                            <PR.Button
                                label={backLabel}
                                type="button"
                                onClick={handleBack}
                                className="cancel-button"
                            />
                            <PR.Button
                                label={nextLabel}
                                className="create-alert-button"
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
            {/* section ends here */}
        </>
    );
};

export default AlertForm;