import React, { useRef, useState } from "react";
import * as PR from "../prime-react/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { logo, logoDark } from "../assets/images/index";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { logout } from "../services/api";
import ProfileImageDisplay from "../shared/ProfileImageDisplay";
import { newsActions } from "../store/news";

const Header = ({backBtn, backBtnLabel}) => {
  const menu = useRef(null);
  const navigate = useNavigate();
  const userMenu = useRef(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch()
  const sessionData = useSelector(state => state?.auth?.sessionData);
  const profileData = useSelector(state => state?.auth?.profileData);
  const role = useSelector(state => state.auth.role);

  const toast = useRef(null);

  const handleLogout = () => {
    const headers = { sessionid: sessionData.sessionId };
    logout(headers, dispatch, (response) => {
      if (response.result === 'SUCCESS') {
        navigate('/login');
      } else {
        const error = response.error;
        toast?.current?.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })         

      }
    });
  };

  const allMenuItems = [
    {
      label: "Profile details",
      icon: "pi pi-user",
      url: '/profile-details',
      isVisible: true
    },
    // {
    //   label: "Leagues",
    //   icon: <PR.Image src={leagueIcon} loading="lazy" alt="league" className="menu-svg-icon" />,
    //   url: '/leagues',
    //   isVisible: role === 'member' ? true: false
    // },
    {
      label: "Logout",
      icon: "pi pi-power-off",
      isVisible: true,
      command: () => {
        setVisible(true);
      }
    }
  ];

  const menuItems = allMenuItems.filter(item => item.isVisible);


  const userMenuItems = [
    {
      label: "My alerts",
      icon: "pi pi-list",
      url: '/my-alerts'
    },
    {
      label: "Alert tags",
      icon: "pi pi-th-large",
      url: '/tags'
    },
    {
      label: "Case updates",
      icon: "pi pi-share-alt",
      url: '/case-updates'
    },
    {
      label: "User management",
      icon: "pi pi-users",
      url: '/user-management'
    },
    {
      label: "Member analytics",
      icon: "pi pi-chart-bar",
      url: '/my-analytics'
    },
    {
      label: "News & updates",
      icon: "pi pi-book",
      url: '/news',
      command: () => dispatch(newsActions.updateTab({activeIndex:0}))
    },
    {
      label: "Reports",
      icon: "pi pi-file",
      url: '/reports'
    }
  ];
  
  const footerContent = (
      <div>
          <PR.Button label="Cancel" onClick={() => setVisible(false)} outlined className="logout-btns" />
          <PR.Button label="Logout" onClick={() => handleLogout()} outlined autoFocus className={` ${role === 'regulator' && 'regulator'} logout-btns click-btn`} />
      </div>
  );

  const gotoCreateAlert=()=>{
    navigate("/create-alert");
}

  const start = <Link to="/home"><PR.Image src={role === 'analyst' ? logoDark : logo} alt="IBIA Logo" className="logo-img" /></Link>;
  const startBack = <Link to={`/${backBtn}`} className="flex align-items-center back-btn"> <i className="pi pi-arrow-left" />Back to {backBtnLabel ||backBtn}</Link>

  const end = <div className="flex align-items-center gap-4">
                <Link to="/help" className="right-icons"><i className="pi pi-question-circle"></i> {role !== 'regulator' && 'Help guide'}</Link>
                {  role === 'analyst' && (
                  <>
                  <PR.Button className="create-button" severity="primary" label="Create alert" onClick={() => gotoCreateAlert()}/>
                  <PR.Button
                      icon="pi pi-bars"
                      className="menu-bars-button"
                      onClick={(e) => userMenu.current.toggle(e)}
                  />
                </>
              )}
                <PR.Menu model={userMenuItems} popup popupAlignment="right" ref={userMenu} />
                <span className="line"></span>
                <span className="flex align-items-center" onClick={(e) => menu.current.toggle(e)}>
                  <PR.Button icon={<ProfileImageDisplay profileImage={profileData?.profilePic} />} className="user-button" rounded outlined severity="secondary" aria-label="User" />
                  <PR.Button
                      icon="pi pi-chevron-down"
                      iconPos="right"
                      className="drop-down-button"
                  />
                 </span>
                <PR.Menu model={menuItems} popup ref={menu} />
              </div>;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>IBIA | Home</title>
        </Helmet>
      </HelmetProvider>
      <section className="header-section">
        <div className="w-12">
          <header className="grid grid-nogutter">
              <div className="w-12 flex align-items-center">
                <PR.Menubar start={backBtn ? startBack : start} end={end} className="w-12 top-header" />
              </div>
          </header>
        </div>
      </section>
      <PR.Dialog visible={visible} footer={footerContent} className="logout-dialog">
          <div className="logout-section">
              <h2>Logout</h2>
              <p>Are you sure you want to logout?</p>
          </div>
      </PR.Dialog>
    </>
  )
}

export default Header