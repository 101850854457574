import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as PR from "../../prime-react/index";

const DownloadPDF = ({ contentId, fileName, buttonLabel, buttonClassName, headerText }) => {
  const handleDownloadPDF = async () => {
    const buttons = document.querySelectorAll(".hide-on-download");
    buttons.forEach(button => (button.style.display = "none"));
    
    const content = document.getElementById(contentId);
    const canvas = await html2canvas(content, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    const margin = 5
    pdf.setFontSize(10);
    pdf.text(headerText, margin, 20);
    pdf.addImage(imgData, "PNG", margin, 30, pdfWidth - 2 * margin, pdfHeight - 20);

    pdf.save(fileName);

    buttons.forEach(button => (button.style.display = ""));
  };

  return (
    <PR.Button
      icon="pi pi-download"
      label={buttonLabel}
      className={buttonClassName}
      onClick={handleDownloadPDF}
      
    />
  );
};

export default DownloadPDF;
