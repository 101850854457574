import React, { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import "../CreateReport/CreateReport.scss";
import { createReport, editReport } from "../../../../services/api";
import { DateFormat } from "../../../../utils/reuse";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { news1, news2, news3 } from "../../../../assets/images/index";
import DownloadPDF from "../../../../shared/downloadpdf/DownloadPdf";
const PreviewReport = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
      "Content-Type": "multipart/form-data",
    };
  }, [sessionData.sessionId]);

  const reportData = JSON.parse(secureLocalStorage.getItem("reportData"));
  const reportFiles = secureLocalStorage.getItem("attachments");
  const sport = secureLocalStorage.getItem("sportName");

  const handlePublish = () => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(reportData));
    reportFiles.forEach((file) => {
      formData.append("attachments", file);
    });

    if (reportData?.reportID) {
      editReport(formData, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Report Updated Successfullly",
          });
          setTimeout(() => {
            navigate("/reports");
          }, 3000);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: response.error?.errorMsg,
          });
        }
      });
    } else {
      createReport(formData, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Report Created Successfullly",
          });
          setTimeout(() => {
            navigate("/reports");
          }, 3000);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: response.error?.errorMsg,
          });
        }
      });
    }
  };

  const imageMap = {
    "suspicious betting": news1,
    summary: news2,
    others: news3,
  };

  const downloadAttachment = (fileData, fileName) => {
    const link = document.createElement("a");
    link.href = fileData;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Layout backBtn={"reports"}>
        <section className="news-tab news-details-section general-news-section">
          <div className="flex align-items-center justify-content-between mb-4">
            <div className="left">
              <h1>{reportData?.title}</h1>
            </div>
            <div className="right hide-on-download">
              <PR.Button
                label="Publish"
                className="action-buttons save-button"
                onClick={handlePublish}
              />
            </div>
          </div>
          <div className="grid" id="pdfContent">
            <div className="col-12">
              <div className="new-list-section">
                <div className="list-wrapper">
                  <div className="news-list preview-report">
                    <div className="relative">
                      <PR.Image
                        src={imageMap[reportData?.reportType]}
                        alt="Weekly Integrity Preview"
                        className="report-img"
                      />
                      <div className="overlay">
                        <h2>{reportData?.title}</h2>
                      </div>
                    </div>

                    <div className="attachments flex align-items-center justify-content-between mt-3">
                      <div className="flex align-items-center">
                        {reportFiles?.map((file, index) => (
                          <PR.Button
                            key={index}
                            icon="pi pi-book"
                            label={file?.name}
                            className="download-button hide-on-download"
                            onClick={() =>
                              downloadAttachment(file.data, file.name)
                            }
                          />
                        ))}
                      </div>
                      <DownloadPDF
                        contentId="pdfContent"
                        fileName="report.pdf"
                        buttonLabel="Download PDF"
                        buttonClassName="save-button hide-on-download"
                        headerText="Report"
                      />
                    </div>

                    <div className="col-12 p-0 mt-3">
                      <div className="content">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: reportData?.description,
                          }}
                        />
                      </div>
                      <div className="attachments flex gap-3 mt-3">
                        {reportData?.reportType && (
                          <span className="type-info">
                            Report Type: {reportData?.reportType}
                          </span>
                        )}
                        {sport && (
                          <span className="type-info">Sport: {sport}</span>
                        )}
                      </div>
                      <div className="card mt-5">
                        <ul className="list-none gap-4 flex align-items-center justify-content-between mb-0">
                          <li>
                            <i className="pi pi-user"></i>{" "}
                            <span className="author">
                              {sessionData?.name}
                            </span>
                          </li>
                          <li>
                            <i className="pi pi-calendar"></i>{" "}
                            <span>{DateFormat(new Date())}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right mt-4 hide-on-download">
                  <PR.Button
                    label="Publish"
                    className="action-buttons save-button"
                    onClick={handlePublish}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default PreviewReport;
