/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as PR from "../../../prime-react/index";
import "./MyAnalytics.scss";
import Layout from '../../../layouts/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAlertsCount, getLocations, getSports } from '../../../services/api';
import moment from 'moment';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { jsPDF } from "jspdf";
import { optionsforBarGraphs, optionsforBarGraphsWithPercentage, optionsforResponseType } from '../../../utils/reuse';

const MyAnalyticsDetails = () => {

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const type = queryParams.get("type");
    const dispatch = useDispatch();
    const isExport = useSelector(state => state.auth?.exportPermission);

    let title, description, downloadedFilename;
    const chartRef = useRef(null);
    const [dataForExport, setDataForExport] = useState(null);

     // Select stored data based on type
     const storedAnalyticsData = useSelector(state => {
        switch (type) {
            case 'arps':
                return state.auth?.alertRaisedAnalytics;
            case 'rrps':
                return state.auth?.responseRateAnalytics;
            case 'taps':
                return state.auth?.totalAlertsAnalytics;
            case 'tapl':
                return state.auth?.alertByLocationAnalytics;
            case 'tpc':
                return state.auth?.tagsCountAnalytics;
            case 'rtps':
                return state.auth?.responseTypeAnalytics;
            default:
                return [];
        }
    });

    const [sports, setSports] = useState([]);
    const [location, setLocation] = useState([]);
    const [dates, setDates] = useState(storedAnalyticsData?.selectedDate);

    const [chartOptions, setChartOptions] = useState(optionsforBarGraphs);
    const [chartOptionsWithPerc, setChartOptionsWithPerc] = useState(optionsforBarGraphsWithPercentage);
    const [chartOptionsResponseType, setChartOptionsResponseType ] = useState(optionsforResponseType);


    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);


    const defaultDataForResponseType = [
        { label: 'suspicious', color: '#B9BC19', value: 0 },
        { label: 'notsuspicious', color: '#FC702E', value: 0 },
        { label: 'notoffered', color: '#94C11F', value: 0 }
    ];
    const [responseType, setResponseType] = useState(null);

    const [visible, setVisible] = useState(false);
    const [exportType, setExportType] = useState(null);

    
    const [selectedDataValue, setSelectedDataValue] = useState(storedAnalyticsData?.selectedData);


    // Get list of all the sports
    const getSportsHandler = useCallback(() => {
        getSports(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const sports = responseData;
                const result = (sports?.length > 0) ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setSports(result);
            } else {
                setSports([]);
            }
        })
    }, [dispatch, headers]);

    // Get list of all the locations
    const getLocationHandler = useCallback(() => {
        getLocations(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                    const locations = responseData;  
                    const result = (locations?.length > 0) ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                    setLocation(result);
            } else {
                    setLocation([]);
                }
        })
    }, [dispatch, headers]);    
   

    const emptyData = {
        labels: null,
        datasets: [
            {
                label: '',
                data: null,
                backgroundColor: '#3067C2',
                borderRadius: 5,
                barThickness: 25,
            },
        ]
    };

    const processData = (data, setDataFunction, isTags, isSecBar) => {
        if (data) {
            const filteredData = data?.filter(item => {
                const value = isTags ? item?.tagCount : item?.alertCount || item?.responseRate || 0;
                return value > 0;
            });

            const labels = filteredData?.map(item => 
                isTags ? `${item?.tagName}` : `${item?.sportName || item?.locationName}`
            );
            const dataValues = filteredData?.map(item => 
                isTags ? `${item?.tagCount}` : `${item?.alertCount || item?.responseRate || 0}`
            );

            const chartData = {
                labels: labels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: isSecBar ? '#FC702E' : '#B9BC19' ,
                        borderRadius: 5,
                        barThickness: 25,
                    },
                ]
            };
            setDataFunction(chartData);
            setChartOptions(optionsforBarGraphs);
            setChartOptionsWithPerc(optionsforBarGraphsWithPercentage)
        } else {
            setDataFunction(emptyData);
        }
    };
    
    const [chartDataNew, setChartDataNew] = useState({});

        // Api to fetch analytics data Of sports
        const fetchAnalyticDataBySport = useCallback((selectedDataValue) => {
            const obj = {
                type: type,
            }; 
            if(selectedDataValue?.length > 0)
            {
                type === "tapl" 
                ? (obj.locations  = selectedDataValue?.map(location  => location.id).join(','))
                : obj.sport = selectedDataValue?.map(sport => sport.id).join(',');
            }
            if (dates && dates?.length > 0) {
                const startDate= moment(dates[0]).format('YYYY-MM-DD');
                const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
                obj.startDate = startDate;
                obj.endDate = endDate;
            }
            getAlertsCount(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {   
                    const responseData = response.data;
                    if( type === 'arps') {
                        setDataForExport(responseData?.alertsRaisedPerSport);
                        processData(responseData?.alertsRaisedPerSport, setChartDataNew);
                    } else if( type ==='rrps'){
                        setDataForExport(responseData?.responseRatePerSport);
                        processData(responseData?.responseRatePerSport, setChartDataNew);
                    } else if( type ==='taps'){
                        setDataForExport(responseData?.totalAlertsPerSport);
                        processData(responseData?.totalAlertsPerSport, setChartDataNew, false, true);
                    } else if( type ==='tapl'){
                        setDataForExport(responseData?.totalAlertsPerLocation);
                        processData(responseData?.totalAlertsPerLocation, setChartDataNew, false, true);
                    } else if( type ==='tpc'){
                        setDataForExport(responseData?.tagsCount);
                        processData(responseData?.tagsCount, setChartDataNew, true, true);
                    } 
                }
            })
        // eslint-disable-next-line
        }, [dispatch, headers, dates]);

        // Api to fetch analytics response type in bar graph 
        const fetchAnalyticResTypeBySport = useCallback((selectedDataValue) => {        
            const obj = {
                type: "rtps",
            }; 
            if(selectedDataValue) {
                obj.sport = selectedDataValue?.id
            }
            if (dates && dates?.length > 0) {
                const startDate= moment(dates[0]).format('YYYY-MM-DD');
                const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
                obj.startDate = startDate;
                obj.endDate = endDate;
            }
            getAlertsCount(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {   
                    const responseData = response.data;
                    setResponseType(responseData);
                    setDataForExport(responseData?.responseTypePerSport);

                    const updatedData = response?.data?.responseTypePerSport?.map((item, index) => ({
                        label: item?.responseType || ``,
                        color: defaultDataForResponseType[index]?.color || '#000000',
                        value: item?.responseRate || 0
                    })) || defaultDataForResponseType;
        
                    const data = {
                        labels: updatedData.map(item => 'Response Type'),
                        datasets: updatedData.map(item => ({
                            label: item.label,
                            data: [item.value],
                            backgroundColor: item.color,
                            borderRadius: 5,
                            barThickness: 30,
                            borderWidth: 3,
                            borderColor: "#fff"
                        }))
                    };
                    setChartDataNew(data);
                    setChartOptionsResponseType(optionsforResponseType);
                }
            })
            // eslint-disable-next-line
        }, [dispatch, headers, dates]);

        
        useEffect(()=>{
            if(type ==='rtps') {
                fetchAnalyticResTypeBySport(selectedDataValue)
            }
            else {
                fetchAnalyticDataBySport(selectedDataValue);
            }
        },[selectedDataValue, dates])

    useEffect(() => {
        getSportsHandler();
        getLocationHandler();
    },[ getSportsHandler, getLocationHandler])




    //  title and description based on type
    switch (type) {
        case 'arps':
          title = 'Alerts Raised by Sport';
          description = 'The number of alerts raised by your organisation by sport in a given date range.';
          downloadedFilename = "alert-raised";
          break;
        case 'rrps':
          title = 'Response Rate by Sport';
          description = 'The % of alerts responded to by your organisation by sport in a given date range.';
          downloadedFilename = "response-rates";
          break;
        case 'rtps':
          title = 'Response Type';
          description = 'A breakdown (in %) of the way in which your organisation has responded to alerts in a given date range.';
          downloadedFilename ="response-type";
          break;
        case 'taps':
            title = 'Total Alerts by Sport';
            description = 'The number of alerts deemed suspicious by sport in a given date range.';
            downloadedFilename = "total-alerts";
            break;
        case 'tapl':
          title = 'Total Alerts by Location';
          description = 'The number of alerts deemed suspicious by event location in a given date range.';
          downloadedFilename = "alerts-by-location";
          break;
        case 'tpc':
          title = 'Tennis Players of Concern';
          description = 'Tennis players that have featured in multiple IBIA alerts deemed suspicious in a given date range.';
          downloadedFilename = "tennis-players"
          break;
        default:
          title = 'Analytics Data';
          description = 'General analytics overview.';
          downloadedFilename = "analytics-data"
          break;
      }


      const handleDownloadFile = (exportType) => {
        const currDate = `_${moment(dates[0]).format('DD_MMM_YYYY')}${moment(dates[1]).isValid() ? `_to_${moment(dates[1]).format('DD_MMM_YYYY')}` : ''}`;
        downloadedFilename =  downloadedFilename + currDate;
        
        const csvConfig = mkConfig({ useKeysAsHeaders: true , filename: downloadedFilename});

        if(exportType === 'png'){
            if (chartRef.current) {
                const base64Image = chartRef.current.getBase64Image();
                const link = document.createElement('a');
                link.href = base64Image;
                link.download = `${downloadedFilename}`;
                link.click();
            }
        }
        else if(exportType === 'csv'){
            const csv = generateCsv(csvConfig)(dataForExport);
            download(csvConfig)(csv)
        }

        else if (exportType === 'pdf') {
            const base64Image = chartRef.current.getBase64Image();
            const doc = new jsPDF();
            doc.addImage(base64Image, 'PNG', 10, 10);
            doc.save(`${downloadedFilename}.pdf`);
        }
        setExportType(null);
        setVisible(false);
    }

    
    const footerContent = (
        <div>
            <PR.Button label="Cancel" onClick={() => setVisible(false)} outlined className="logout-btns" />
            <PR.Button label="Export" onClick={() => handleDownloadFile(exportType)} disabled={!exportType} outlined autoFocus className={`logout-btns click-btn`} />
        </div>
    );

    return (
        <>
            <Layout>
                <div className='menu-section'>
                    <Link to="/my-analytics" className='back-link'><i className="pi pi-arrow-left"></i> Back to analytics</Link>
                </div>
                <section className="my-analytics-tab">
                    <div className="m-0 flex align-items-center justify-content-between">
                        <div className='heading-card'>
                            <h1>{title}</h1>
                            <p>{description}</p>
                        </div>
                        <div className="header-right flex gap-2">
                            <div className='date'>
                            <PR.Calendar 
                                value={dates} 
                                onChange={(e) => setDates(e.value)} 
                                selectionMode="range" 
                                iconPos="left" 
                                readOnlyInput 
                                showIcon 
                                className='date-calendar' 
                                placeholder='Date'
                                dateFormat={"dd M yy"}
                                maxDate={new Date()}
                            />
                            </div>
                           
                            {type === 'tapl' ? (
                            <div className="location">
                             <PR.MultiSelect 
                                filter
                                value={selectedDataValue}
                                onChange={(e) => setSelectedDataValue(e.value)} 
                                options={location} 
                                optionLabel="name" 
                                placeholder="Select location"
                                maxSelectedLabels={0}
                                showSelectAll={false}
                                className="multi-select-dropdown"
                                selectionLimit={10} 
                            />
                            </div>
                            ): ( (type === 'tpc'|| type === 'rtps') ? null :
                            <div className="sports">
                                <PR.MultiSelect 
                                   filter
                                   value={selectedDataValue}
                                   onChange={(e) => setSelectedDataValue(e.value)} 
                                   options={sports} 
                                   optionLabel="name" 
                                   placeholder="Select sports"
                                   maxSelectedLabels={0}
                                   showSelectAll={false}
                                   className="multi-select-dropdown"
                                   selectionLimit={10} 
                               />
                            </div>
                        )}
                        {type ==='rtps' && (
                            <div className="card">
                            <PR.Dropdown
                                value={selectedDataValue}
                                onChange={(e) => setSelectedDataValue(e.value)}
                                options={sports}
                                optionLabel="name"
                                placeholder="Select sports"
                                className="single-select-dropdown bg-white"
                                showClear
                                filter
                                />
                        </div>
                        )}
                            <div className="export-block">
                                <PR.Button label="Export" icon="pi pi-upload" className="export-button" onClick={() => setVisible(true)} disabled={!isExport || !dataForExport} />
                            </div>
                        </div>
                    </div>
                    <div className="analytics-wrapper">
                        <div className="grid align-items-start mt-3">
                            <div className="col-12">
                                <div className={`info-box ${type === 'rtps' ? 'xmax-chart' : 'max-chart'}`}>
                                    { type === 'rtps' ? (
                                         <div className='flex flex-column'>
                                         <div className="card mt-2">
                                            <PR.Chart type="bar" ref={chartRef} data={chartDataNew} options={chartOptionsResponseType} className='horizontal-bar-chart' />
                                        </div>
                                        <div>
                                                {responseType?.responseTypePerSport ? (
                                                <div className='chart-info'>
                                                {responseType?.responseTypePerSport && responseType?.responseTypePerSport[0] && (
                                                    <ul className='flex align-items-center justify-content-between list-none'>
                                                        <li><span className='indicator'></span>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[0]?.responseType : ''}</li>
                                                        <li><b>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[0]?.responseRate.toFixed(2) : 0}%</b></li>
                                                    </ul>
                                                     )}
                                                    {responseType?.responseTypePerSport && responseType?.responseTypePerSport[1] && (
                                                    <ul className='flex align-items-center justify-content-between list-none'>
                                                        <li><span className='indicator not-suspicious'></span>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[1]?.responseType : ''}</li>
                                                        <li><b>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[1]?.responseRate.toFixed(2) : 0}%</b></li>
                                                    </ul>
                                                    )}
                                                   {responseType?.responseTypePerSport && responseType?.responseTypePerSport[2] && (
                                                    <ul className='flex align-items-center justify-content-between list-none mb-0'>
                                                        <li><span className='indicator not-offered'></span>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[2]?.responseType : ''}</li>
                                                        <li><b>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[2]?.responseRate.toFixed(2) : 0}%</b></li>
                                                    </ul>
                                                    )}
                                                </div>
                                                ):(
                                                    <div className='flex justify-content-center'>
                                                        <p className='mt-3'>No data available</p>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    ):(
                                    <div className="chartbar-details-view">
                                        <PR.Chart type="bar" ref={chartRef} data={chartDataNew} options={type === 'rrps' ? chartOptionsWithPerc : chartOptions} />
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            <PR.Dialog visible={visible} footer={footerContent} className="export-dialog">
            <div className="export-header-section">
                <h2>Export Graph as</h2>
                <p>Select file format</p>
            </div>
            <div className="export-body-section">
                <PR.Button label="PNG" onClick={() => setExportType('png')} outlined className="export-btns" />
                <PR.Button label="CSV" onClick={() => setExportType('csv')} outlined className="export-btns" />
                <PR.Button label="PDF" onClick={() => setExportType('pdf')} outlined className="export-btns" />
            </div>
            </PR.Dialog>
        </>
    );
};

export default MyAnalyticsDetails;