import React from "react";
import "../PrivacyPolicy/PrivacyPolicy.scss";
import Layout from "../../layouts/Layout";

const TermsConditions = () => {
    return (
        <>
            <Layout>
                <section className="content-section">
                    <div className="m-0">
                        <h1>Terms & Conditions</h1>
                    </div>
                    <div className="grid mt-2">
                        <div className="col-9">
                            <div className="content-wrapper">
                                <h2>Effective as of 23 January 2023</h2>
                                <p>This Terms & Conditions(the "Terms & Conditions") describes how Folloze, Inc. and its affiliated companies (“Folloze,” “we,” “us,” and “our”) collect, use, and disclose personal data, including of users of Folloze.com and any other websites owned by Folloze, and any other services we offer (together with our websites, the "Services"). This Terms & Conditions also applies to personal data we collect about you offline, such as when you contact us by phone or communicate with us in person. Capitalized terms not defined in this Terms & Conditions have the meanings given in our Terms of Service.
                                Folloze provides various products, tools and services through our Services as part of our online platform that enables users to curate, organize and share information, documents, links, files and other digital content with other users within and outside of their organizations. This Terms & Conditions does not apply to information we process as a data processor or service provider on behalf of our customers. Folloze does not control how customers use information Folloze processes on their behalf, and you should consult with the applicable customer to understand how they use information they receive from Folloze.</p>
                                <p>By accessing or using the Services or otherwise providing us with information, you acknowledge the applicability of this Terms & Conditions. If you do not agree to this Terms & Conditions, please do not access or use the Services or provide us with information.</p>
                                <span>1. What Information We Collect, How We Get It</span>
                                <p>We collect data about you as described below when we provide our Services. Please note that we need certain types of information to provide the Services to you. If you do not provide us with such information, or if you ask us to delete that information, you may no longer be able to access or use certain aspects of the Services.</p>
                                <span>2. Information you provide directly to us</span>
                                <p>We may collect information that you provide directly to us through user entry. We collect personal data that you voluntarily provide to us. You may provide us this information when you create a user account; order a product or service; request subscription content, including when you register for trial accounts; send us a question via e-mail; or communicate with us in person, such as through conferences or events; or otherwise contact us.</p>
                                <p>Information you provide directly to us may concern you and others and may include, but is not limited to:</p>
                                <ul>
                                    <li>Contact Information and Identifiers. You may provide us with contact information or other personal identifiers, such as your name, email address, mailing address, or telephone number.</li>
                                    <li>Financial Information. We may collect payment information for our Services, such as when we engage payment processors to collect your payment information.</li>
                                    <li>Business Information. We may collect general information about your employment or business, such as a business title, business phone, business email address, business product and service offerings, and other information you provide us when communicating with us.</li>
                                    <li>Transaction Information. We may collect information about transactions with you, such as purchases, purchase amount, and date of purchase;</li>
                                    <li>Communications. We may collect your communications with us, such as when you submit questions, communicate with our customer support, or otherwise communicate with Folloze.</li>
                                </ul>
                                <span>3. Information from affiliates and non-affiliated parties</span>
                                <p>We may collect information about you or others through our affiliates or through non-affiliated parties. We may also collect information about you from other users. For example, users may provide us with information about colleagues or other prospective users of our Services, including name, e-mail and other contact information.</p>
                                <p>We may combine information that we collect from you through the Services with information that we obtain from such other parties and information derived from other products or Services we provide.</p>
                                <span>4. Information we collect automatically</span>
                                <p>Device/Usage Information. We and our vendors, which include ad networks and analytics companies such as Google Analytics, may use cookies, web beacons, and other tracking technologies to collect information about the computers or devices (including mobile devices) you use to access the Services. We also receive technical information from metadata. As described further below, we may collect and analyze information including but not limited to (a) browser type; (b) ISP or operating system; (c) domain name; (d) access time; (e) referring or exit pages; (f) page views; (g) IP address; (h) unique device identifiers (e.g. IDFA or Android ID); (i) version of our Services you're using; and (j) the type of device that you use. We may also track when and how frequently you access or use the Services, including how you engage with or navigate our website or mobile application. We use this information (including the information collected by our vendors) for analytics (including to determine which portions of the Services are used most frequently and what our users like/do not like), to evaluate the success of our Services and advertising campaigns, offer you any other or additional services that can be helpful to you or our users or identify the most efficient path for getting you to the content that interests you, and as otherwise described in this Policy.</p>
                                <p>Cookies and Other Electronic Technologies. “Cookies” are small data files that are placed on your browser by a website. The cookies help provide additional functionality to the Services and help us analyze usage more accurately. “Web beacons” (also known as “clear gifs” and “pixel tags”) are small transparent graphic images that are often used in conjunction with cookies to further personalize our Services. “Metadata” is usually technical data that is associated with user content. For example, metadata can describe how, when and by whom a piece of user content was collected. Google Analytics helps us understand how users use our Services by collecting information sent by your browser, including your IP address, and utilizing cookies. Google Analytics receives and stores information generated by your browser or device about your use of the Services and their use of the information is governed by their privacy policy. For more information, see http://www.google.com/policies/privacy/partners/ and http://www.google.com/policies/privacy/.</p>
                                <p>You can typically remove and reject cookies from our online services with your browser settings. Many browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it could affect how the Services work for you. As we adopt additional technologies, we may also gather additional information through other methods. Google Connectivity. We may enable our customers to connect our Services with their Google Gmail account. If offered, you will have the option to connect your Folloze account with Gmail. If you elect to connect your Folloze account with Gmail, you grant us access to certain features available via the Gmail Application Programming Interface. By enabling the Gmail Application Programming Interface and authorizing our access, Folloze will have: (1) read-only access to your email message metadata, such as headers and labels, but not the email body; (2) the ability to send emails from your Gmail account; and/or (3) other permissions you explicitly grant to us. Folloze processes such data to enable customers to create email campaigns from their Gmail account, and we also use the data on behalf of customers to track replies to such email campaigns. Notwithstanding anything to the contrary herein, Folloze further processes such data in accordance with Google's Limited Use Requirements.</p>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="content-wrapper sticky-box">
                                <p>For any further assistance or inquiries, don't hesitate to contact our support team</p>
                                <p><span>Email:</span> ibia.bet@gmail.com</p>
                                <p><span>Mobile:</span> +1 (888)12 123</p>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default TermsConditions;