import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as PR from "../../../prime-react/index";
import Layout from '../../../layouts/Layout';
import "./AlertTags.scss";
import { getPlayers, modifyTag, deleteAlertTag} from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, pagecount } from '../../../utils/reuse';
import { useFormik } from "formik";
import * as Yup from 'yup';

const AlertTags = () => {
    const [visible, setVisible] = useState(false);
    const [deletePopupVisible, setDeletePopupVisible] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchedTag, setSearchedTag] = useState(null);
    const [updateData, setUpdateData] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef(null);

    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pagecount + 1);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);

    const sessionData = useSelector(state => state.auth?.sessionData);
    const headers = useMemo(() => {
        return { 
            sessionid: sessionData.sessionId
        };
    }, [sessionData.sessionId]);

    const gotoAlertList=(rowData)=>{
        navigate(`/alerts?tagId=${rowData?.id}&tagName=${rowData?.name}&alertCount=${rowData?.alertsCount ?? 0 }`);
    }

    const getTagsResponse = useCallback(() => {
        setLoading(true);
        const obj = {
            offset: offset,
            limit: limit,
            includeCount: true
        };

        if (searchedTag) {
            obj.search = searchedTag;
        }
        getPlayers(obj, headers, dispatch, response => {
            if(response.result === 'SUCCESS'){
                const responseData = response.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                const result = (responseData?.length > 0) ? responseData.slice(0, pagecount) : [];
                if (result.length > 0) {
                    setLast(responseData?.length <= pagecount);
                    setPaging(true)
                    setTagsData(result);
                } else {
                    setLast(true);
                    setPaging(false);
                    setTagsData([]);
                    toast.current.show({  severity: 'warn', summary:'Warning', detail: 'No records found' });
                }
            } else {
                setLast(true);
                setPaging(false);
                setTagsData([]);
                const error = response.error;
                toast.current?.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
            setLoading(false);
        })
    }, [dispatch, headers, limit, offset, searchedTag])
    
    const handlePageChange = (currentPage) =>{
        setPaging(false);
        setPage(currentPage);
        const offsetVal = (currentPage - 1) * pagecount;
        const limitVal = pagecount + 1;
        setOffset(offsetVal);
        setLimit(limitVal);
    }

    const searchTagHandler = (e) => {
        const searchedTag = e.target.value ?? null;
        if (searchedTag?.length <= 15) {
            setSearchedTag(searchedTag);
          }
      
    }

    const initialValues = {
        id: updateData ? updateData.id : '',
        name: updateData ? updateData.name : '',
        description: updateData ? updateData.description : '',
    }

    const validationSchema = () => {
        return Yup.object().shape({
            name: Yup.string()
                .trim()
                .required('Name is required')
                .min(3, "Name should be at least 3 characters long")
                .max(20, "Name can be at most 20 characters long"),
            description: Yup.string()
                .trim()
                .required('Description is required')
                .min(3, "Description should be at least 3 characters long")
                .max(20, "Description can be at most 20 characters long"),
        });
    }

    const handleSubmit = values => {
        const getResponse = response => {
            const summary = updateData ? 'updated' : 'created';
            if (response.result === "SUCCESS") {
                setLoading(false);
                setVisible(false);
                getTagsResponse();
                formik.resetForm();
                toast.current.show({ severity: 'success', summary: `Tag ${summary} successfully` });
            } else {
                setLoading(false);
                const error = response.error;
                toast.current?.show({ severity: error?.severity, summary:'Error', detail: (error?.errorMsg) ? error?.errorMsg : error?.summary })
            }
        }
        const addRequestObj = {
            name: values.name,
            description: values.description
        }
        const updateRequestObj = {
            id: +values.id,
            name: values.name,
            description: values.description
        }
        const requestObj = updateData ? updateRequestObj : addRequestObj;
        modifyTag(requestObj, headers, dispatch, getResponse);
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    useEffect(() => {
        getTagsResponse();
    }, [getTagsResponse]);

    const action = (rowData) => {
        return (
            <div className="flex gap-2 align-items-center">
                <PR.Button label="View details" type='submit' severity="secondary" outlined className="view-details-button" onClick={() => gotoAlertList(rowData)} />
                <PR.Button icon="pi pi-pencil" outlined className="view-details-button only-icon" type='submit' onClick={() => {setVisible(true); setUpdateData(rowData)}} />
                <PR.Button icon="pi pi-trash" outlined className="view-details-button only-icon" type='submit' onClick={() => {setDeletePopupVisible(true); setUpdateData(rowData)}} />
            </div>
        );
    };

    const footerDelete = (
        <div>
            <PR.Button label="Cancel" onClick={() => setDeletePopupVisible(false)} outlined className="logout-btns" />
            <PR.Button label="Delete" onClick={() => handleDeleteTag()} outlined autoFocus className="logout-btns click-btn" />
        </div>
    );

    const handleDeleteTag = () => {
        if(updateData?.alertsCount>0){
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Tags with alerts attached to them can't be deleted",
            });
            setDeletePopupVisible(false)
            return;
          }
          deleteAlertTag(updateData.id,headers,dispatch,(response) =>{
            if (response.result === "SUCCESS") {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Tag deleted successfully",
              });
              getTagsResponse();
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: response.error.errorMsg,
              });
            }
            setDeletePopupVisible(false)
          })
    }

    const paginationTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        CurrentPageReport: (options) => {
            return ( <span className='current-page'>Page {page}</span> )
        },
        PrevPageLink: (options) =>{
            return (<PR.Button className='pagination-buttons'
                        onClick={() => handlePageChange(page-1)}
                        disabled={paging ? page <= 1 ? true : false :true}>Previous</PR.Button>
                    )
        },
        NextPageLink: (options) =>{
            return (<PR.Button disabled={paging ? last ? true : false : true} onClick={() => handlePageChange(page+1)} 
            className='pagination-buttons'>Next</PR.Button>)
        }
    };
    const header = (
        <>
            <div className="filter-section flex gap-2">
                <span className="p-input-icon-left flex align-items-center search-box">
                    <i className="pi pi-search" />
                    <PR.InputText name="searchTag" placeholder="Search by tag name.." className="search-field" onChange={searchTagHandler} autoComplete='off'/>
                </span>
                <PR.Button label="Create new tag" icon="pi pi-plus" className="create-button" onClick={() => { setVisible(true); setUpdateData(""); }} />
            </div>
        </>
    );

    const alertsCountDisplay = (rowData) => {
        const count = rowData?.alertsCount ?? 0;
        const label = count > 1 ? ' alerts' : ' alert';
        return `${count} ${label}`;
    }

    const createdAtDateDisplay = (rowData) => {
        return (
            <div className="flex align-items-center gap-3">
                <div className="event-column">
                    <p>{formatDate(rowData.createdAt).displayDate}</p>
                    <span>{formatDate(rowData.createdAt).displayTime}</span>
                </div>
            </div>
        );
    };

    return (
        <>  
            <Layout backBtn={"home"}>
                <section className="all-alerts-section all-tags-section mt-5">
                    <div className="m-0">
                        <h1>Alert tags</h1>
                    </div>
                    <div className="alerts-tab mt-2">
                        <div className="grid grid-nogutter">
                            <div className="card col-12 relative">
                                <PR.DataTable 
                                    value={tagsData}
                                    stripedRows 
                                    className="data-table"
                                    paginatorTemplate={paginationTemplate}
                                    paginator
                                    rows={pagecount}
                                    header={header}
                                    loading={loading}
                                >
                                    <PR.Column field='name' header="Tag name"></PR.Column>
                                    <PR.Column field="description" header="Description"></PR.Column>
                                    <PR.Column body={alertsCountDisplay} header="Alerts" sortable sortField="alertsCount"></PR.Column>
                                    <PR.Column body={createdAtDateDisplay} header="Created" sortable sortField="createdAt"></PR.Column>
                                    <PR.Column body={action} header="Action" className="last-column"></PR.Column>
                                </PR.DataTable>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Create/Edit Tag Dialog */}
                <PR.Dialog visible={visible} className="logout-dialog response-dialog tags-dialog">
                    <div className="dialog-header">
                        <div className='mb-5'>
                            <h2>{updateData ? 'Update Tag' : 'Create Tag'}</h2>
                            <p>Categorize the alert according to the relevant tag</p>
                        </div>
                    </div>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="dialog-body-section">
                        <div className="p-float-label-none hidden">
                                <span>
                                    <PR.InputText placeholder="id" name="id" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.id} className="w-full" />
                                </span>
                            </div>
                            <div className="flex flex-column form-input gap-2 mb-3">
                                <label htmlFor="name">Tag name</label>
                                <PR.InputText id="name" name="name" type="text" aria-describedby="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} placeholder='Enter Tag name'/>
                                {formik.errors.name && formik.touched.name
                                    ? <div className='error'>{formik.errors.name}</div>
                                    : ''
                                }
                            </div>
                            <div className="flex flex-column gap-2">
                                <label htmlFor="description">Tag description</label>
                                <div className="card">
                                    <PR.InputTextarea name="description" placeholder='Something about tag..' className="description-textarea" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.description}/>
                                    {formik.errors.description && formik.touched.description
                                    ? <div className='error'>{formik.errors.description}</div>
                                    : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='dialog-footer'>
                            <PR.Button label="Cancel" type="button" onClick={() => {formik.resetForm(); setVisible(false)}} outlined className="logout-btns" />
                            <PR.Button label={updateData ? "Update tag" : "Create tag"} type="submit" outlined autoFocus className="logout-btns click-btn" disabled={!formik.isValid}/>
                        </div>
                    </form> 
                </PR.Dialog>

                {/* Delete Tag Dialog */}
                <PR.Dialog visible={deletePopupVisible} footer={footerDelete} onHide={() => setDeletePopupVisible(false)} className="logout-dialog response-dialog tags-dialog">
                    <div className="dialog-header">
                        <div className='gap-2'>
                            <h2>Delete tag</h2>
                            <p>Are you sure you want to delete this tag?</p>
                        </div>
                    </div>
                </PR.Dialog>
            </Layout>
            <PR.Toast ref={toast} position='top-right' />
        </>
    );
};

export default AlertTags;