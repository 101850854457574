import { configureStore } from "@reduxjs/toolkit";
import authReducer from './auth'
import newsReducer from './news'

const store = configureStore({
    reducer: {
        auth: authReducer,
        news: newsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
})

export default store;