import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as PR from "../../prime-react/index";
import "./AlertDetails.scss";
import Layout from "../../layouts/Layout";
import {
  eventIcon,
  editIcon,
  chatIcon,
  msgIcon,
} from "../../assets/images/index";
import AlertForm from "../../shared/CreateAlert/AlertForm";
import {
  addComment,
  createResponse,
  getAlertDetails,
  getAlertConversation,
  getFileDetails,
  updateAlertConversation,
  createAlertConversation,
  updateAlertDescription,
  getAlertResponses,
  updateAlertStatus,
  updateAlertLasttRead,
  updateAlertAdvice,
  createAlert,
  getAssigneeList,
  updateAssignee,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetterOfEachSentence,
  formatNumber,
  formatTime,
  getSportIcon,
  chatCount,
  descriptionCount,
  getStatusLabel,
  textTypingHandler,
  handlePaste,
  capitalizeFirstLetter,
  fileUploadLimit
} from "../../utils/reuse";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RAW_FILTER_DATA, getDisplayData } from "../common";
import moment from "moment";
import ImageDisplay from "../ImageDisplay";
import EditAlertForm from "../EditAlert/EditAlertForm";

const AlertDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [alertDesc, setAlertDesc] = useState("");
  const [alertData, setData] = useState({});
  const [alertResponses, setAlertResponses] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [ibiaAdvice, setIbiaAdvice] = useState("");
  const [notes, setNotes] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [commentError, setCommentError] = useState("");
  const [editAdviceVisible, setEditAdviceVisible] = useState(false);
  const [editedIbiaAdvice, setEditedIbiaAdvice] = useState("");
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [editedFiles, setEditedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ibiaAttachments, setIbiaAttachments] = useState([]);
  const [nonIbiaAttachments, setNonIbiaAttachments] = useState([]);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const isMember = useSelector((state) => state.auth.role) === "member";
  const isAnalyst = useSelector((state) => state.auth.role) === "analyst";
  const [statusDropdown, setStatusDropdown] = useState(null);
  const role = sessionData?.role;
  const headers = useMemo(() => {
    return { sessionid: sessionData.sessionId };
  }, [sessionData.sessionId]);
  const companyUser = sessionData.companyName;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterResponseType] = useState([
    "all",
    "offered and suspicious",
    "offered and not suspicious",
    "not offered",
  ]);

  const [currentModal, setCurrentModal] = useState(0);
  const [offer, setOffer] = useState(null);
  const [suspicious, setSuspicious] = useState(null);
  const [openCommentbox, setOpenCommentbox] = useState(false);
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [chatData, setChatData] = useState([]);
  const [chatParticipants, setChatParticipants] = useState([]);
  const [chatInfo, setChatInfo] = useState({
    alertID: "",
    groupID: "",
    conversationID: "",
  });
  const companyName = sessionData.companyName;
  const userName = sessionData.companyName;
  const groupID = isAnalyst ? alertData?.createdByGroupID : sessionData.groupID;
  const [chatLoading, setChatLoading] = useState(false);
  const [isChatPopupOpen, setChatPopupOpen] = useState(false);
  const [chatCompany, setChatCompany] = useState();

  const [chatVisible, setChatVisible] = useState(false);
  const [conversationClicked, setConversationClicked] = useState(false);
  const [isAlertsUpdated, setIsAlertsUpdated] = useState(false);

  const location = useLocation();
  const responseID = location?.state?.responseID;
  const [responsesActiveIndex, setResponsesActiveIndex] = useState(null)

  const [loadingScroll, setLoadingScroll] = useState(true)
  const responsesDiv = useRef(null)

  useEffect(() => {
    const scrollOnLoad = () => {
      if(responseID){
        if(responsesDiv.current){
          responsesDiv.current.scrollIntoView({ behavior: 'smooth' })

          setTimeout(() => {
            window.scrollBy({
                top: 1,
                behavior: 'smooth'
            });
        }, 500); 
        }
      }
    }

    setTimeout(scrollOnLoad, 1500)
    // eslint-disable-next-line
  },[loadingScroll])

  useEffect(() => {
    const deleteState = () => {
      if (location.state?.responseID) {
        navigate(location.pathname, { state: null, replace: true });
      }
    }

    setTimeout(deleteState, 7500)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let findResponseTimeout;
    let stopLoadingTimeout;

    const findResponseToExpand = () => {
      const index = filteredData?.findIndex(response => response.responseID === responseID);
      if (index !== -1) {
        setResponsesActiveIndex(index);
      }

      // Stop loading after everything is set
      stopLoadingTimeout = setTimeout(() => setLoadingScroll(false), 500);
    };

    if (responseID) {
      // Delay the execution to allow time for rendering
      findResponseTimeout = setTimeout(findResponseToExpand, 1500);
    }else{
      setLoadingScroll(false)
    }

    return () => {
      clearTimeout(findResponseTimeout);
      clearTimeout(stopLoadingTimeout);
    };
    // eslint-disable-next-line
  }, [filteredData]);

  const additionalStyle =
    RAW_FILTER_DATA.statusFilter?.find(
      (item) => item.id === alertData.alertStatus
    )?.style || "";

  const clearDialogData = () => {
    setIbiaAdvice("");
    setSelectedFiles([])
    setStatusDropdown(null);
    setUploadedFiles([]);
    setDeletedAttachments([]); 
    setEmailOption("none");
    setNotes('')
  };
  const openEditAdviceDialog = () => {
    setEditedIbiaAdvice(alertData.ibiaAdvice || "");
    const uniqueFiles = ibiaAttachments
    ? [...new Map(ibiaAttachments?.map(file => [file.key, file])).values()]
    : [];

  setEditedFiles(uniqueFiles);
  setEditAdviceVisible(true);
  };

  const setAdviceAttachments = () => {

    setIbiaAdvice(alertData.ibiaAdvice || "")
    const uniqueFiles = ibiaAttachments
    ? [...new Map(ibiaAttachments?.map(file => [file.key, file])).values()]
    : [];

    setEditedFiles(uniqueFiles);
  }
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (alertData?.fileAttachment) {
      const ibiaFiles = alertData.fileAttachment.filter(
        (file) => file.owner === 'IBIA'
      );
      const nonIbiaFiles = alertData.fileAttachment.filter(
        (file) => file.owner !== 'IBIA'
      );
      setIbiaAttachments(ibiaFiles);
      setNonIbiaAttachments(nonIbiaFiles);
    }else{
      setIbiaAttachments([]);
      setNonIbiaAttachments([]);
    }
  }, [alertData]);

  const handleDeleteFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const handleDeleteSelectedFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  
  const handleFileUpload = (event) => {
    const totalFiles =
      editedFiles.length + uploadedFiles.length + event.files.length;

    if (totalFiles > 3) {
      toast.current.show({
        severity: "error",
        summary: "Upload Error",
        detail: "Cannot upload more than 3 files.",
      });
      return;
    }
  
    const newFiles = event.files;
    const duplicateFiles = newFiles.filter((newFile) =>
      [...uploadedFiles, ...editedFiles].some(
        (existingFile) => existingFile.name === newFile.name
      )
    );
  
    if (duplicateFiles.length > 0) {
      toast.current.show({
        severity: "warn",
        summary: "Duplicate File Names",
        detail: "File names must be unique. Please rename your file(s) before uploading.",
      });
      fileInputRef.current.clear();
      return;
    }
    const validFiles = newFiles.filter((file) => {
      if (file.size > fileUploadLimit.value) {
        toast.current.show({
          severity: "error",
          summary: "File Too Large",
          detail: `${file.name} is larger than ${fileUploadLimit.label}.`,
        });
        return false;
      }
      return true;
    });
  
    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  
    if (fileInputRef.current) {
      fileInputRef.current.clear();
    }
  };
  
  const handleEditFileUpload = (event) => {
    const totalFiles =
      editedFiles?.length + selectedFiles?.length + event.files?.length;
  
    if (totalFiles > 3) {
      toast.current.show({
        severity: "error",
        summary: "Upload Error",
        detail: "Cannot upload more than 3 files.",
      });
      return;
    }
  
    const newFiles = event.files;
    const duplicateFiles = newFiles.filter((newFile) =>
      [...selectedFiles, ...editedFiles].some(
        (existingFile) => existingFile.name === newFile.name
      )
    );
  
    if (duplicateFiles?.length > 0) {
      toast.current.show({
        severity: "warn",
        summary: "Duplicate File Names",
        detail: "File names must be unique. Please rename your file(s) before uploading.",
      });
      fileInputRef.current.clear();
      return;
    }
  
    const validFiles = newFiles.filter((file) => {
      if (file.size > fileUploadLimit.value) {
        toast.current.show({
          severity: "error",
          summary: "File Too Large",
          detail: `${file.name} is larger than ${fileUploadLimit.label}.`,
        });
        return false;
      }
      return true;
    });
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  
    if (fileInputRef.current) {
      fileInputRef.current.clear();
    }
  };
  
  const handleDeleteExistingFile = (index) => {
    const updatedAttachments = [...editedFiles];
    const deletedAttachment = updatedAttachments?.splice(index, 1)[0];
    setEditedFiles(updatedAttachments);
    setDeletedAttachments([
      ...deletedAttachments,
      {
        type: "application/pdf",
        key: deletedAttachment.key,
      },
    ]);
  };

  const handleEditAdviceSubmit = () => {
    const id = params?.id;
    const formData = new FormData();
  
    const totalFiles = editedFiles.length + selectedFiles.length;
    if (totalFiles > 3) {
      toast.current.show({
        severity: "error",
        summary: "Upload Error",
        detail: "Cannot upload more than 3 files.",
      });
      return;
    }
  
    const data = {
      alertStatus: alertData?.alertStatus === "not reported" ? "notreported" : alertData?.alertStatus,
      ibiaAdvice: editedIbiaAdvice,
      deletedAttachments,
    };
    formData.append("data", JSON.stringify(data));
    selectedFiles?.forEach((file) => {
      formData.append("attachments", file);
    });
  
    updateAlertAdvice(id, formData, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "IBIA advice and attachments updated successfully",
        });
        getDetails();
        setEditAdviceVisible(false);
        clearDialogData();
      } else {
        const error = response?.error;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    });
  };
  
  const handleChangeAlertStatus = () => {
    const id = params?.id;
    let data = {};
    const formData = new FormData();

    uploadedFiles.forEach((file, index) => {
      formData.append(`attachments`, file);
    });

    if (alertData.alertStatus === "open") {
      data.alertStatus = "evaluating";
    } else if (alertData.alertStatus === "evaluating") {
      if (statusDropdown === "notreported") {
        data = {
          alertStatus: "notreported",
          caseStatus: "nofurtheraction",
          ibiaAdvice,
        };
      } else if (statusDropdown === "suspicious") {
        data = {
          alertStatus: "suspicious",
          caseStatus: "underinvestigation",
          ibiaAdvice,
        };
      } else if (statusDropdown === "open") {
        data = { alertStatus: "open" };
      }
      if (deletedAttachments.length > 0) {
        data.deletedAttachments = deletedAttachments;
      }
    } else if (alertData.alertStatus === "suspicious") {
      if (alertData.caseStatus === "under investigation") {
        if (statusDropdown === "evaluating") {
          data = { alertStatus: "evaluating", notes };
        } else if (statusDropdown === "closed") {
          data = {
            alertStatus: "suspicious",
            caseStatus: "closed",
            notes,
            emailNotify:emailOption
          };
        } else if (statusDropdown === "sanctioned") {
          data = {
            alertStatus: "suspicious",
            caseStatus: "sanctioned",
            notes,
            emailNotify:emailOption
          };
        }
      }
    } else if (
      alertData.alertStatus === "not reported" &&
      alertData.caseStatus === "no further action"
    ) {
      data = { alertStatus: "evaluating", notes };
    }

    formData.append("data", JSON.stringify(data));

    updateAlertStatus(id, formData, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Alert status has been changed successfully",
        });
        getDetails();
        clearDialogData();
      } else {
        const error = response?.error;
        toast.current?.show({
          severity: error?.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
      setAlertStatusVisible(false);
    });
  };

  const emailOptions = [
      { label: "None", value: "none" },
      { label: "All members", value: "allmembers" },
      { label: "Alert raiser/ Offered & Suspicious respondant", value: "alertraiser" },
    ]

  const [emailOption, setEmailOption] = useState("none")
  const statusValues = useMemo(() => {
    if (
      alertData.alertStatus === "suspicious" &&
      alertData.caseStatus === "under investigation"
    ) {
      return [
        { label: "Evaluating", value: "evaluating" },
        { label: "Sanctioned", value: "sanctioned" },
        { label: "Closed", value: "closed" },
      ];
    }
    return [
      { label: "Open", value: "open" },
      { label: "Suspicious", value: "suspicious" },
      { label: "Not Reported", value: "notreported" },
    ];
  }, [alertData.alertStatus, alertData.caseStatus]);

  const handleViewCaseStatus = () => {
    navigate(`/case-updates?showResultOnly=true&id=${alertData?.alertID}`);
  };

  // Change alert status - Dialog
  const [alertStatusVisible, setAlertStatusVisible] = useState(false);
  const [assigneeVisible, setAssigneeVisible] = useState(false);
  const [assigneeDropdown, setAssigneeDropdown] = useState(null);

  const alertStatusFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {clearDialogData(); setAlertStatusVisible(false); }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Change status"
        onClick={handleChangeAlertStatus}
        outlined
        autoFocus
        className="logout-btns click-btn"
        disabled={
          !(
            statusDropdown ||
            ((alertData.alertStatus === "open" ||
              alertData.alertStatus === "not reported") &&
              !statusDropdown)
          )
        }
      />
    </div>
  );
  const changeAssigneeFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {
          setAssigneeVisible(false)
          setAssigneeDropdown(null)
        }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Change assignee"
        onClick={() => {
          setAssigneeVisible(false)
          setAssigneeDropdown(null)
          setAssignee()
        }}
        outlined
        autoFocus
        disabled={!assigneeDropdown}
        className="logout-btns click-btn"
      />
    </div>
  );

  const [assigneeNames, setAssigneeNames] = useState(null)

  const getAssigneeNames = () => {
    getAssigneeList(sessionData.groupID, headers,dispatch, (response) =>{
      if(response.result === "SUCCESS"){
        const group = response.data[0]
        const sortedUsers = group.users?.length >0 ? group.users?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setAssigneeNames(sortedUsers)
      }else{
        const error = response?.error;
        toast.current.show({
          severity: error?.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    })
  }

  const setAssignee = () => {
    const obj ={
      alertID: params?.id,
      assigneeID: assigneeDropdown?.id
    }
    updateAssignee(obj, headers, dispatch, (response) =>{
      if(response.result === "SUCCESS"){
        setData(prev => ({
          ...prev,
          assignee: assigneeDropdown.name
        }))
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Assignee updated successfully",
        });

      }else{
        const error = response?.error;
        toast.current.show({
          severity: error?.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    })
  }

  // function to call add comment API
  const createComment = () => {
    const id = params?.id;

    if (value.trim() !== "") {
      addComment(id, value, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          setData((prevData) => ({
            ...prevData,
            comments: [...(prevData.comments || []), response.data],
          }));
        } else {
          const error = response?.error;
          toast.current.show({
            severity: error?.severity,
            summary: "Error",
            detail: error?.errorMsg ? error?.errorMsg : error?.summary,
          });
        }
      });
      setValue("");
    }
  };
  //edit ibia advice
  const editAdviceFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {setEditAdviceVisible(false); clearDialogData()}}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Save changes"
        onClick={handleEditAdviceSubmit}
        outlined
        autoFocus
        className="logout-btns click-btn"
      />
    </div>
  );


  // function to edit alert description API
  const editAlertDesc = () => {
    const id = params?.id;
    const alertDescFinal = alertDesc.trim().replace(/[ \t]{2,}/g, " ");
    if (alertDesc.trim() !== "") {
      updateAlertDescription(
        id,
        alertDescFinal,
        headers,
        dispatch,
        (response) => {
          if (response.result === "SUCCESS") {
            setData((prevData) => ({
              ...prevData,
              description: alertDescFinal,
            }));
            setAlertDesc(alertDescFinal);
            toast.current?.show({
              severity: "success",
              summary: "Success",
              detail: "Alert description updated successfully",
            });
          } else {
            const error = response?.error;
            toast.current.show({
              severity: error?.severity,
              summary: "Error",
              detail: error?.errorMsg ? error?.errorMsg : error?.summary,
            });
          }
        }
      );
    }
    setVisible(false);
  };

  const updateChat = (chatID, content) => {
    setChatLoading(true);
    updateAlertConversation(chatID, content, headers, dispatch, (response) => {
      getChatConversation(chatInfo.alertID, chatInfo.groupID);
      setChatLoading(false);
    });
  };

  const createChat = (alertID, groupID, content) => {
    setChatLoading(true);
    createAlertConversation(
      alertID,
      groupID,
      content,
      headers,
      dispatch,
      (response) => {
        getChatConversation(chatInfo.alertID, chatInfo.groupID);
        setChatLoading(false);
      }
    );
  };

  const getChatConversation = (id, groupID) => {
    setChatLoading(true);
    getAlertConversation(id, groupID, headers, dispatch, (response) => {
      setChatPopupOpen(true);
      if (response.data?.messages) {
        setChatData(
          response.data.messages.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
        );
        setChatInfo((prev) => ({
          ...prev,
          conversationID: response.data.conversationID,
        }));
        setChatParticipants(response.data.participants);

        // to update chatRead only when readAt is null for last sender's message
        const lastSenderMessage = response.data.messages.filter(message => message.senderID !== sessionData.groupID).pop();
        if (lastSenderMessage && !lastSenderMessage.readAt) {
            setIsAlertsUpdated(true);
        }

      } else {
        setChatData([]);
      }
      setChatLoading(false);
    });
  };

  const currentConversationID = useMemo(
    () => chatInfo?.conversationID,
    [chatInfo]
  );

  useEffect(() => {
    if (currentConversationID && isAlertsUpdated) {
        updateChatRead(currentConversationID, {isRead: true, includeActiveConversation: conversationClicked ?  true : false}); 
        setIsAlertsUpdated(false);
    }
  // eslint-disable-next-line
  },[isAlertsUpdated])

  const updateChatRead = (chatID, isRead) => {
    
    if(chatID) {
    updateAlertLasttRead(chatID, isRead, headers, dispatch, response => {
        if(response.result === 'SUCCESS') {
        const responseData = response.data;
        setChatLoading(false)   
        if (conversationClicked){
          // this is to update the new message dot when clicked on conversation chat
          setData((prevData) => ({
            ...prevData,
            activeConversation: responseData?.activeConversation,
          }));
        } else {
            // this is to update the new message dot when clicked on responses chat
            const newData = filteredData.map(item => 
            item?.respondingGroupID === chatInfo?.groupID
                ? { ...item, activeConversation: responseData?.activeConversation }
                : item
            );
            setFilteredData(newData);

            // Check if all items in filteredData have activeConversation as false
           const allInactive = newData.every(item => item.activeConversation === false);
            if (allInactive) {
            setData(prevData => ({
              ...prevData,
              activeConversation: false,
            }));
          }
          }
        }
        setConversationClicked(false);
      })
   }
  }
  
  const getChatUserName = (senderId) => {
    const userName = chatParticipants
      .filter((user) => user.ID === senderId)
      .map((user) => user?.name);
    return userName[0];
  };

  const getChatUserImage = (senderId) => {
    const userImage = chatParticipants
      .filter((user) => user.ID === senderId)
      ?.map((user) => user.image);
    return userImage[0];
  };
  const messagesEndRef = useRef(null);
  const isDisabled = !(message?.trim())
  const scrollToBottom = () => {
      if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  };

  useEffect(() => {
    if (chatVisible) {
      setTimeout(scrollToBottom, 10);
    }
  }, [chatVisible, chatData]);

  const chatFooterContent = (
    <div>
      <div className="flex align-items-center justify-content-between">
        <span className="ask-field">
          <PR.InputText
            placeholder="Ask anything.."
            value={message}
            onChange={(e) => {
              if (e.target.value.length > chatCount) {
                setMessage(e.target.value.substring(0, chatCount));
                setErrorMessage(
                  `Message should be ${chatCount} characters or less.`
                );
              } else {
                setMessage(e.target.value);
                setErrorMessage("");
              }
            }}
            onPaste={(e) => {
              const pastedData = e.clipboardData.getData("text");
              const maxCharsToAdd = chatCount - message?.length;
              const newMessage =
                message + pastedData?.substring(0, maxCharsToAdd);
              setMessage(newMessage);
              if (newMessage.length > chatCount) {
                setErrorMessage(
                  `Message should be ${chatCount} characters or less.`
                );
              }
              e.preventDefault();
            }}
          />
        </span>
        <PR.Button
          label="Send message"
          onClick={() => {
            if (chatData?.length === 0) {
              createChat(chatInfo.alertID, chatInfo.groupID, message);
            } else {
              updateChat(chatInfo.conversationID, message);
            }
            setMessage("");
          }}
          outlined
          className="logout-btns send-message"
          disabled={isDisabled}
        />
      </div>
      <br />
      <span style={{ textAlign: "left" }}>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </span>
    </div>
  );

  const [responsesPopupLoading, setResponsesPopupLoading] = useState(false);
  const [responsesData, setResponsesData] = useState({});

  const [respondedVisible, setRespondedVisible] = useState(false);

  const [notRespondedVisible, setNotRespondedVisible] = useState(false);
  const getReference = (count) => {
    return count > 1 ? "members" : "member";
  };

  const showReceived = () => {
    setRespondedVisible(true);
    getAlertResponsesHandler(params?.id);
  };

  const showPending = () => {
    setNotRespondedVisible(true);
    getAlertResponsesHandler(params?.id);
  };
  const getAlertResponsesHandler = (id) => {
    setResponsesPopupLoading(true);
    getAlertResponses(id, headers, dispatch, (response) => {
      if (response.result === "SUCCESS" && response.data) {
        let data = {};
        const alertResponses = response.data;

        const respondedInfo = alertResponses?.filter(
          (response) => response.responseID
        );
        const notRespondedInfo = alertResponses?.filter(
          (response) => !response.responseID
        );

        data["respondedInfo"] = respondedInfo;
        data["respondedCount"] = respondedInfo?.length;

        data["notRespondedInfo"] = notRespondedInfo;
        data["notRespondedCount"] = notRespondedInfo?.length;

        setResponsesData(data);
        setResponsesPopupLoading(false);
      } else {
        setResponsesPopupLoading(false);
      }
    });
  };

  const respondedFooterContent = (
    <div>
      <PR.Button
        label="Close"
        onClick={() => {
          setRespondedVisible(false);
          setResponsesData({});
        }}
        outlined
        className="logout-btns"
      />
    </div>
  );

  const notRespondedFooterContent = (
    <div>
      <PR.Button
        label="Close"
        onClick={() => {
          setNotRespondedVisible(false);
          setResponsesData({});
        }}
        outlined
        className="logout-btns"
      />
    </div>
  );

  const renderFirstModal = () => {
    return (
      <PR.Dialog
        visible={currentModal === 1}
        footer={responseFooter}
        className="logout-dialog response-alert-dialog"
        blockScroll="true"
      >
        <div className="logout-section">
          <p>Respond to the alert</p>
          <h2>Did you offer betting markets on the event?</h2>
          <div className="card grid mt-1">
            <div className="col-6">
              <div className="box">
                <div className="flex align-items-center">
                  <PR.RadioButton
                    inputId="yes"
                    name="offer"
                    value="Yes"
                    onChange={(e) => setOffer(e.value)}
                    checked={offer === "Yes"}
                  />
                  <label htmlFor="yes" className="ml-2">
                    Yes
                  </label>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="box">
                <div className="flex align-items-center">
                  <PR.RadioButton
                    inputId="no"
                    name="offer"
                    value="No"
                    onChange={(e) => setOffer(e.value)}
                    checked={offer === "No"}
                  />
                  <label htmlFor="no" className="ml-2">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PR.Dialog>
    );
  };

  const renderSecondModal = () => {
    return (
      <PR.Dialog
        visible={currentModal === 2 && !openCommentbox}
        footer={responseFooter}
        className="logout-dialog response-alert-dialog"
        blockScroll="true"
      >
        <div className="logout-section">
          <p>Respond to the alert</p>
          <h2>Did you see any suspicious activity?</h2>
          <div className="card grid mt-1">
            <div className="col-6">
              <div className="box">
                <div className="flex align-items-center">
                  <PR.RadioButton
                    inputId="yes"
                    name="suspicious"
                    value="Yes"
                    onChange={(e) => setSuspicious(e.value)}
                    checked={suspicious === "Yes"}
                  />
                  <label htmlFor="yes" className="ml-2">
                    Yes
                  </label>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="box">
                <div className="flex align-items-center">
                  <PR.RadioButton
                    inputId="no"
                    name="suspicious"
                    value="No"
                    onChange={(e) => setSuspicious(e.value)}
                    checked={suspicious === "No"}
                  />
                  <label htmlFor="no" className="ml-2">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PR.Dialog>
    );
  };

  const renderThirdModal = () => {
    return (
      <PR.Dialog
        visible={currentModal === 3 && !openCommentbox}
        className="logout-dialog edit-alert-dialog"
        blockScroll="true"
      >
        <section className="create-alert-section">
          <div className="create-alert-wrapper">
            <div className="m-0">
              <p>Respond to the alert</p>
              <h1>Tell us more about it</h1>
            </div>
            <AlertForm
              initialData={alertData}
              editForm={false}
              handleBack={handleBack}
              handleNext={handleNext}
              nextLabel="Send response"
              backLabel="Back"
            />
          </div>
        </section>
      </PR.Dialog>
    );
  };

  const renderCommentBox = () => {
    return (
      <PR.Dialog
        visible={openCommentbox}
        footer={responseFooter}
        className="logout-dialog response-alert-dialog"
        blockScroll="true"
      >
        <div className="logout-section">
          <p>Respond to the alert</p>
          <h2>Add comment</h2>
          <PR.InputTextarea
            value={comment}
            onChange={(e) => textTypingHandler(e, setComment, chatCount)}
            onPaste={(e) => handlePaste(e, comment, setComment, chatCount)}
            placeholder="Explain in detail.."
            className="add-comment"
          />
          <div className="descriptioncharLimit">
            {chatCount - comment?.length}/{chatCount} characters left
          </div>
        </div>
      </PR.Dialog>
    );
  };

  const formatEvent = (events) => {
    const dataList = events?.map((event) => {
      let data = {};
      data.event = event.eventName;
      data.turnover = event.turnover ? `${event.turnover}%` : `-`;
      data.country = event.locationName;
      data.date = moment
        .utc(event.dateTime)
        .format("MMMM DD, YYYY HH:mm [UTC]");
      return data;
    });

    return dataList;
  };

  const descriptionFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {
          setAlertDesc(alertData?.description);
          setVisible(false);
        }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Save changes"
        onClick={editAlertDesc}
        outlined
        autoFocus
        className="logout-btns click-btn"
      />
    </div>
  );
  const [visibleEditDetails, setVisibleEditDetails] = useState(false);
  const handleBack = () => {
    if (currentModal === 2) {
      setSuspicious(null);
    }
    setOpenCommentbox(false);
    setCurrentModal((prev) => prev - 1);
  };

  const onBackClick = () => {
    setVisibleEditDetails(false);
  };

  const handleUpdateAlert = (obj) => {
    setVisibleEditDetails(false);
    createAlert(obj, headers, dispatch, response => {
      if (response.result === 'SUCCESS') {
          toast.current?.show({  severity: 'success', summary:'Success', detail: 'Updated Alert Successfully' });
          getDetails();
      } else {
          const error = response.error;
          toast.current?.show({ severity: error.severity, summary:'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })         
      }
  })
  };

  const handleNext = (formResponse = null) => {
    if (openCommentbox) {
        const data = new FormData();
        let resData = {};
        if (offer === "No") {
          resData = {
            responseType: "not offered",
            comment: comment.trim() !== "" ? comment : undefined,
          };
        } else if (offer === "Yes" && suspicious === "No") {
          resData = {
            responseType: "offered and not suspicious",
            comment: comment.trim() !== "" ? comment : undefined,
          };
        }
        data.append("data", JSON.stringify(resData));
        createResponseHandler(data);

        setOpenCommentbox(false);
        setCurrentModal(0);
        setComment("");
        setSuspicious(null);
        setOffer(null);
      return;
    }
    if (offer === "No" || suspicious === "No") {
      setOpenCommentbox(true);
      setCurrentModal((prev) => prev + 1);
      return;
    }
    if (
      (currentModal === 1 && offer) ||
      (currentModal === 2 && suspicious) ||
      currentModal === 3
    ) {
      if (currentModal < 3) {
        setCurrentModal((prev) => prev + 1);
      } else {
        createResponseHandler(formResponse);
        setSuspicious(null);
        setOffer(null);
        setCurrentModal(0);
      }
    }
  };
  const backLabel = () => {
    if (currentModal > 1) {
      return "Back";
    } else {
      return "Cancel";
    }
  };

  const nextLabel = () => {
    if (currentModal === 3 || openCommentbox) {
      return "Send response";
    } else {
      return "Continue";
    }
  };

  // function to call create response API
  const createResponseHandler = (data) => {
    const key = params?.id;
    createResponse(key, data, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Response has been submitted successfully",
        });
        getDetails();
      } else {
        const error = response?.error;
        toast.current?.show({
          severity: error?.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    });
  };

  const responseFooter = (
    <div>
      <PR.Button
        label={backLabel()}
        onClick={handleBack}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label={nextLabel()}
        onClick={handleNext}
        disabled={
          currentModal === 1 && !offer
            ? true
            : currentModal === 2 && !openCommentbox && !suspicious
            ? true
            : false
        }
        icon={currentModal === 3 || openCommentbox ? null : "pi pi-arrow-right"}
        iconPos={currentModal === 3 || openCommentbox ? null : "right"}
        outlined
        autoFocus
        className="logout-btns click-btn"
      />
    </div>
  );

  const renderItems = (items, section) => {
    const capitalizedArray = items?.map((item) => {
      const name = typeof item === "string" ? item : item.name;
      return {
        ...item,
        name: capitalizeFirstLetterOfEachSentence(name),
      };
    });

    return (
      <div>
        {section === "customerTypes" ? (
          <span>
            {items
              ?.map((id) => {
                const customerType = RAW_FILTER_DATA.customerTypes.find(
                  (type) => type.id === id
                );
                return customerType ? customerType.name : id;
              })
              .join(",  ")}
          </span>
        ): ( capitalizedArray?.length > 0 ? 
          section === "tags" ? 
            capitalizedArray?.map((item, index) => (
              <span key={index} className="tagSection">
                {item.name}
              </span>
            ))
            : 
            capitalizedArray?.map((item) => item.name)?.join(", ")
          : 
          "-"
        )}
      </div>
    );
  };

  const getResponseEventTotalCustomers = (customerInfo) => {
    return customerInfo?.customerDetails?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0
    );
  };

  const renderAlertTemplate = (unparsedResponse) => {
    let response = {
      ...unparsedResponse,
      events:
        unparsedResponse?.eventInfo && JSON.parse(unparsedResponse?.eventInfo),
      customerInfo:
        unparsedResponse?.customerInfo &&
        JSON.parse(unparsedResponse?.customerInfo),
      fileAttachment:
        unparsedResponse?.fileAttachment &&
        JSON.parse(unparsedResponse?.fileAttachment),
    };
    return (
      <>
        <div className="details-section">
          {response.respondingGroup === companyUser && (
            <div className="flex align-items-center justify-content-between response-editorheader">
              <h3>Response details</h3>
            </div>
          )}
          {renderAlertDetailsLeft(response, true)}
          {renderAlertDetailsRight(response, true)}
        </div>
        <p>Description:</p>
        <p>{response.description}</p>
      </>
    );
  };

  const renderCommentTemplate = (response) => {
    return (
      <>
        <div className="details-section">
          {response.respondingGroup === companyUser && (
            <div className="flex align-items-center justify-content-between response-editorheader">
              <h3>Response details</h3>
            </div>
          )}
          <p>Comment</p>
          <p>{response.comment}</p>
        </div>
      </>
    );
  };

  const getDetails = useCallback(() => {
    setLoading(true);
    const id = params?.id;
    getAlertDetails(id, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        if (responseData === null) {
          navigate("/home");
        } else {
          let data = {
            ...responseData,
            customerInfo:
              responseData?.customerInfo &&
              JSON.parse(responseData?.customerInfo),
            comments:
              responseData?.comments && JSON.parse(responseData?.comments),
            fileAttachment:
              responseData?.fileAttachment &&
              JSON.parse(responseData?.fileAttachment),
          };
          const alertDetails = responseData ? data : {};
          const alertResponses = alertDetails?.responses;
          setAlertResponses(alertResponses);
          setFilteredData(alertResponses);
          setData(alertDetails);

          const initialFilters = filterResponseType?.reduce((acc, filter) => {
            acc[filter] = filter === "all";
            return acc;
          }, {});

          setFilters(initialFilters);
        }
      } else {
        setData({});
        const error = response?.error;
        toast.current?.show({
          severity: error?.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
      setLoading(false);
    });
  }, [dispatch, filterResponseType, headers, params?.id, navigate]);

  // Filter the responses section based on checkbox selection
  const handleResponseChange = (event) => {
    const { id, checked } = event.target;
    setFilters((prevFilters) => {
      let newFilters = { ...prevFilters, [id]: checked };

      if (id === "all") {
        // If "all" is checked, uncheck all other filters
        newFilters = filterResponseType?.reduce((acc, filter) => {
          acc[filter] = filter === "all" ? checked : false;
          return acc;
        }, {});
      } else if (checked) {
        // If any filter other than "all" is checked, uncheck "all"
        newFilters["all"] = false;
      }

      // Filter data based on updated filters
      const isAllSelected = newFilters["all"];
      const newFilteredData = isAllSelected
        ? alertResponses
        : alertResponses?.filter((item) => newFilters[item.responseType]);

      setFilteredData(newFilteredData);
      return newFilters;
    });
  };

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const fetchFileDetails = (key, fileName) => {
    const convertBlobToPDFAndDownload = async (pdfData, fileName) => {
      try {
        const url = URL.createObjectURL(pdfData);

        const link = document.createElement("a"); //download link
        link.href = url;
        link.setAttribute("download", fileName || "file.pdf");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        toast.current?.show({
          severity: error?.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    };
    getFileDetails(key, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const fileData = response.data;
        convertBlobToPDFAndDownload(fileData, fileName);
      } else {
        const error = response?.error;
        toast.current?.show({
          severity: error?.severity,
          summary: "Error",
          detail: error?.errorMsg ? error?.errorMsg : error?.summary,
        });
      }
    });
  };

  const renderAlertDetailsLeft = (data, isResponse) => {
    return (
      <>
        <ul className="details-ul">
          <li>ID</li>
          {isResponse ? <li>{data?.responseID}</li> : <li>{data?.alertID}</li>}
        </ul>
        <ul className="details-ul">
          <li>Type</li>
          <li>{getDisplayData(alertData?.alertType, "alertTypeFilter")}</li>
        </ul>
        <ul className="details-ul">
          <li>Sport</li>
          <li>{data?.events?.length ? data?.events[0]?.sportName : ""}</li>
        </ul>
        <ul className="details-ul">
          <li>Tournament/ league</li>
          <li>{data?.events?.length ? data?.events[0]?.tournamentName : ""}</li>
        </ul>
        {data?.tags && (
          <ul className="details-ul">
            <li>Tags</li>
            <li>{renderItems(data?.tags, "tags")}</li>
          </ul>
        )}
        <ul className="details-ul">
          <li>Gender</li>
          <li>
            {data?.events?.length
              ? getDisplayData(data?.events[0]?.gender, "gender")
              : ""}
          </li>
        </ul>
        <ul className="details-ul">
          <li>Location of event</li>
          <li>{data?.events?.length ? data?.events[0]?.locationName : ""}</li>
        </ul>
        <ul className="details-ul">
          <li>Date/ time of event</li>
          <li>
            {data?.events?.length
              ? moment
                  .utc(data?.events[0]?.dateTime)
                  .format("MMMM DD, YYYY HH:mm [UTC]")
              : ""}
          </li>
        </ul>
        <ul className="details-ul">
          <li>Time of bet</li>
          <li>{renderItems(data?.timeOfBet, "timeOfBet")}</li>
        </ul>
        <ul className="details-ul">
          <li>State of bet</li>
          <li>{renderItems(data?.stateOfBet, "stateOfBetsFilter")}</li>
        </ul>
        {data?.timeOfAlert && (
          <ul className="details-ul">
            <li>Alert raised</li>
            <li>{getDisplayData(data?.timeOfAlert, "alertsRaised")}</li>
          </ul>
        )}
        <ul className="details-ul">
          <li>Channel</li>
          <li>{renderItems(data?.channel, "channels")}</li>
        </ul>
        <ul className="details-ul">
          <li>Max bets</li>
          <li>{data?.maxBets ? "Yes" : "No"}</li>
        </ul>
        <ul className="details-ul">
          <li>Files</li>
          <ul className="p-0 flex flex-column">
            {nonIbiaAttachments?.map((file) => (
              <li key={file.key}>
                <PR.Button
                  label={file?.name}
                  icon="pi pi-download"
                  className="download-button"
                  onClick={() => fetchFileDetails(file.key, file?.name)}
                />
              </li>
            ))}
          </ul>
        </ul>
      </>
    );
  };

  const renderAlertDetailsRight = (data, isResponse) => {
    return (
      <>
        <ul className="details-ul">
          <li>No. of events</li>
          <li>{formatNumber(data?.events?.length)}</li>
        </ul>
        <div className="card">
          <PR.DataTable
            value={formatEvent(data?.events)}
            stripedRows
            className="data-table"
          >
            <PR.Column field="event" header="Event"></PR.Column>
            <PR.Column field="date" header="Date Time"></PR.Column>
            <PR.Column field="country" header="Country"></PR.Column>
            <PR.Column field="turnover" header="Turnover"></PR.Column>
          </PR.DataTable>
        </div>
        <ul className="details-ul">
          <li>Type of customers</li>
          <li className="capitalize">
            {renderItems(data?.customerInfo?.customerType, "customerTypes")}
          </li>
        </ul>
        <ul className="details-ul">
          <li>Total customers</li>
          <li>
            {formatNumber(getResponseEventTotalCustomers(data.customerInfo))}
          </li>
        </ul>
        <div className="grid">
          {data?.customerInfo?.customerDetails?.map((item, index) => (
            <div className="col-4" key={index}>
              <div className="box">
                <p>
                  Country <br /> <span>{item?.country}</span>
                </p>
                <p>
                  No. of customers <br />{" "}
                  <span>{formatNumber(item?.count)}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const backBtn = () => {
    if (role === "analyst") {
      return "home";
    }
    if (role === "member") {
      return "alerts";
    }
  };

  const responseTypeFilter = RAW_FILTER_DATA?.responseTypeFilter;

  return (
    <>
      <Layout backBtn={backBtn()}>
        <section className="alert-details-section">
          <div className="m-0">
            <h1>Alert details</h1>
          </div>

          <div className="details-wrapper">
            {(loading) ? (
              <div className="loading">
                <i className="pi pi-spin pi-spinner"></i>
              </div>
            ) : loadingScroll ? (
              <div className="loading">
                <i className="pi pi-spin pi-spinner"></i>
              </div> 
            )
            :(
              <>
                <div className="header-section">
                  <div className="card flex align-items-center justify-content-between">
                    <div className="left-section">
                      <div className="flex align-items-center gap-3">
                        {alertData?.events?.length > 0 ? (
                          <div className="sports-icon">
                            {getSportIcon(alertData?.events[0]?.sportID + "b")}
                          </div>
                        ) : (
                          <PR.Image
                            src={eventIcon}
                            alt="Sport"
                            className="sports-icon"
                          />
                        )}
                        <h2>{alertData?.alertName}</h2>
                        {alertData?.alertStatus && (
                          <div
                            className={`capitalize status ${additionalStyle}`}
                          >
                            {alertData?.alertStatus}
                          </div>
                        )}
                        {alertData?.caseStatus && (
                          <div className="capitalize status">
                            {getStatusLabel(alertData?.caseStatus)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="right-section flex align-items-center text-right align-items-center gap-3">
                      {(isMember && (alertData?.conversationExists) && (!alertData?.responseRequired)) ||
                      (isAnalyst && sessionData?.groupID !== groupID) ? (
                        <div
                          className={`response chat ${
                            alertData?.activeConversation ? "active" : ""
                          }`}
                        >
                          <PR.Image
                            src={chatIcon}
                            alt="Chat ID"
                            onClick={() => {
                              setChatVisible(true);
                              setConversationClicked(true);
                              getChatConversation(params?.id, groupID);
                              setChatCompany(companyName);
                              setChatInfo((prev) => ({
                                ...prev,
                                groupID: groupID,
                                alertID: params?.id,
                              }));
                            }}
                          />
                        </div>
                      ) : null}

                      {/* Change alert status - Buttons */}
                      {(isAnalyst &&
                      alertData.alertStatus === "suspicious" &&
                      alertData.caseStatus === "under investigation") ? (
                        <PR.Button
                          label="Change case status"
                          className="respond-button status-button"
                          onClick={() => setAlertStatusVisible(true)}
                        />
                      ) : alertData.alertStatus === "suspicious" &&
                        (alertData.caseStatus === "closed" ||
                          alertData.caseStatus === "sanctioned") ? (
                        <PR.Button
                          label="View case status"
                          className={` ${
                            isAnalyst ? "analyst" : "member"
                          } respond-button status-button`}
                          onClick={handleViewCaseStatus}
                        />
                      ) : (
                        isAnalyst && (
                          <PR.Button
                            label="Change alert status"
                            className="respond-button status-button"
                            onClick={() => {setAdviceAttachments(); setAlertStatusVisible(true)}}
                          />
                        )
                      )}
                      {isAnalyst && (
                        <PR.Button
                          label="Assign"
                          className="respond-button assign-button"
                          onClick={() => {setAssigneeVisible(true); getAssigneeNames();}}
                        />
                      )}

                      {/* Change alert status - Buttons */}

                      {alertData.createdByGroup !== sessionData.companyName &&
                        alertData?.responseRequired &&
                        (alertData?.alertStatus === "open" ||
                          alertData?.alertStatus === "evaluating") &&
                        role !== "analyst" && (
                          <>
                            <PR.Button
                              label="Respond to alert"
                              className="respond-button"
                              onClick={() => setCurrentModal(1)}
                            />
                            <div className="status error">
                              <i className="pi pi-info-circle mr-2"></i> This
                              alert requires your response immediately!
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className="details-section">
                  <div className="flex align-items-center justify-content-between">
                    <h3>Alert details</h3>
                    {((alertData.createdByGroup === companyUser && alertData.alertStatus === 'open') ||
                      isAnalyst) && (
                      <PR.Button
                        className="edit-button"
                        iconPos="left"
                        onClick={() => setVisibleEditDetails(true)}
                      >
                        <PR.Image
                          src={editIcon}
                          alt="Filter Icon"
                          className="icon"
                        />
                        <span className="label">Edit details</span>
                      </PR.Button>
                    )}
                  </div>
                  <div className="hr-line"></div>
                  <div className="grid justify-content-between mt-4">
                    <div className="col-5 border-right">
                      {renderAlertDetailsLeft(alertData, false)}
                    </div>
                    <div className="col-6">
                      {renderAlertDetailsRight(alertData, false)}
                    </div>
                  </div>
                </div>

                {isAnalyst &&
                  (!(["open", "evaluating"].includes(alertData.alertStatus )) && 
                    <div className="details-section advice-report-section">
                      <div className="flex align-items-center justify-content-between">
                      <h3>
                        Advice Issued:{" "}
                        {alertData.caseStatus === "closed" || alertData.caseStatus === "sanctioned"
                          ? `${capitalizeFirstLetter(alertData?.alertStatus)} & ${capitalizeFirstLetter(alertData?.caseStatus)}`
                          : capitalizeFirstLetter(alertData?.alertStatus)}
                      </h3>
                      {" "}
                        <PR.Button className="edit-button" iconPos="left" onClick={openEditAdviceDialog}>
                          <PR.Image
                            src={editIcon}
                            alt="Filter Icon"
                            className="icon"
                          />
                          <span className="label">Edit</span>
                        </PR.Button>
                      </div>
                      <div className="hr-line"></div>
                      <div className="grid justify-content-between mt-4">
                        <div className="col-8 border-right">
                          <div className="advice-report-block">
                            <p>
                              {alertData?.ibiaAdvice
                                ? alertData?.ibiaAdvice
                                : "No Advice issued"}
                            </p>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="attached-files-block">
                            <p>Attached Files:</p>
                            {ibiaAttachments?.length > 0 ? (
                              ibiaAttachments?.map((file) => (
                                <PR.Button
                                  key={file.key}
                                  label={file.name}
                                  icon="pi pi-download"
                                  className="download-button"
                                  onClick={() =>
                                    fetchFileDetails(file.key, file.name)
                                  }
                                />
                              ))
                            ) : (
                              <p>No files attached</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <PR.Dialog
           visible={editAdviceVisible}
           footer={editAdviceFooter}   
            className="logout-dialog description-dialog evaluating-status-dialog"
          >
          
          <div className="logout-section">
       <h2>Edit Advice and Attachments</h2>
       <label htmlFor="description">Advice issued</label>
       <div className="card">
         <PR.InputTextarea
           value={editedIbiaAdvice}
           onChange={(e) => textTypingHandler(e, setEditedIbiaAdvice, descriptionCount)}
           onPaste={(e) => handlePaste(e, editedIbiaAdvice, setEditedIbiaAdvice, descriptionCount)}
           placeholder="Explain in detail..."
           className="detail-textarea"
         />
         <div className="descriptioncharLimit">
           {descriptionCount - editedIbiaAdvice?.length}/{descriptionCount} characters left
         </div>
         </div>
       </div>
       <div className="col-12 p-0">
            <div className="flex flex-column gap-2">
             <label htmlFor="attachments">Attach file</label>
             <div className="card file-upload attach-fileupload flex align-items-center">
                  <PR.FileUpload
                 auto
                 mode="advanced"
                 name="attachments"
                 accept=".pdf,.docx,.xlsx"
                 chooseLabel="Choose Files"
                 multiple
                 ref={fileInputRef}
                 onSelect={handleEditFileUpload}
                 customUpload
               />
               <span className="ml-2">(Add file less than {fileUploadLimit.label})</span>

             </div>
             <ul style={{ listStyleType: "none", padding: 0 }}>
             {selectedFiles?.map((file, index) => (
                        <div className="uploaded-file-container flex flex-column gap-2">
                      <div className="uploaded-files" key={index}>
                        {file.name}
                        <i
                          className="pi pi-trash"
                          onClick={() => handleDeleteSelectedFile(index)}
                        />
                      </div>
                    </div>
                  ))}
             {editedFiles?.map((file, index) => (
                        <div className="uploaded-file-container flex flex-column gap-2">
                         <div className="uploaded-files" key={index}>
                          {file.name}
                          <i
                            className="pi pi-trash"
                            onClick={() => handleDeleteExistingFile(index)}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          />
                        </div>
                      </div>
                    ))}
             
             </ul>
       </div>
       </div>
          </PR.Dialog>
                    </div>
                    
                  )}

                <div className="description-comment-section">
                  <div className="grid">
                    <div className="col-6">
                      <PR.Accordion activeIndex={0}>
                        <PR.AccordionTab
                          header={
                            <div className="flex align-items-center justify-content-between">
                              <p>Alert description</p>
                              {(alertData.createdByGroup === companyUser ||
                                isAnalyst) && (
                                <PR.Button
                                  className="edit-button"
                                  iconPos="left"
                                  onClick={() => setVisible(true)}
                                >
                                  <PR.Image
                                    src={editIcon}
                                    alt="Filter Icon"
                                    className="icon"
                                  />
                                  <span className="label">Edit</span>
                                </PR.Button>
                              )}
                            </div>
                          }
                        >
                          <p className="m-0 desc-ws">
                            {alertData?.description}
                          </p>
                        </PR.AccordionTab>
                      </PR.Accordion>
                    </div>
                    <div className="col-6">
                      <PR.Accordion
                        activeIndex={alertData?.comments?.length > 0 ? 0 : -1}
                      >
                        <PR.AccordionTab header="Comment panel">
                          <div className="comment-panel">
                            <div className="chat-messages">
                              {alertData?.comments?.map((chat, index) => (
                                <div className="message-box" key={index}>
                                  <div className="sender-name">
                                    <p>
                                      <span>{chat.groupName}</span>
                                    </p>
                                  </div>
                                  <div className="mb-3">
                                    <div className="message">
                                      <p>{chat?.content}</p>
                                    </div>
                                    <div className="time text-right">
                                      <span>
                                        {chat?.createdAt
                                          ? formatTime(chat.createdAt)
                                          : " "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="card comment-box">
                              <PR.InputText
                                value={value}
                                onChange={(e) => {
                                  if (e.target.value?.length <= chatCount) {
                                    setValue(
                                      e.target.value.substring(0, chatCount)
                                    );
                                    setCommentError("");
                                  } else {
                                    setCommentError(
                                      `Message should be ${chatCount} characters or less.`
                                    );
                                  }
                                }}
                                onPaste={(e) => {
                                  const pastedData =
                                    e.clipboardData.getData("text");
                                  const maxCharsToAdd =
                                    chatCount - value?.length;
                                  const newMessage =
                                    value +
                                    pastedData?.substring(0, maxCharsToAdd);
                                  setValue(newMessage);
                                  if (newMessage.length >= chatCount) {
                                    setCommentError(
                                      `Message should be ${chatCount} characters or less.`
                                    );
                                  }
                                  e.preventDefault();
                                }}
                                placeholder="Type your comment..."
                              />
                              <PR.Button
                                label="Send"
                                className="send-button"
                                onClick={createComment}
                              />
                            </div>
                            <br />{" "}
                            <span style={{ textAlign: "left" }}>
                              {commentError && (
                                <div className="error-message">
                                  {commentError}
                                </div>
                              )}
                            </span>
                          </div>
                        </PR.AccordionTab>
                      </PR.Accordion>
                    </div>
                  </div>
                </div>
                <div className="responses-section">
                  <div className="grid">
                    <div className="col-9">
                      <div className="left-section">
                        <div className="flex align-items-center justify-content-between">
                          <h3>Responses</h3>
                          {alertData?.receivedResponseCount > 0 && (
                            <div className="flex gap-3">
                              <PR.Button
                                label={`Received: ${alertData?.receivedResponseCount}`}
                                className="response-button"
                                onClick={showReceived}
                              />
                              <PR.Button
                                label={`Pending: ${alertData?.pendingResponseCount}`}
                                className="response-button"
                                onClick={showPending}
                              />
                            </div>
                          )}
                        </div>
                        <div className="hr-line"></div>
                        {alertData?.receivedResponseCount > 0 ? (
                          <div className="filters-list">
                            <div className="flex flex-wrap gap-3">
                              {filterResponseType?.map((key) => (
                                <div
                                  className="flex align-items-center status-box"
                                  key={key}
                                >
                                  <PR.Checkbox
                                    id={key}
                                    onChange={handleResponseChange}
                                    checked={filters[key]}
                                  ></PR.Checkbox>
                                  <label
                                    htmlFor={key}
                                    className="ml-2 font-medium"
                                  >
                                    {
                                      responseTypeFilter?.find(
                                        (option) => option.id === key
                                      )?.name
                                    }
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <>
                            <p className="no-response-p-tag">
                              No responses received yet
                            </p>
                          </>
                        )}
                        <PR.Accordion
                          activeIndex={responsesActiveIndex}
                          className="response-accordion"
                        >
                          {filteredData?.map((response) => {
                            return (
                                <PR.AccordionTab
                                  header={
                                    <div className="flex align-items-center justify-content-between ">
                                      <ul className="flex list-none align-items-center gap-2">
                                        <li className="flex align-items-center gap-2">
                                          <ImageDisplay profileImage={response?.groupImage} type="group" />
                                          <span className="capitalize">{response.respondingGroup}</span>
                                        </li>
                                        <li>|</li>
                                        <li>
                                          {response?.createdAt
                                            ? formatTime(response?.createdAt)
                                            : ""}
                                        </li>
                                      </ul>
                                      <div className="flex align-items-center response-options gap-4">
                                        <div
                                          className={`status ${
                                            RAW_FILTER_DATA.responseTypeFilter?.find(
                                              (item) => item.id === response.responseType
                                            )?.style || ""
                                          }`}
                                        >
                                          {
                                            responseTypeFilter?.find(
                                              (option) =>
                                                option.id ===
                                                response?.responseType
                                            )?.name
                                          }
                                        </div>
                                        <div
                                          className={`response-chat ${
                                            response?.activeConversation
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          {(isMember &&
                                            response.respondingGroupID ===
                                              sessionData.groupID && response?.conversationExists) ||
                                          (isAnalyst) ? (
                                            <PR.Image
                                              src={msgIcon}
                                              alt="Chat ID"
                                              className="msg-icon"
                                              onClick={() => {
                                                setChatVisible(true);
                                                getChatConversation(
                                                  params?.id,
                                                  response?.respondingGroupID);
                                                setChatCompany(companyName);
                                                setChatInfo((prev) => ({
                                                  ...prev,
                                                  groupID:
                                                    response?.respondingGroupID,
                                                  alertID: params?.id,
                                                }));
                                              }}
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  key={response.respondingGroup}
                                  disabled={response.responseType !=="offered and suspicious" ?  !response?.comment: false}
                                >
                                  <div ref={response.responseID === responseID ? responsesDiv: null}>
                                    {response.responseType ===
                                    "offered and suspicious"
                                    ? renderAlertTemplate(response)
                                    : renderCommentTemplate(response)}
                                  </div>
                                </PR.AccordionTab>
                          )})}
                        </PR.Accordion>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="right-section">
                        <div className="reporter-profile">
                          <ImageDisplay profileImage={alertData?.userImage} type="user" />
                          <p>Reporter</p>
                          <h4>{alertData?.createdByGroup}</h4>
                        </div>
                        <div className="hr-line"></div>
                        <ul className="list-none flex align-items-center justify-content-between">
                          <li>Created</li>
                          <li>
                            {moment
                              .utc(alertData?.createdAt)
                              .format("DD/MM/YYYY, hh:mm a [UTC]")}
                          </li>
                        </ul>
                        <ul className="list-none flex align-items-center justify-content-between">
                          <li>Updated</li>
                          <li>
                            {moment
                              .utc(alertData?.updatedAt)
                              .format("DD/MM/YYYY, hh:mm a [UTC]")}
                          </li>
                        </ul>
                        <ul className="list-none flex align-items-center justify-content-between">
                          <li>Resolved</li>
                          <li>
                            {alertData?.resolvedAt &&
                              moment
                                .utc(alertData?.resolvedAt)
                                .format("DD/MM/YYYY, hh:mm a [UTC]")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
            }
          </div>
        </section>
        <PR.Dialog
          visible={visible}
          footer={descriptionFooter}
          className="logout-dialog description-dialog"
          blockScroll="true"
        >
          <div className="logout-section">
            <h2>Edit alert description</h2>
            <PR.InputTextarea
              value={alertDesc ? alertDesc : alertData?.description}
              onChange={(e) => textTypingHandler(e, setAlertDesc, descriptionCount)}
              onPaste={(e) => handlePaste(e, alertDesc, setAlertDesc, descriptionCount)}
            />            
            <div className="descriptioncharLimit">
            {descriptionCount - (alertDesc ? alertDesc?.length : alertData?.description?.length)}/{descriptionCount} characters left
            </div>
          </div>
        </PR.Dialog>
        {renderFirstModal()}
        {renderSecondModal()}
        {renderCommentBox()}
        {renderThirdModal()}

        {/* Edit details dialog */}
        <PR.Dialog
          visible={visibleEditDetails}
          className="logout-dialog edit-alert-dialog"
          blockScroll="true"
          onHide={() => setVisibleEditDetails(false)}
          closeOnEscape="true"
        >
          <section className="create-alert-section">
            <div className="create-alert-wrapper">
              <div className="m-0">
                <h1>Edit details</h1>
              </div>
              <EditAlertForm
                handleBack={onBackClick}
                handleNext={handleUpdateAlert}
                initialData={alertData}
                nextLabel="Save changes"
                backLabel="Cancel"
              />
            </div>
          </section>
        </PR.Dialog>
        {/* Chat Dialog  */}
        {chatLoading && !isChatPopupOpen ? (
          <div className="loading chat-loading">
            <i className="pi pi-spin pi-spinner"></i>
          </div>
        ) : (
          <PR.Dialog
            visible={chatVisible}
            footer={chatFooterContent}
            onHide={() => {
              setMessage('')
              setErrorMessage('')
              setChatVisible(false);
              setChatPopupOpen(false)
              setChatInfo({
                alertID:'',
                groupID:'',
                conversationID:''
              })
            }}
            className="logout-dialog response-dialog chat-dialog"
            blockScroll="true"
          >
            <div className="dialog-header">
              <div className="flex align-items-center gap-2">
                <ImageDisplay
                  profileImage={sessionData?.groupImage}
                  type="group"
                />
                <h2>I'm {chatCompany}</h2>
              </div>
            </div>
            {chatData?.length === 0 ? (
              <p className="no-message-p">No messages yet.</p>
            ) : (
              <div className="dialog-body-section">
                {chatData?.map((item, index) => {
                  const rendermessage =
                    getChatUserName(item.senderID) !== userName ? (
                      <div
                        className="chat-block-received"
                        key={index + item.senderID}
                      >
                        <div className=" flex align-items-start">
                          <div className="mr-3">
                            <ImageDisplay
                              profileImage={getChatUserImage(item.senderID)}
                              type="user"
                            />
                          </div>
                          <div className="message-box">
                            <div className="message">
                              <p>{item.content}</p>
                            </div>
                            <div className="time">
                            <span>{formatTime(item.createdAt)}</span>
                          </div>
                          </div>
                          
                        </div>
                      </div>
                    ) : (
                      <div
                        className="chat-block-sent flex1 align-items-start"
                        key={index}
                      >
                        <div className="message-box">
                          <div className="message">
                            <p>{item.content}</p>
                          </div>
                          <div className="time text-right">
                            <span>{formatTime(item.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    );
                  return <div key={index}>{rendermessage}</div>;
                })}
                <div ref={messagesEndRef} />
              </div>
            )}
          </PR.Dialog>
        )}

        {/* Members Responded Dialog */}
        {responsesPopupLoading && respondedVisible ? (
          <div className="loading chat-loading">
            <i className="pi pi-spin pi-spinner"></i>
          </div>
        ) : (
          <PR.Dialog
            visible={respondedVisible}
            footer={respondedFooterContent}
            className="logout-dialog response-dialog"
            onHide={() => setRespondedVisible(false)}
            closeOnEscape="true"
            blockScroll="true"
          >
            {/****  Members Responded ****/}
            <div className="dialog-header">
              <div className="flex align-items-center justify-content-between mb-5">
                <h2>
                  {responsesData?.respondedCount}{" "}
                  {getReference(responsesData?.respondedCount)} responded
                </h2>
              </div>
              <span className="p-input-icon-left search-field">
                <i className="pi pi-search" />
                <PR.InputText placeholder="Search" />
              </span>
            </div>
            <div className="dialog-body-section">
              {responsesData?.respondedInfo?.map((company, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center member-response-list justify-content-between"
                  >
                    <div className="left">
                      <ul className="flex list-none align-items-center gap-2">
                        <li className="flex align-items-center gap-2">
                          <ImageDisplay
                            profileImage={company?.groupImage}
                            type="group"
                          />{" "}
                          <span>{company.respondingGroup}</span>
                        </li>
                        <li>|</li>
                        <li>{formatTime(company.createdAt)}</li>
                      </ul>
                    </div>
                    <div className="right flex align-items-center">
                      <span
                        className={
                          company.responseType === "offered and suspicious"
                            ? "suspicious"
                            : null
                        }
                      >
                        {company.responseType}
                      </span>{" "}
                      <i className="pi pi-chevron-right"></i>
                    </div>
                  </div>
                );
              })}
            </div>
          </PR.Dialog>
        )}

        {/* Members Not Responded Dialog */}
        {responsesPopupLoading && notRespondedVisible ? (
          <div className="loading chat-loading">
            <i className="pi pi-spin pi-spinner"></i>
          </div>
        ) : (
          <PR.Dialog
            visible={notRespondedVisible}
            footer={notRespondedFooterContent}
            className="logout-dialog response-dialog"
            onHide={() => setNotRespondedVisible(false)}
            closeOnEscape="true"
            blockScroll="true"
          >
            {/* Members Not Responded  */}
            <div className="dialog-header">
              <div className="flex align-items-center justify-content-between mb-5">
                <h2>
                  {responsesData?.notRespondedCount}{" "}
                  {getReference(responsesData?.notRespondedCount)} not responded
                </h2>
              </div>
              <span className="p-input-icon-left search-field">
                <i className="pi pi-search" />
                <PR.InputText placeholder="Search" />
              </span>
            </div>
            <div className="dialog-body-section">
              {responsesData?.notRespondedInfo?.map((company, index) => {
                return (
                  <div key={index} className="not-responded-list">
                    <ImageDisplay
                      profileImage={company?.groupImage}
                      type="group"
                    />
                    <span>{company.respondingGroup}</span>
                  </div>
                );
              })}
            </div>
          </PR.Dialog>
        )}

        {/* Change alert status : open - Dialog */}
        {alertData?.alertStatus === "open" && (
          <PR.Dialog
            visible={alertStatusVisible}
            footer={alertStatusFooter}
            className="logout-dialog response-dialog tags-dialog alert-status-dialog"
          >
            <div className="dialog-header">
              <div className="mb-5">
                <h2>Change alert status</h2>
              </div>
            </div>
            <div className="dialog-body-section">
              <div className="flex align-items-center gap-4">
              <div className="status">{alertData.alertStatus?.charAt(0)?.toUpperCase() + alertData.alertStatus?.slice(1)}</div>

                <span className="pi pi-arrow-right"></span>
                <PR.InputText
                  id="status"
                  type="text"
                  aria-describedby="status"
                  className="status-input"
                  value="Evaluating"
                />
              </div>
            </div>
          </PR.Dialog>
        ) }

        {alertData?.alertStatus === "evaluating" &&
        (
          <PR.Dialog
            visible={alertStatusVisible}
            footer={alertStatusFooter}
            className="logout-dialog response-dialog tags-dialog evaluating-status-dialog"
          >
            <div className="dialog-header">
              <div className="mb-5">
                <h2>Change alert status</h2>
              </div>
            </div>
            <div className="dialog-body-section">
              <div className="role-grid align-items-center gap-4">
                <div className="status">{alertData?.alertStatus?.charAt(0)?.toUpperCase()+alertData.alertStatus?.slice(1)}</div>
                <span className="pi pi-arrow-right"></span>
                  <PR.Dropdown
                    value={statusDropdown}
                    onChange={(e) => setStatusDropdown(e.value)}
                    options={statusValues}
                    optionLabel="label"
                    className="multi-select-dropdown"
                    placeholder={
                    "Change Alert Status"
                    }
                  />
              </div>
              {["notreported", "suspicious", null].includes(statusDropdown) &&
              (<>
                <div className="flex flex-column gap-2 mt-4">
                  <label htmlFor="description">Advice issued</label>
                  <div className="card">
                    <PR.InputTextarea
                      placeholder="Explain in detail.."
                      className="detail-textarea"
                      name="description"
                      value={ibiaAdvice}
                      onChange={(e) => textTypingHandler(e, setIbiaAdvice, descriptionCount)}
                      onPaste={(e) => handlePaste(e, ibiaAdvice, setIbiaAdvice, descriptionCount)}
                    />
                    <div className="descriptioncharLimit">
                      {descriptionCount - ibiaAdvice?.length}/{descriptionCount} characters left
                    </div>
                  </div>
                </div>
                <div className="flex flex-column gap-2 mt-4">
                  <label htmlFor="attachments">Attach file</label>
                  <div className="card create-file-upload flex align-items-center">
                    <PR.FileUpload
                      auto
                      mode="advanced"
                      name="attachments"
                      accept=".pdf,.docx,.xlsx"
                      chooseLabel="Choose Files"
                      multiple
                      customUpload
                      ref={fileInputRef}
                      uploadHandler={handleFileUpload}
                    />
                    <span className="ml-2">(Add file less than {fileUploadLimit.label})</span>

                  </div>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {uploadedFiles?.map((file, index) => (
                      <div>
                        <li key={index}>
                          {file.name}
                          <i
                            className="pi pi-trash"
                            onClick={() => handleDeleteFile(index)}
                          />
                        </li>
                      </div>
                    ))}
                      {editedFiles?.map((file, index) => (
                        <div className="uploaded-file-container flex flex-column gap-2">
                         <div className="uploaded-files" key={index}>
                          {file.name}
                          <i
                            className="pi pi-trash"
                            onClick={() => handleDeleteExistingFile(index)}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          />
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </>)}
            </div>
          </PR.Dialog>
        )}

        {(alertData.alertStatus === "suspicious" && alertData.caseStatus === "under investigation") && (
          <PR.Dialog
            visible={alertStatusVisible}
            footer={alertStatusFooter}
            className="logout-dialog response-dialog tags-dialog evaluating-status-dialog"
          >
            <div className="dialog-header">
              <div className="mb-5">
                <h2>Change case status</h2>
              </div>
            </div>
            <div className="dialog-body-section">
              <div className="role-grid align-items-center gap-4">
                  <div className="status">{alertData?.caseStatus?.charAt(0)?.toUpperCase() + alertData.caseStatus?.slice(1)}</div>
                <span className="pi pi-arrow-right"></span>
                <PR.Dropdown
                  value={statusDropdown}
                  onChange={(e) => setStatusDropdown(e.value)}
                  options={statusValues}
                  optionLabel="label"
                  className="multi-select-dropdown"
                  placeholder={
                    "Change Case Status"
                  }
                />
              </div>
              <div className="flex flex-column gap-2 mt-4">
                <label htmlFor="description">Notes</label>
                <div className="card">
                  <PR.InputTextarea
                    placeholder="Explain in detail.."
                    className="detail-textarea"
                    name="description"
                    value={notes}
                    onChange={(e) => textTypingHandler(e, setNotes, descriptionCount)}
                    onPaste={(e) => handlePaste(e, notes, setNotes, descriptionCount)}
                  />
                <div className="descriptioncharLimit">
                  {descriptionCount - notes?.length}/{descriptionCount} characters left
                </div>
                </div>
              </div>
              {['sanctioned', 'closed'].includes(statusDropdown) &&(
                <>
                  <label className="mb-2 block">Send email notification to</label>
                  <PR.Dropdown
                    value={emailOption}
                    onChange={(e) => setEmailOption(e.value)}
                    options={emailOptions}
                    optionLabel="label"
                    className="multi-select-dropdown"
                  />
                </>
              )}
            </div>
          </PR.Dialog>
        )}

        {(alertData.alertStatus === "not reported" && alertData.caseStatus === "no further action") && (
          <PR.Dialog
            visible={alertStatusVisible}
            footer={alertStatusFooter}
            className="logout-dialog response-dialog tags-dialog evaluating-status-dialog"
          >
            <div className="dialog-header">
              <div className="mb-5">
                <h2>Change case status</h2>
              </div>
            </div>
            <div className="dialog-body-section">
              <div className="role-grid align-items-center gap-4">
                  <div className="status">{alertData?.alertStatus?.charAt(0)?.toUpperCase() + alertData.alertStatus?.slice(1)}</div>
                <span className="pi pi-arrow-right"></span>
                <PR.InputText
                  id="status"
                  type="text"
                  aria-describedby="status"
                  className="status-input"
                  value=" Back to Evaluating"
                />
              </div>
              <div className="flex flex-column gap-2 mt-4">
                <label htmlFor="description">Notes</label>
                <div className="card">
                  <PR.InputTextarea
                    placeholder="Explain in detail.."
                    className="detail-textarea"
                    name="description"
                    value={notes}
                    onChange={(e) => textTypingHandler(e, setNotes, descriptionCount)}
                    onPaste={(e) => handlePaste(e, notes, setNotes, descriptionCount)}
                  />
                <div className="descriptioncharLimit">
                  {descriptionCount - notes?.length}/{descriptionCount} characters left
                </div>
                </div>
              </div>
            </div>
          </PR.Dialog>
        )}
        {/* Change assignee - Dialog */}
        <PR.Dialog
          visible={assigneeVisible}
          footer={changeAssigneeFooter}
          className="logout-dialog response-dialog tags-dialog alert-status-dialog"
          blockScroll="true"
        >
          <div className="dialog-header">
            <div className="mb-5">
              <h2>Change assignee</h2>
            </div>
          </div>
          <div className="dialog-body-section">
            <div className="assignee-flex align-items-center gap-4">
              {alertData?.assignee && (
                <>
                  <div className="status ">{alertData?.assignee}</div>
                  <span className="pi pi-arrow-right"></span>
                </>
              )}
              <PR.Dropdown
                value={assigneeDropdown}
                onChange={(e) => setAssigneeDropdown(e.value)}
                options={assigneeNames}
                optionLabel="name"
                className="multi-select-dropdown"
                placeholder="Select Assignee"
              />
            </div>
          </div>
        </PR.Dialog>
      </Layout>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default AlertDetails;
