import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({backBtn, backBtnLabel, children}) => {
    return (
        <>
            <div className="main">
                <Header backBtn={backBtn} backBtnLabel={backBtnLabel}/>
                    {children}
                <Footer />
            </div>
        </>
    );
};

export default Layout;