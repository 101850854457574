import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import "./CaseUpdates.scss";
import Layout from "../../layouts/Layout";
import MenuBar from "../../layouts/MenuBar";
import * as PR from "../../prime-react/index";
import { eventIcon } from "../../assets/images/index";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCaseStatus } from "../../services/api";
import { debounce } from "lodash";
import moment from "moment";
import { RAW_FILTER_DATA } from "../common";
import DownloadPDF from "../downloadpdf/DownloadPdf";
import AddNote from "./AddNote";
const CaseUpdates = () => {
  const isMember = useSelector((state) => state.auth.role) === "member";
  const isAnalyst = useSelector((state) => state.auth.role) === "analyst";
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const showResultOnly = params.get("showResultOnly");
  const initialId = params.get("id");
  const [searchTerm, setSearchTerm] = useState(initialId || "");
  const [caseUpdates, setCaseUpdates] = useState(null);
  const [noRecord, setNoRecord] = useState(false);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
      "Content-Type": "application/json",
    };
  }, [sessionData.sessionId]);

  const handleSearch = useCallback(
    debounce((term) => {
      if (term) {
        const searchParams = new URLSearchParams();
        if (!isNaN(term)) {
          searchParams.append("id", term);
        } else {
          searchParams.append("name", term);
        }
        getCaseStatus(
          searchParams.toString(),
          headers,
          dispatch,
          (response) => {
            if (response.result === "SUCCESS") {
              const responseData = response.data;
              const result = responseData?.length > 0 ? responseData : [];
              setCaseUpdates(result);
              setNoRecord(result?.length === 0);
            } else {
              setCaseUpdates(null);
              setNoRecord(true);
              const error = response?.error;
              toast.current?.show({
                severity: error?.severity || "error",
                summary: "Error",
                detail: error?.errorMsg || error?.summary || "No record found",
              });
            }
          }
        );
      } else {
        setCaseUpdates(null);
        setNoRecord(false);
      }
   
    }, 500),
    // eslint-disable-next-line
    [headers, dispatch]
    
  );

  useEffect(() => {
    if (initialId) {
      handleSearch(initialId);
    }
  }, [initialId, handleSearch]);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  const getStatusLabel = (alertStatusId, caseStatusId) => {
    const alertStatus = RAW_FILTER_DATA.statusFilter?.find(
      (item) => item.id === alertStatusId
    );
    const caseStatus = RAW_FILTER_DATA.caseStatus?.find(
      (item) => item.id === caseStatusId
    );
    const currentLabel = caseStatus?.name
      ? `${alertStatus?.name}: ${caseStatus?.name}`
      : alertStatus?.name;
    const additionalStyle = alertStatus?.style || "";
    return <div className={`status ${additionalStyle}`}>{currentLabel}</div>;
  };
console.log(caseUpdates);
  return (
    <>
      <PR.Toast ref={toast} position='top-right' />
      <ConditionalLayout isMember={isMember}>
        {isMember && <MenuBar />}
        <section className="case-updates-tab">
          <div className="m-0 flex align-items-center justify-content-between relative">
            <h1>Case updates</h1>
            <div className="export-block export-analyst">
              {isAnalyst && caseUpdates && !noRecord && (
                <DownloadPDF
                contentId="results-section"
                fileName="case-updates.pdf"
                buttonLabel="Export"
                buttonClassName="export-button"
                headerText="Case Update"
                />
              )}
            </div>
          </div>

          {!showResultOnly && (
            <div className="grid grid-nogutter relative">
              <div className="flex align-items-center search-bar">
                <span className="p-input-icon-left w-12">
                  <i className="pi pi-search" />
                  <PR.InputText
                    value={searchTerm}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 15) {
                        setSearchTerm(value);
                      }
                    }}
                    placeholder="Search by alert number, name.."
                    className="search-box w-12"
                  />
                </span>
              </div>
            </div>
          )}

          {!showResultOnly && !caseUpdates && !noRecord && (
            <div className="loading-section">
              <div className="card">
                <div className="small-box">
                  <div className="flex align-items-center">
                    <div className="mr-2">
                      <i className="pi pi-check-circle"></i>
                    </div>
                    <div>
                      <PR.Skeleton className="bars"></PR.Skeleton>
                      <PR.Skeleton className="bars custom-width"></PR.Skeleton>
                    </div>
                  </div>
                </div>
                <div className="big-box">
                  <div className="flex align-items-center">
                    <div className="mr-2">
                      <i className="pi pi-check-circle"></i>
                    </div>
                    <div>
                      <PR.Skeleton className="bars"></PR.Skeleton>
                      <PR.Skeleton className="bars custom-width"></PR.Skeleton>
                    </div>
                  </div>
                </div>
                <div className="small-box">
                  <div className="flex align-items-center">
                    <div className="mr-2">
                      <i className="pi pi-check-circle"></i>
                    </div>
                    <div>
                      <PR.Skeleton className="bars"></PR.Skeleton>
                      <PR.Skeleton className="bars custom-width"></PR.Skeleton>
                    </div>
                  </div>
                </div>
                <p>Search for any alert to know the progress..</p>
              </div>
            </div>
          )}
          <br />
          {!noRecord && caseUpdates && <p className="heading-p">Results:</p>}
          <div id="results-section" className="results-section">
            {noRecord && (
              <div className="flex justify-content-center no-record-section">
                <p className="no-data m-0 text-bluegray-500">No records found</p>
              </div>
            )}
            {caseUpdates &&
              caseUpdates?.map((Case, index) => (
                <div key={index} className="result-box">
                  <div className="grid grid-nogutter align-items-center justify-content-between mb-3">
                    <span className="alert-number-span">
                      #Alert ID:&nbsp;{Case?.alertID}
                    </span>
                    <span>
                      {getStatusLabel(Case?.alertStatus, Case?.lastCaseStatus)}
                    </span>
                  </div>
                  <div className="hr-line"></div>
                  <div className="grid mt-3">
                    <div className="col-8">
                      <div className="flex align-items-start gap-3 header-info">
                        <div>
                          <PR.Image
                            src={eventIcon}
                            alt="Event ID"
                            className="icon"
                          />
                        </div>
                        <div className="card">
                          <h2>{Case?.eventName}</h2>
                          <ul>
                            <li>Sport: {Case?.sportName}</li>
                            <li>League: {Case?.tournamentName}</li>
                            <li>
                              Alert type:{" "}
                              {RAW_FILTER_DATA.alertTypeFilter?.find(
                                (item) => item.id === Case.alertType
                              )?.name}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="hr-line"></div>
                      <div className="notes-block">
                        <div className="flex align-items-center justify-content-between">
                          <span>Notes</span>
                          <AddNote id={Case.alertID} toast={toast} refetch={handleSearch} headers={headers} label={'Add Note'} icon={'pi pi-pencil'} />
                        </div>
                        <p>
                          {Case?.notes?.sort((a,b)=>moment(b.createdAt)-moment(a.createdAt)).map((el,i)=><div><p className="note-message" key={i} > <span>{moment(el?.createdAt).format('DD/MMM  hh:mm a')}</span> {el?.notes}</p></div>)}
                        </p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="activity-section">
                        <p>Activity</p>
                        <div className="hr-line"></div>
                        {Case?.activity?.map((activity, index) => (
                          <ul
                            key={index}
                            className="flex align-items-center justify-content-between list-none"
                          >
                            <li>
                              <span>
                                {
                                  RAW_FILTER_DATA.caseStatus?.find(
                                    (item) =>
                                      item.id === activity?.caseStatus
                                  )?.name
                                }
                              </span>
                            </li>
                            <li>
                              {moment(activity?.createdAt).format(
                                "DD/MM/YYYY, hh:mm a"
                              )}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </ConditionalLayout>
    </>
  );
};

export default CaseUpdates;

const ConditionalLayout = ({ children, isMember }) => {
  return (
    <>
      {isMember ? (
        <Layout>{children}</Layout>
      ) : (
        <Layout backBtn={"home"}>{children}</Layout>
      )}
    </>
  );
};
