import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import "./SportingSanction.scss";
import Layout from "../../../layouts/Layout";
import * as PR from "../../../prime-react/index";
import {
  slide1,
  slide2,
  news1,
  news2,
  news3,
  news4,
  news5,
  news6,
  news7,
  newView1,
  newView2,
  Avatar,
  newView3,
  newView4,
  newView5,
  newView6,
} from "../../../assets/images/index";
import { getFileDetails, getNews } from "../../../services/api";
import { pagecount } from "../../../utils/reuse";
import MostViewed from "../../../shared/MostViewed/MostViewed";
import TopCategories from "../../../shared/TopCategories/TopCategories";
import dbData from "../../../assets/data/db.json";
import moment from "moment";
import { extractKeywords } from "../../../utils/reuse";

const SportingSanction = () => {

  const toast = useRef(null);

  const dispatch = useDispatch();
  const [images, setImages] = useState({});
  const sessionData = useSelector(state => state.auth.sessionData);
  const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
  const [dates, setDates] = useState([]);
  const [filteredNewsData, setFilteredNewsData] = useState([]);
  const [mostViewedData, setMostViewedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 
  const navigate = useNavigate();
  const pageCount = pagecount;

  const imageMap = {
    news1,
    news2,
    news3,
    news4,
    news5,
    news6,
    news7,
    newView1,
    newView2,
    newView3,
    newView4,
    newView5,
    newView6,
    slide1,
    slide2,
    Avatar
  };

  useEffect(() => {
    setMostViewedData(dbData.mostViewed);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
      if ((searchQuery?.length >= 3 || (dates && dates?.length > 0)) || (searchQuery?.length === 0 && (!dates || dates?.length === 0))) {
      setCurrentPage(1);
      getNewsListHandler(1);
    }
    // eslint-disable-next-line
  }, [searchQuery, dates]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      getNewsListHandler(newPage);
    }
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    const formattedCategory = categoryName?.replace(/ /g, '-')?.replace(/-+$/, '');
    navigate(`/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`);
  };

  const getNewsListHandler = useCallback((page = 1) => {
    setLoading(true);
    const obj = {
      offset: (page - 1) * pageCount,
      limit: pageCount,
      search: searchQuery?.length >= 3 ? searchQuery : undefined,
      type: 2 
    };
    if (dates && dates?.length > 0) {
      const startDate= moment(dates[0]).format('YYYY-MM-DD');
      const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
      obj.startDate = startDate;
      obj.endDate = endDate;
    }
    getNews(obj, headers, dispatch, (response) => {
      if (response.result === 'SUCCESS') {
        const responseData = response.data;
        const totalPages = Math.ceil(responseData?.length / pageCount);
        setFilteredNewsData(responseData);
        setTotalPages(totalPages);
      } else {
        const error = response.error;
        toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary });
      }
      setLoading(false);
    });
  }, [dispatch, pageCount, headers, searchQuery, dates]);

  useEffect(() => {
    filteredNewsData?.forEach(news => {
      if (news.fileAttachments) {
        const attachments = JSON.parse(news.fileAttachments);
        attachments?.forEach(attachment => {
          if (attachment.type === "image/jpeg" || attachment.type === "image/png") {
            getFileDetails(attachment.key, headers, dispatch, response => {
              if (response.result === 'SUCCESS') {
                setImages(prevImages => ({
                  ...prevImages,
                  [news.id]: URL.createObjectURL(response.data)
                }));
              } else {
                const error = response?.error;
                toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
              }
            });
          }
        });
      }
    });
    // eslint-disable-next-line
  }, [filteredNewsData, headers, dispatch]);

const gotoNewsDetails = (id) => {
      navigate(`/sanction-details?id=${id}`);
};

  return (
    <>
      <Layout>
        <div className="back-button">
          <Link to="/news">
            <i className="pi pi-arrow-left"></i>
            Back to news
          </Link>
        </div>
        <section className="news-tab">
          <div className="grid">
            <div className="col-9">
              <div className="new-list-section">
                <div className="flex align-items-center justify-content-between">
                  <div className="left">
                    <h1>All Sanctions</h1>
                  </div>
                  <div className="right flex gap-3">
                    <div className="flex align-items-center search-bar">
                      <span className="p-input-icon-left w-12">
                        <i className="pi pi-search" />
                        <PR.InputText
                          placeholder="Search.."
                          className="search-box w-12"
                          value={searchQuery}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 15) {
                              setSearchQuery(value);
                            }
                          }}
                        />
                      </span>
                    </div>
                    <div className="card" style={{ position: "relative" }}>
                      <PR.Calendar
                        value={dates}
                        onChange={(e) => setDates(e.value)}
                        iconPos="left"
                        readOnlyInput
                        showIcon
                        selectionMode="range"
                        className="date-calendar"
                        placeholder="Select Date"
                        showButtonBar
                        dateFormat="dd/mm/yy"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <div className="list-wrapper">
                  {loading ? (
                    <div className="loading">
                      <i className="pi pi-spin pi-spinner"></i>
                    </div>
                  ) : (!filteredNewsData || filteredNewsData?.length === 0) ? (
                    <div className="flex justify-content-center">
                        <p className="no-data m-0 text-bluegray-500">
                            No records found
                        </p>
                    </div>
                  ) : (
                    filteredNewsData?.map((news) => {
                      const { warning, fine } = extractKeywords(news.keywords);
                      return (
                        <React.Fragment key={news.id}>
                          <div className="news-list">
                            <div className="flex align-items-center gap-4">
                              <div className="col-3 p-0">
                                {images[news.id] ? (
                                  <PR.Image
                                    src={images[news.id]}
                                    alt={news.title}
                                    className="news-img"
                                  />
                                ) : (
                                  <PR.Image
                                    src={Avatar}
                                    alt={news.title}
                                    className="news-img"
                                  />
                                )}
                              </div>
                              <div className="col-9 p-0">
                                <div className="custom-width">
                                  <h3>{news.title}</h3>
                                  <p
                                   style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                                    dangerouslySetInnerHTML={{
                                      __html: news.description?.split(" ").slice(0, 15).join(" ") + "...",
                                    }}
                                  ></p>
                                  <span onClick={() => gotoNewsDetails(news?.id)} className='cursor-pointer font-bold text-black'>see more</span>
                                  <div className="flex align-items-center justify-content-between">
                                    <ul className="flex list-none align-items-center gap-4">
                                      {warning && (
                                        <li>
                                          <i className="pi pi-exclamation-triangle"></i>{" "}
                                          <span className="warning">{warning}</span>
                                        </li>
                                      )}
                                      {fine && (
                                        <li>
                                          <i className="pi pi-money-bill"></i>{" "}
                                          <span className="fine">{fine}</span>
                                        </li>
                                      )}
                                    </ul>
                                    <ul className="flex list-none align-items-center gap-4">
                                      <li>
                                        <i className="pi pi-calendar"></i>{" "}
                                        <span>{moment(news.createdAt).format('DD MMM YYYY')}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="hr-line mt-4 mb-4"></div>
                        </React.Fragment>
                      );
                    })
                  )}
                </div>
                {filteredNewsData?.length > 0 && (
                  <div className="pagination">
                    <div className="grid grid-nogutter align-items-center justify-content-between">
                      <span>Page {currentPage}</span>
                      <ul className="flex list-none align-items-center gap-3">
                        <li
                          className={currentPage === 1 ? "disabled" : ""}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </li>
                        <li
                          className={currentPage === totalPages ? "disabled" : ""}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-3">
              <MostViewed mostViewedData={mostViewedData} imageMap={imageMap} />
              <TopCategories
                heading={"Top news categories"}
                handleCategoryClick={handleCategoryClick}
              />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SportingSanction;
