import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Home.scss";
import Layout from "../../../layouts/Layout";
import AlertsTemplate from "./AlertsTemplate";

const AnalystHome = () => {

    const [count, setCount]=useState({
        open:'-',
        evaluating:'-',
        suspicious:'-'
    })
    const openAlertCount = (count) => {
        setCount(prev => ({...prev, open:count}))
    }
    const evaluatingAlertCount = (count) => {
        setCount(prev => ({...prev, evaluating:count}))

    }
    const suspiciousAlertCount = (count) => {
        setCount(prev => ({...prev, suspicious:count}))
    }

    return (
        <> 
             <Layout>
                <section className="analyst-home-section">
                    <div className="grid align-items-center text-center">
                        <div className="col-4">
                            <Link to="/open-alerts">
                                <div className="info-box">
                                    <h2>{count.open}</h2>
                                    <p>Open Alerts <span className="link"><i className="pi pi-arrow-up-right"></i></span></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/evaluating-alerts">
                                <div className="info-box">
                                    <h2>{count.evaluating}</h2>
                                    <p>Evaluating Alerts <span className="link"><i className="pi pi-arrow-up-right"></i></span></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/suspicious-alerts">
                                <div className="info-box">
                                    <h2>{count.suspicious}</h2>
                                    <p>Suspicious Alerts <span className="link"><i className="pi pi-arrow-up-right"></i></span></p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='hr-line mt-4 mb-5'></div>
                    <AlertsTemplate
                        alertStatus=""
                        title="All alerts"
                        openAlertCount={openAlertCount}
                        evaluatingAlertCount={evaluatingAlertCount}
                        suspiciousAlertCount={suspiciousAlertCount}
                    />
                </section>
            </Layout>
        </>
    );
};

export default AnalystHome;