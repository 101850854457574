export const linksData = [
    {   sportName: 'Tennis', 
        sportID: 98,
        links: [
            { label : 'ITIA General' , link: 'https://www.itia.tennis/about/'},
            { label : 'ITIA Tennis Anti-Corruption Program' , link: 'https://www.itia.tennis/tacp/rules/'},
            { label : 'ITIA Sanctions' , link: 'https://www.itia.tennis/sanctions/'},
            { label : 'UTR Integrity Policies' , link: 'https://support.universaltennis.com/support/solutions/articles/9000193542-utr-sports-policies-on-tennis-integrity'}
        ]
    },
    {   sportName: 'Football',
        sportID: 43, 
        links: [
            { label : 'UEFA Anti-Match-Fixing' , link: 'https://www.uefa.com/running-competitions/integrity/anti-match-fixing/'},
            { label : 'FIFA Integrity' , link: 'https://inside.fifa.com/legal/integrity'},
        ]
    },
    {   sportName: 'Esports', 
        sportID: 104,
        links: [
            { label : 'ESIC Integrity Program' , link: 'https://esic.gg/codes/'},
            { label : 'ESIC Members &amp; Supportes List' , link: 'https://esic.gg/members/'},
        ]
    },
    {   sportName: 'Olympic Sports', 
        sportID: 65,
        links: [
            { label : 'IOC' , link: 'Olympic Movement Unit on the Prevention of the Manipulation of Competitions'},
        ]
    },
    {   sportName: 'Basketball', 
        sportID: 18,
        links: [
            { label : 'FIBA' , link: 'https://playershub.fiba.com/en/integrity/betting-and-match-fixing/protecting-integrity-sport'},
        ]
    },
    {   sportName: 'Badminton', 
        sportID: 15,
        links: [
            { label : 'BWF Integrity Unit' , link: 'https://corporate.bwfbadminton.com/integrity/integrity-unit/'},
            { label : 'BWF Anti-Match-Fixing' , link: 'https://corporate.bwfbadminton.com/integrity/anti-match-fixing-overview/'},
        ]
    },
    {   sportName: 'Boxing', 
        sportID: 25,
        links: [
            { label : 'British Boxing Board of Control Rules and Regulations' , link: 'https://bbbofc.com/build/documents/Rules_and_Regulations_2023.pdf'},
        ]
    },
    {   sportName: 'MMA', 
        links: [
            { label : 'UFC Athlete Conduct Policy' , link: 'https://media.ufc.tv/conduct/athlete_conduct_policy.pdf'},
        ]
    },
    {   sportName: 'Table Tennis', 
        sportID: 96,
        links: [
            { label : 'ITTF Integrity' , link: 'https://www.ittf.com/integrity/'},
            { label : 'ITTF Regulations' , link: 'https://documents.ittf.sport/sites/default/files/public/2024-02/2024_ITTF_Statutes_clean_version.pdf'},
            { label : 'Challenger Series Ochsenhausen Integrity Policies' , link: 'https://www.challengerseries.net/downloads/'},
            { label : 'TT Cup Integrity Policies' , link: 'https://ttcup.com/integrity-information/'},
        ]
    },
];