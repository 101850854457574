import React, { useState } from 'react';
import * as PR from "../../../../prime-react/index";
import './CreateNewsDialog.scss';
import { newsTypes } from "../../../../shared/common";

const CreateNewsDialog = ({ visible, onHide, onSelectCategory }) => {
    const [selectedCategory, setSelectedCategory] = useState('General news');

    const footerContent = (
        <div>
            <PR.Button label="Cancel" onClick={onHide} outlined className="logout-btns" />
            <PR.Button label="Next" onClick={() => onSelectCategory(selectedCategory)} className="logout-btns click-btn" />
        </div>
    );

    return (
        <>
            <PR.Dialog visible={visible} footer={footerContent} onHide={onHide} className="logout-dialog tags-dialog create-news-dialog">
                <div className="dialog-header">
                    <div className='mb-5'>
                        <h2>Create news</h2>
                        <p>Categorize the alert according to the relevant tag</p>
                    </div>
                </div>
                <div className="dialog-body-section">
                    {newsTypes?.map((category, index) => (
                        <div key={index} className="p-field-radiobutton box">
                            <PR.RadioButton inputId={category.name} name="category" value={category.name} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory === category.name} />
                            <label htmlFor={category.name}>{category.name}</label>
                        </div>
                    ))}
                </div>
            </PR.Dialog>
        </>
    );
};

export default CreateNewsDialog;
