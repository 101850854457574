import { useEffect, useState } from "react";
import * as PR from "../../../prime-react/index";
import ImageDisplay from "../../../shared/ImageDisplay";
import { pagecount } from "../../../utils/reuse";

export const Header = ({query,refetch, setFormPopUp}) => {

    let trigger;

    const changeHandler = ({target:{value}}) =>{
        query.search = value;
        if (trigger) clearTimeout(trigger);   
        trigger = setTimeout(() => {query.offset=0; refetch();  }, 1000);
    }

    return <>
        <div className="filter-section flex gap-2 p-2">
            <span className="p-input-icon-left flex align-items-center search-box">
                <i className="pi pi-search" />
                <PR.InputText maxLength={15} placeholder="Search.."  className="search-field" onChange={changeHandler} />
            </span>
            <PR.Button label="Create new group"  icon="pi pi-plus" className="create-button" onClick={setFormPopUp} />
        </div>
    </>
};
export const PaginationTemplate = ({refetch, query, data}) => {

    const [page, setPage] = useState();

    const clickHandler = async v =>{
        query.offset = (((page+v)-1)*pagecount);
        await refetch();
    }
    useEffect(()=>{
        setPage((Math.round(query.offset/pagecount)+1))
    },[query.offset, setPage])
    

    return <div className="p-paginator p-component p-paginator-bottom">
        <span className="current-page">Page {page}</span>
        <PR.Button className="pagination-buttons" disabled={!(page-1)} onClick={clickHandler?.bind(null,-1)} > Previous </PR.Button>
        <PR.Button className="pagination-buttons" disabled={data?.length<=(pagecount)} onClick={clickHandler?.bind(null,1)} > Next </PR.Button>
    </div>
};

export const groupName = ({image,role,name}) =>  <div className="flex align-items-center gap-2 group-name-block">
    {!!image ? <ImageDisplay profileImage={image} type="group" />:<ImageDisplay profileImage={'{"key": "773b6ee3-c3b1-4598-855f-dfb7b44d994e", "name": "avator-img.png", "size": "8488", "type": "image/png", "owner": "7"}'} type="group" />}
    <div className="event-column">
        <p>{name}</p>
        <span className="role">{role}</span>
    </div>
</div>


export const status = ({status}) => <div className="flex gap-2 align-items-center">
    <div className={`status ${status}`}> {status ? status?.charAt(0)?.toUpperCase() + status?.slice(1)?.toLowerCase() : ''} </div>
</div>


export const userCount = ({usersCount}) => <div className="flex gap-2 align-items-center">
    <div> {usersCount ?? 0} {usersCount === 1 ? 'profile' : 'profiles'} </div>
</div>


export const action = (viewDetailsHandler,editHandler,rowData) => {
    return (
        <div className="flex gap-2 align-items-center">
            <PR.Button label="View details" severity="secondary" onClick={viewDetailsHandler.bind(null,rowData)} outlined disabled={rowData.usersCount === 0} className="view-details-button" />
            <PR.Button icon="pi pi-pencil" outlined onClick={editHandler.bind(null,rowData)} className="view-details-button only-icon"/>
        </div>
    );
};